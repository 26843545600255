import { t } from "i18next";
import React, { createContext, useContext, useState } from "react";
import { Column, Row } from "style/custom/useCustomLayout";
import { CheckBtn, MainBtn } from "style/useButtonStyles";
import { IconLink, Dimmed, PositionBox } from "style/useLayoutStyles";
import styled from "styled-components";

const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  z-index: 9999;
  padding: 20px;
  background-color: var(--c-white);

  text-align: left;
`;

const Title = styled.h2`
  font-size: var(--s-title);
  font-family: var(--f-title);
  line-height: var(--l-title);
`;

const PopupTitle = styled.h2`
  font-size: var(--s-header);
  font-family: var(--f-header);
  line-height: var(--l-header);
`;

const Text = styled.p`
  font-size: var(--s-sub);
  line-height: var(--l-sub);
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  text-align: center;
  width: fit-content;

  text-align: left;
  background-color: var(--c-white);
  z-index: 9999;

  min-width: 500px;
  max-width: 90vw;
`;

const PopupContent = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
  max-height: 80vh;
  padding: 16px 28px;
  min-width: 500px;
  max-width: 90vw;
`;

const PopupHeader = styled.header`
  width: 100%;
  display: flex;
  padding: 16px 28px;
  background-color: var(--c-gray-100);
  justify-content: space-between;
`;

const PopupContext = createContext();
const AlertContext = createContext();
const ConfirmContext = createContext();
// popup
export const usePopup = () => useContext(PopupContext);
// alert를 원할땐 useAlert 호출
export const useAlert = () => useContext(AlertContext);
// confirm을 원할땐 useConfirm 호출
export const useConfirm = () => useContext(ConfirmContext);

export const CommonWindowProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");

  const [areaIcon, setAreaIcon] = useState("");
  const [areaTitle, setAreaTitle] = useState("");

  const [subBtnMsg, setSubBtnMsg] = useState("");
  const [mainBtnMsg, setMainBtnMsg] = useState("");

  const [popups, setPopups] = useState([]);
  const [confirmFunc, setConfirmFunc] = useState(() => null);

  const openAlert = ({ message, message2, iconURL, title, mainBtn }) => {
    setShowAlert(true);
    setAreaIcon(iconURL ? "/assets/svg/warnicon.svg" : "/assets/svg/infoicon.svg");
    setAreaTitle(title);

    setMessage(message);
    setMessage2(message2);
    setMainBtnMsg(mainBtn);
  };

  const openConfirm = ({ message, message2, iconURL, title, subBtn, mainBtn, Func }) => {
    setAreaIcon(iconURL ? "/assets/svg/warnicon.svg" : "/assets/svg/infoicon.svg");
    setAreaTitle(title);
    setShowConfirm(true);

    setMessage(message);
    setMessage2(message2);

    setSubBtnMsg(subBtn || subBtnMsg);
    setMainBtnMsg(mainBtn || mainBtnMsg);

    setConfirmFunc(() => Func || null);
  };

  const openPopup = (popupConfig) => {
    setShowPopup(true);
    setPopups((currentPopups) => [...currentPopups, { ...popupConfig, id: Math.random() }]);
    document.body.classList.add("scroll-lock");
    document.activeElement.blur();
  };

  const closeAlert = () => setShowAlert(false);

  const closeConfirm = (typeNo) => {
    if (confirmFunc !== null && typeNo === 2) {
      confirmFunc();
      setShowConfirm(false);
    }

    if (typeNo === 1) {
      setShowConfirm(false);
    }
  };

  const closePopup = () => {
    if (popups.length > 0) {
      const currentPopup = popups[popups.length - 1];

      if (currentPopup.onClose) {
        currentPopup.onClose();
      }
    }

    setPopups((currentPopups) => currentPopups.slice(0, currentPopups.length - 1));
    document.body.classList.remove("scroll-lock");
  };

  const alertComponent = showAlert && (
    <Dimmed>
      <Box>
        <Row $gap="8px" $align="flex-start">
          <IconLink $url={areaIcon} $width="24px" $height="24px"></IconLink>
          <Column $gap="8px" $width="296px">
            <Title> {areaTitle} </Title>
            {message && <Text>{message}</Text>}
            {message2 && <Text>{message2}</Text>}
            <Row $width="100%" $jus="flex-end" $pad="12px 0 0">
              <MainBtn onClick={closeAlert} $size="var(--s-subB)" $width="70px" autoFocus>
                {mainBtnMsg || t("common.check")}
              </MainBtn>
            </Row>
          </Column>
        </Row>
      </Box>
    </Dimmed>
  );

  const confirmComponent = showConfirm && (
    <Dimmed>
      <Box>
        <Row $gap="8px" $align="flex-start">
          <IconLink $url={areaIcon} $width="24px" $height="24px"></IconLink>
          <Column $gap="8px" $width="296px">
            <Title> {areaTitle} </Title>
            {message && <Text>{message}</Text>}
            {message2 && <Text>{message2}</Text>}
            <Row $width="100%" $jus="flex-end" $gap="8px" $pad="12px 0 0">
              <CheckBtn onClick={() => closeConfirm(1)}>{subBtnMsg || t("common.cancel")}</CheckBtn>
              <MainBtn onClick={() => closeConfirm(2)} $width="80px" autoFocus>
                {mainBtnMsg || t("common.delete")}
              </MainBtn>
            </Row>
          </Column>
        </Row>
      </Box>
    </Dimmed>
  );

  const popupComponent = showPopup ? (
    <>
      {popups.map((popupProps) => (
        <Dimmed key={popupProps.id}>
          <PopupContainer>
            <PositionBox $position="relative">
              <PopupHeader>
                <PopupTitle> {popupProps.title} </PopupTitle>
                <IconLink onClick={closePopup} $url={"/assets/svg/closebtn.svg"} $width="24px" $height="24px"></IconLink>
              </PopupHeader>
              <PopupContent height={popupProps.height}>
                {typeof popupProps.content === "function"
                  ? React.createElement(popupProps.content, {
                      ...popupProps.props,
                      closePopup,
                    })
                  : popupProps.content}
              </PopupContent>
            </PositionBox>
          </PopupContainer>
        </Dimmed>
      ))}
    </>
  ) : null;

  return (
    <AlertContext.Provider value={{ openAlert }}>
      <ConfirmContext.Provider value={{ openConfirm }}>
        <PopupContext.Provider value={{ openPopup, closePopup }}>
          {children}
          {popupComponent}
          {alertComponent}
          {confirmComponent}
        </PopupContext.Provider>
      </ConfirmContext.Provider>
    </AlertContext.Provider>
  );
};
