import styled, { css } from "styled-components";

const createPositionBox = ({ $position, $top, $bottom, $trans, $left, $right, $zIndex }) => css`
  position: ${$position};
  top: ${$top};
  bottom: ${$bottom};
  left: ${$left};
  right: ${$right};
  transform: ${$trans};
  z-index: ${$zIndex};
`;

const createFlexStyles = ({
  $gap,
  $jus,
  $mar,
  $pad,
  $flex,
  $borB,
  $bTop,
  $over,
  $overX,
  $overY,
  $white,
  $align,
  $direct,
  $width,
  $shadow,
  $cursor,
  $height,
  $radius,
  $tAlign,
  $flexWrap,
  $minWidth,
  $maxWidth,
  $maxHeight,
  $backColor,
}) => css`
  gap: ${$gap};
  flex: ${$flex};
  align-items: ${$align};
  flex-wrap: ${$flexWrap};
  justify-content: ${$jus};
  flex-direction: ${$direct};

  width: ${$width};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};

  height: ${$height};
  max-height: ${$maxHeight};

  margin: ${$mar};
  padding: ${$pad};

  border-top: ${$bTop};
  border-bottom: ${$borB};
  border-radius: ${$radius};
  box-shadow: ${$shadow};

  text-align: ${$tAlign};
  background-color: ${$backColor};

  overflow: ${$over};
  overflow-x: ${$overX};
  overflow-y: ${$overY};

  cursor: ${$cursor};
  white-space: ${$white};

  display: flex;
`;

const createTextBox = ({
  $color,
  $size,
  $font,
  $weight,
  $line,
  $tAlign,
  $white,
  $width,
  $op,
  $over,
  $textDeco,
  $pad,
  $radius,
  $mask,
  $border,
  $shadow,
}) => css`
  color: ${$color};

  font-size: ${$size};
  font-family: ${$font};
  font-weight: ${$weight};

  line-height: ${$line};
  width: ${$width};

  text-align: ${$tAlign};
  white-space: ${$white};
  opacity: ${$op};
  overflow: ${$over};
  text-decoration: ${$textDeco};

  padding: ${$pad};
  border: ${$border};
  box-shadow: ${$shadow};
  border-radius: ${$radius};

  mask-image: ${$mask};
  -webkit-mask-image: ${$mask};
`;

const createIcon = ({ $url, $bor, $width, $height, $trans, $radius }) => css`
  display: block;
  border: ${$bor};
  width: ${$width};
  height: ${$height};
  background: ${$url};
  border-radius: ${$radius};
  cursor: pointer;
  &.active {
    background: ${$url};
    transform: ${$trans};
  }
`;

const createDropDown = ({ $borB, $width, $border, $height }) => css`
  cursor: pointer;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;
  width: ${$width ? $width : "230px"};
  height: ${$height ? $height : "40px"};

  border-radius: 4px;
  border-bottom: ${$borB};
  border: ${$border ? $border : "2px solid var(--c-gray-300)"};
  font-size: var(--s-caption);
  font-family: var(--f-caption);
`;

const DropDown = styled.button`
  ${createDropDown}
`;

const createDropMenuContainer = ({ $top, $bottom }) => css`
  position: absolute;
  top: ${$top ? $top : "100%"};
  bottom: ${$bottom};

  left: -1px;
  text-align: left;
  z-index: 99;
  width: 101%;
  margin-top: 6px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  background-color: var(--c-white);

  padding: 4px;
`;

const DropMenuContainer = styled.div`
  ${createDropMenuContainer}
`;

const DropMenuBox = styled.ul`
  overflow-y: auto;
  overscroll-behavior: contain;
  height: auto;
  max-height: 300px;
  padding: 4px;

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--c-black-op);
    border-radius: 99px;
  }
`;

const DropMenuList = styled.li`
  padding: 12px;
  background-color: var(--c-white);

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  line-height: var(--l-sub);

  cursor: pointer;

  border-radius: 4px;

  &:hover {
    background-color: var(--c-input);
  }
`;

const createInputTextBox = ({ $height, $border, $backColor, $maxWidth }) => css`
  border: ${$border ? $border : "none !important"};
  box-sizing: border-box;
  background-color: ${$backColor ? $backColor : "var(--c-input)"};
  border-radius: 4px !important;
  padding-left: 10px;
  width: 100%;
  height: ${$height ? $height : "40px"};
  max-width: ${$maxWidth};
  font-family: var(--f-caption);
  font-size: var(--s-caption);

  outline: none;
`;

const createMainBtn = ({ $pad, $size, $font, $line, $width, $height, $minHeight }) => css`
  width: ${$width};
  min-width: 40px;

  height: ${$height};
  min-height: ${$minHeight ? $minHeight : "32px"};
  padding: ${$pad ? $pad : "2px 4px"};

  white-space: nowrap;
  color: var(--c-white);
  border-radius: 4px;
  background-color: var(--c-mint-600);

  font-size: ${$size ? $size : "var(--s-caption)"};
  font-family: ${$font};
  line-height: ${$line};
`;

const createRedBtn = ({ $pad, $width, $size, $font, $line }) => css`
  width: ${$width};
  min-width: 40px;

  min-height: 36px;
  padding: ${$pad};

  white-space: nowrap;
  color: var(--c-white);
  border-radius: 4px;
  background-color: var(--c-red);

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};
`;

const createTable = ({ $borTR, $borTL, $borBR, $borBL, $radius, $maxWidth }) => css`
  max-width: ${$maxWidth};
  width: 100%;
  text-align: center;
  white-space: nowrap;

  border-spacing: 0;
  border-radius: ${$radius ? $radius : "4px"};
  border-top-left-radius: ${$borTL};
  border-top-right-radius: ${$borTR};
  border-bottom-left-radius: ${$borBL};
  border-bottom-right-radius: ${$borBR};
  border-style: hidden;
  border-collapse: collapse;
  box-shadow: var(--shadow2);
`;

const createTableHeader = ({ $pad, $width, $height, $maxWidth, $minHeight }) => css`
  height: ${$height ? $height : "25px"};
  min-height: ${$minHeight ? $minHeight : "25px"};

  width: ${$width};
  max-width: ${$maxWidth};

  padding: ${$pad};

  vertical-align: middle;
  border: 1px solid var(--c-gray-300);
  background-color: var(--c-mint-600);
  color: var(--c-white);
  font-size: var(--s-caption);
  /* font-family: var(--f-caption); */

  &:first-child {
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
`;

const createTableDeps = ({ $pad, $width, $height, $textDeco, $maxWidth, $minHeight }) => css`
  height: ${$height ? $height : "34px"};

  min-height: ${$minHeight ? $minHeight : "34px"};

  width: ${$width};
  max-width: ${$maxWidth};

  padding: ${$pad ? $pad : "0"};

  vertical-align: middle;
  text-decoration: ${$textDeco};
  border: 1px solid var(--c-gray-300);
  font-size: var(--s-caption);
  font-family: var(--f-caption);
`;

const createShadowBox = ({
  $pad,
  $position,
  $width,
  $height,
  $tAlign,
  $maxWidth,
  $minHeight,
  $maxHeight,
}) => css`
  height: ${$height ? $height : "100%"};
  min-height: ${$minHeight};
  max-height: ${$maxHeight};

  width: ${$width ? $width : "100%"};
  max-width: ${$maxWidth};

  padding: ${$pad};
  box-shadow: var(--shadow);
  text-align: ${$tAlign};
  border-radius: 4px;
  position: ${$position};
`;

const createLineSection = ({ $pad, $borT, $borL, $borR, $borB }) => css`
  padding: ${$pad};
  border-top: ${$borT};
  border-left: ${$borL};
  border-right: ${$borR};
  border-bottom: ${$borB};

  width: fit-content;
`;

const createBorderTextBox = ({ $pad, $width, $height, $tAlign }) => css`
  width: ${$width ? $width : "180px"};
  padding: ${$pad ? $pad : "8px 0"};
  border-bottom: 1px solid #aaa;
  height: ${$height ? $height : "40px"};

  font-size: var(--s-caption);
  line-height: var(--l-caption);
  text-align: ${$tAlign};
`;

const createPadBox = ({ $pad, $width }) => css`
  padding: ${$pad};
  width: ${$width};
`;

const createInfoListBox = ({ $gap, $direct, $flexWrap }) => css`
  display: flex;
  flex-direction: ${$direct ? $direct : "column"};
  flex-wrap: ${$flexWrap};

  gap: ${$gap ? $gap : "12px"};
`;

const createGrayBtn = ({ $pad, $mar, $size, $width }) => css`
  background-color: var(--c-input);

  padding: ${$pad ? $pad : "12px 20px"};
  margin: ${$mar};
  color: var(--c-black);

  width: ${$width};
  white-space: nowrap;

  font-size: ${$size ? $size : " var(--s-subB)"};
  line-height: var(--l-subB);

  border-radius: 4px;
`;

const IconLink = styled.span`
  ${createIcon}
`;

const PositionBox = styled.div`
  ${createPositionBox}
`;

const FlexBox = styled.div`
  ${createFlexStyles}
`;

const TextBox = styled.span`
  ${createTextBox}
`;

const GrayBtn = styled.button`
  ${createGrayBtn}
`;

const InfoListBox = styled.ul`
  ${createInfoListBox}
`;

const MainBtn = styled.button`
  ${createMainBtn}
`;

const RedBtn = styled.button`
  ${createRedBtn}
`;

const InputTextBox = styled.input.attrs({ type: "text", autoComplete: "off" })`
  ${createInputTextBox}
`;

const PadBox = styled.div`
  ${createPadBox}
`;

const BorderTextBox = styled.p`
  ${createBorderTextBox}
`;

const Table = styled.table`
  ${createTable}
`;

const TableHeader = styled.th`
  ${createTableHeader}
`;

const TableDeps = styled.td`
  ${createTableDeps}
`;

const ShadowBox = styled.div`
  ${createShadowBox}
`;

const LineSection = styled.div`
  ${createLineSection}
`;

const ContentArea = styled.div`
  position: relative;

  width: 100%;
  padding: 20px;

  border-radius: 4px;
  box-shadow: var(--shadow);
  background-color: var(--c-white);
`;

const ScrollContent = styled.div`
  margin: 0;
  height: 100%;
  width: 100%;
  line-height: 1.2;
  overflow-y: auto;
  white-space: pre-wrap;
  overscroll-behavior: contain;
`;

const NoticeLayOut = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  gap: 12px;
  margin: 12px 0;
  overflow-y: scroll;
  overscroll-behavior: contain;

  width: 100%;
  height: 100%;
  max-height: 600px;

  border-radius: 8px;
  box-shadow: var(--shadow);
`;

const InfoTitle = styled.p`
  min-width: 60px;

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  line-height: var(--l-caption);
  font-weight: bold;

  color: var(--c-toastBg);
  white-space: nowrap;
`;

const InfoList = styled.li`
  min-width: 180px;
`;

const createModalBox = ({ $width }) => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--c-white);

  width: ${$width ? $width : "280px"};
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid var(--c-white);
`;
const ModalBox = styled.div`
  ${createModalBox}
`;

const CheckInput = styled.input.attrs({ type: "checkbox" })`
  padding: 1px 0 !important;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
  background: url(/assets/svg/noncheck_icon.svg);
  &:checked {
    background: url(/assets/svg/checkbox_icon.svg);
  }
  &[disabled] {
    opacity: 0.5;
  }
  &[disabled] + label {
    opacity: 0.5;
  }
`;

const CategoryMenuBox = styled.div`
  width: fit-content;
`;

const CategoryMenu = styled.button`
  min-width: 120px;
  padding: 12px 0;
  text-align: center;
  white-space: nowrap;
  color: var(--c-gray-700);
  background-color: rgba(95, 158, 160, 0.2);

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  &:hover {
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
  transition: 0.2s ease-in-out;
  &.active {
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`;

const AreaTitle = styled.p`
  padding-bottom: 12px;
`;

const SubText = styled.span`
  white-space: nowrap;
  color: #1c1c1c;
`;

const ResetBtn = styled.button`
  width: 80px;
  padding: 0 12px;
  border-radius: 4px;
  white-space: nowrap;
  height: 40px;
  border: 1px solid var(--c-gray-700);
  font-size: var(--s-caption);
  font-family: var(--f-caption);

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &[disabled] + label {
    opacity: 0.5;
  }
`;

const UserManager = styled.div`
  position: relative;
  height: 100%;
`;

const Dimmed = styled.div`
  background-color: var(--c-black-op);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const InfoContainer = styled.div`
  padding-bottom: 20px;
  width: 100%;
`;

const Textarea = styled.textarea`
  width: 250px;
  text-align: start;
  padding: 8px 0px;
  border: none;
  border-bottom: 1px solid #aaa;
  height: 36px;

  font-size: var(--s-sub);
  font-family: var(--f-sub);
  line-height: var(--l-sub);
  resize: none;
`;

const useStyle = () => {
  return {
    //버튼
    RedBtn,
    GrayBtn,
    MainBtn,
    ResetBtn,
    CategoryMenu,

    //인풋
    CheckInput,
    InputTextBox,
    Textarea,

    //텍스트
    TextBox,
    SubText,
    AreaTitle,
    InfoTitle,
    BorderTextBox,

    //레이아웃
    PadBox,
    FlexBox,
    ModalBox,
    ShadowBox,

    ContentArea,
    UserManager,
    NoticeLayOut,
    ScrollContent,

    CategoryMenuBox,

    InfoList,
    InfoListBox,
    InfoContainer,

    LineSection,
    Dimmed,
    //아이콘
    IconLink,

    //위치조정
    PositionBox,

    //드롭다운
    DropDown,
    DropMenuBox,
    DropMenuList,
    DropMenuContainer,

    // 테이블
    Table,
    TableDeps,
    TableHeader,
  };
};

export default useStyle;
