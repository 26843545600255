/**
 * file : SwalAlert.jsx
 * 설명 : 알람
 * 
 * Icon 종류: success, error, warning, info, question
 * 알림 창에서 검색 및 이미지 띄우기 가능
 */

import Swal from "sweetalert2";

/**
 * 기본 알림
 * @param {String} icon icon 값
 * @param {String} title 제목
 * @param {html} html 표기할 html 코드
 */
const SwalAlert = ( icon, title, html ) => {
  Swal.fire({
    icon: icon,
    title: title,
    html: html,
    confirmButtonText: "확인"
  });
};

/**
 * 기본 알림(콜백 함수 추가)
 * @param {String} icon icon 값
 * @param {String} title 제목
 * @param {html} html 표기할 html 코드
 * @param {Function} CallBack 콜백 함수
 */
const SwalAlertCallBack = ( icon, title, html, CallBack ) => {
  Swal.fire({
    backdrop: false,
    icon: icon,
    title: title,
    html: html,
    confirmButtonText: "확인"
  }).then((res) => {
    if (res.isConfirmed) {
      CallBack()
    }
  });
};
  
/**
 * CallBack 가능 알림 창(확인, 취소)
 * @param {String} icon icon 값
 * @param {String} title 제목
 * @param {html} html 표기할 html 코드
 * @param {Function} CallBack 콜백 함수
 */
const SwalConfirm = (icon, title, html, CallBack) => {
  Swal.fire({
    icon: icon,
    title: title,
    html: html,
    showCancelButton: true,
    confirmButtonText: "확인",
    cancelButtonText: "취소",
  }).then((res) => {
    if (res.isConfirmed) {
      CallBack()
    }
  });
};

/**
 * 토스트 메세지
 * @param {String} icon icon 값
 * @param {String} title 제목
 */
const SwalToast = (icon, title) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'center-center',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  Toast.fire({
    icon: icon,
    title: title
  })
};

export { SwalAlert, SwalAlertCallBack, SwalConfirm, SwalToast }