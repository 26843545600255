import api from "interceptor/api";
import { useEffect, useState } from "react";
import { FormBox } from "style/custom/useCustomLayout";
import { TextBox } from "style/useTextStyles";
import { SelectForm } from "./CommonForm";

const BankSelectForm = ({ useForm, useStated, width, isRequired }) => {
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    getBankList();
  }, []);

  const createBankEnum = (data) => {
    const newEnum = [];

    data.forEach((item) => {
      // 새로운 객체 생성
      let newItem = {
        label: item.bank_name,
        value: item.no,
        key: item.no,
      };

      // 변환된 객체를 새로운 형식의 객체에 추가
      newEnum.push(newItem);
    });

    // 새로운 형식의 객체 반환
    setBankList(newEnum);
  };

  const getBankList = () => {
    const apiUrl = "/v1/user/all/bank/list";
    api
      .get(apiUrl, {})
      .then((res) => {
        createBankEnum(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  return (
    <FormBox $flex="1" $align="center">
      <TextBox $width={width} $minWidth="120px" $line="var(--l-sub)">
        은행명 {isRequired && <TextBox $color="red">*</TextBox>}
      </TextBox>
      {bankList && (
        <SelectForm name="account_bank" options={bankList} {...useForm} {...useStated} />
      )}
    </FormBox>
  );
};

export default BankSelectForm;
