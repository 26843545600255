import React, { useEffect, useRef, useState } from "react";

import useFormData from "common/useFormData";
import { AreaTitle } from "style/useTextStyles";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { PositionBox } from "style/useLayoutStyles";
import { InputForm, RadioForm, SelectForm } from "components/common/CommonForm";
import { Column, FilterBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useCommonState } from "contexts/useCommonState";
import { usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { useCountPerPage } from "constants/select/useValue";
import { addAllFromEnum } from "utils/common/EnumUtil";
import useStateData from "common/useStateData";
import { dbUser, dbUserState2 } from "enum/db/useUserEnum";
import { agentUserFilterCondition } from "enum/agent/userAgentUserEnum";
import NoDataTable from "components/common/NoDataTable";
import UserState2Hover from "components/common/UserState2Hover";
import UserNameTab from "components/common/UserNameTab";
import { t } from "i18next";

const AgentUserList = ({ agentNo }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { closePopup } = usePopup();

  const [initAgentTypeList, setInitAgentTypeList] = useState([]);
  const [initAgentList, setInitAgentList] = useState([]);

  const [agentTypeList, setAgentTypeList] = useState([]);
  const [agentList, setAgentList] = useState([]);

  const [agentInfo, setAgentInfo] = useState();
  const popupRef = useRef();

  useEffect(() => {
    getAgentSummaryInfo();
    getAgentTypeList();
    getSubAgentList();
  }, []);

  const getAgentTypeList = () => {
    const apiUrl = "/v1/agent/info/agent-types";
    api
      .get(apiUrl, {
        params: {
          agent_no: agentNo,
        },
      })
      .then((res) => {
        createAgentTypeEnum(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const getAgentSummaryInfo = () => {
    const apiUrl = `/v1/agent/info/summary/${agentNo}`;
    api
      .get(apiUrl, {})
      .then((res) => {
        setAgentInfo(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const getSubAgentList = () => {
    const apiUrl = `/v1/agent/info/sub-agent/list/${agentNo}`;
    api
      .get(apiUrl, {})
      .then((res) => {
        createAgentListEnum(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const createAgentListEnum = (data) => {
    const newEnum = [];

    let allItem = {
      label: t("common.all"),
      value: "",
      key: 0,
      type: "",
    };
    newEnum.push(allItem);

    data.forEach((item) => {
      // 새로운 객체 생성
      let newItem = {
        label: item.name,
        value: item.code,
        key: item.code,
        type: item.type,
      };

      // 변환된 객체를 새로운 형식의 객체에 추가
      newEnum.push(newItem);
    });

    setAgentList(newEnum);
    setInitAgentList(newEnum);
  };

  const createAgentTypeEnum = (data) => {
    const newEnum = [];

    let allItem = {
      label: t("common.all"),
      value: "",
      key: 0,
    };
    newEnum.push(allItem);

    data.forEach((item) => {
      // 새로운 객체 생성
      let newItem = {
        label: item.name,
        value: item.type,
        key: item.depth,
      };

      // 변환된 객체를 새로운 형식의 객체에 추가
      newEnum.push(newItem);
    });

    // 새로운 형식의 객체 반환
    setAgentTypeList(newEnum);
    setInitAgentTypeList(newEnum);
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/user/statistics-total/list/${agentNo}`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "agent_code-asc",
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
    setLoading(false);
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_agent_type: "",
      search_agent_code: "",
      search_user_state1: "",
      search_user_state2: "",

      search_agent_user_condition: "",
      search_user_nickname: "",
    },
    getTableItem,
    useTG
  );

  useEffect(() => {
    useSearch.formValue.search_agent_code = "";
    const searchType = useSearch.formValue.search_agent_type;

    if (searchType) {
      const filteredData = initAgentList.filter((item) => item.type === searchType);
      const searchCondition = useSearch.formValue.search_agent_user_condition;

      if (searchCondition === agentUserFilterCondition.DIRECT.value) {
        setAgentList(filteredData);
      } else {
        const allItem = {
          label: t("common.all"),
          value: "",
          key: 0,
          type: "",
        };

        setAgentList([allItem, ...filteredData]);
      }
    } else {
      setAgentList(initAgentList);
    }
  }, [useSearch.formValue.search_agent_type]);

  useEffect(() => {
    const searchCondition = useSearch.formValue.search_agent_user_condition;
    if (searchCondition) {
    }
    switch (searchCondition) {
      case agentUserFilterCondition.DIRECT.value:
        const agentType = initAgentTypeList.filter((item) => item.value === agentInfo.type);
        const agent = initAgentList.filter((item) => item.value === agentInfo.code);

        setAgentTypeList(agentType);
        setAgentList(agent);

        useSearch.formValue.search_agent_type = agentType[0].value;
        useSearch.formValue.search_agent_code = agent[0].value;

        break;

      case agentUserFilterCondition.SUB.value:
        const subAgentType = initAgentTypeList.filter((item) => item.value !== agentInfo.type);
        const subAgent = initAgentList.filter((item) => item.value !== agentInfo.code);

        setAgentTypeList(subAgentType);
        setAgentList(subAgent);

        useSearch.formValue.search_agent_type = subAgentType[0].value;
        useSearch.formValue.search_agent_code = subAgent[0].value;

        break;

      default:
        useSearch.formValue.search_agent_type = "";
        useSearch.formValue.search_agent_code = "";
        setAgentTypeList(initAgentTypeList);
        setAgentList(initAgentList);
        break;
    }
  }, [useSearch.formValue.search_agent_user_condition]);

  return (
    <>
      <Column $gap="48px" $width="1500px" ref={popupRef}>
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.user_detail_info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Column $width="100%">
            <FilterBox $pad="12px">
              <Row $gap="12px" $align="center">
                <Column $gap="4px">
                  <FilterTitle>{t("agent.type")}</FilterTitle>
                  <Row $gap="4px">
                    {agentTypeList && (
                      <SelectForm
                        name="search_agent_type"
                        placeholder={agentTypeList[0]?.label || t("common.all")}
                        options={agentTypeList}
                        {...useSearch}
                        {...useStated}
                      />
                    )}
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.sub_agent_name")}</FilterTitle>
                  <Row $gap="4px">
                    {agentTypeList && (
                      <SelectForm
                        name="search_agent_code"
                        placeholder={agentList[0]?.label || t("common.all")}
                        options={agentList}
                        {...useSearch}
                        {...useStated}
                      />
                    )}
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.user_state1")}</FilterTitle>
                  <Row $gap="4px">
                    <SelectForm
                      name="search_user_state1"
                      placeholder={t("common.all")}
                      options={addAllFromEnum(dbUser.state1)}
                      {...useSearch}
                      {...useStated}
                    />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.user_state2")}</FilterTitle>
                  <Row $gap="4px">
                    <SelectForm
                      name="search_user_state2"
                      placeholder={t("common.all")}
                      options={addAllFromEnum(dbUserState2.state2)}
                      {...useSearch}
                      {...useStated}
                    />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("user.user")}</FilterTitle>
                  <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
                </Column>

                <Column $gap="4px">
                  <FilterTitle></FilterTitle>
                  <Row $align="center" $gap="12px" $height="34px">
                    <RadioForm name="search_agent_user_condition" options={agentUserFilterCondition} {...useSearch} />
                  </Row>
                </Column>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                  {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-count" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
                </Row>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useTG.extendData}
                />
              </Row>
            </Row>

            {useTG.error ? (
              <span>error</span>
            ) : (
              <>
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">{t("common.no")}</TableHeader>
                      <TableHeader>{t("agent.code")}</TableHeader>
                      <TableHeader>{t("agent.agent")}</TableHeader>
                      <TableHeader>{t("agent.user")}</TableHeader>
                      <TableHeader>{t("agent.user_state")}</TableHeader>
                      <TableHeader>{t("agent.accumulated_bet_money")}</TableHeader>
                      <TableHeader>{t("agent.accumulated_win_money")}</TableHeader>
                      <TableHeader>{t("agent.accumulated_bet_win_money")}</TableHeader>
                      <TableHeader>{t("agent.accumulated_account_in_money")}</TableHeader>
                      <TableHeader>{t("agent.accumulated_account_out_money")}</TableHeader>
                      <TableHeader>{t("agent.accumulated_account_in_out_money")}</TableHeader>
                      <TableHeader>{t("agent.money")} (C)</TableHeader>
                      <TableHeader>{t("agent.point")} (P)</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>
                          <TableDeps $tAlign="left">{item.agent_code}</TableDeps>
                          <TableDeps $tAlign="left">{`${item.agent_name}(${item.agent_type_name})`}</TableDeps>

                          <TableDeps $tAlign="left">
                            <Row $gap="6px" $align="center">
                              <UserState2Hover state2List={item.state2_arr} />
                              <UserNameTab no={item.no} nickname={item.nickname} refresh={getTableItem} popupRef={popupRef} />
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <TextBox
                              className={
                                item.state1 === dbUser.state1.S.value
                                  ? "complete"
                                  : item.state1 === dbUser.state1.B.value
                                  ? "warn"
                                  : item.state1 === dbUser.state1.O.value
                                  ? "withdraw"
                                  : item.state1 === dbUser.state1.W.value
                                  ? "normal"
                                  : item.state1 === dbUser.state1.T.value
                                  ? "test"
                                  : ""
                              }
                            >
                              {dbUser.state1[item.state1]?.label || "-"}
                            </TextBox>
                          </TableDeps>

                          <TableDeps $tAlign="right">{parseInt(item.bet_money || 0).toLocaleString()}</TableDeps>
                          <TableDeps $tAlign="right">{parseInt(item.win_money || 0).toLocaleString()}</TableDeps>
                          <TableDeps $tAlign="right">
                            {((item.bet_money || 0) - (item.win_money || 0)).toLocaleString(undefined, {
                              signDisplay: "exceptZero",
                            })}
                          </TableDeps>
                          <TableDeps $tAlign="right">{item.account_in_money?.toLocaleString() || 0}</TableDeps>
                          <TableDeps $tAlign="right">{item.account_out_money?.toLocaleString() || 0}</TableDeps>
                          <TableDeps $tAlign="right">
                            {((item.account_in_money || 0) - (item.account_out_money || 0)).toLocaleString(undefined, {
                              signDisplay: "exceptZero",
                            })}
                          </TableDeps>
                          <TableDeps $tAlign="right">{item.money?.toLocaleString() || 0}</TableDeps>
                          <TableDeps $tAlign="right">{item.point?.toLocaleString() || 0}</TableDeps>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
                {useTG && useTG.data?.length === 0 && <NoDataTable />}
              </>
            )}
            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
              <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
              </PositionBox>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useTG.extendData}
                  top="top"
                />
              </Row>
            </Row>
          </Column>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentUserList;
