import { usePaging } from "constants/usePaging";
import { useEffect, useState } from "react";

function useTableData(isFirstSearch, countPerPage) {
  let fnSearch;

  const [error, setError] = useState(false);
  const [isInitSearch, setInitSearch] = useState(isFirstSearch);
  const [data, setData] = useState(null);
  const [pagingInfo, setPagingInfo] = useState(null);
  // 조회 확장 정보
  const [extendData, setExtendData] = useState({
    pagingData: {
      page: usePaging.defaultCurrentPage,
      countPerPage: countPerPage ? countPerPage : usePaging.defaultCountPerPage,
    },
    sortNm: "",
  });

  const onSearchFunc = (f) => {
    fnSearch = f;
  };

  const onSortClick = (lSortData) => {
    setExtendData((prevData) => ({ ...prevData, sortNm: lSortData }));
  };

  const onPageChange = (page, isSearch = true) => {
    if (!isSearch) {
      setInitSearch(false);
    }

    setExtendData((prevData) => ({
      ...prevData,
      pagingData: { ...prevData.pagingData, page },
    }));
  };

  const getPagingDataToUri = () => {
    return (
      "page=" +
      extendData.pagingData.page +
      "&countPrePage=" +
      extendData.pagingData.countPerPage +
      "&sortNm=" +
      extendData.sortNm
    );
  };

  function handleChange(e, status) {
    if (status) {
      if (status.name === "countPerPage") {
        setExtendData((prev) => ({
          ...prev,
          pagingData: {
            ...prev.pagingData,
            countPerPage: e.value,
          },
        }));
      }
    }
  }

  useEffect(() => {
    if (isInitSearch) {
      fnSearch(false);
    }
    setInitSearch(true);
  }, [extendData]);

  return {
    data,
    error,

    pagingInfo,
    extendData,

    setData,

    setError,

    setPagingInfo,
    setExtendData,
    getPagingDataToUri,

    onSortClick,
    onPageChange,
    onSearchFunc,
    onChange: handleChange,
  };
}

export default useTableData;
