import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "interceptor/api";

const NoticeEnd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notice, setNotice] = useState();

  useEffect(() => {
    const getNoticeApi = api.get(`/v1/api/notice?noticeNo=${id}`);
    getNoticeApi.then((response) => {
      setNotice(response.data);
    });
    getNoticeApi.catch((error) => {
      alert("공지사항 작성에 실패하였습니다. error : ", error);
      console.error(error);
    });
  }, [id]);

  const submitModifyClick = () => {
    navigate(`/notice/modify/${id}`);
  };

  const submitDeleteNotice = (noticeNo) => {
    // noticeNo;
    const deleteNoticeApi = api.delete(`/v1/api/notice?noticeNo=${noticeNo}`);

    deleteNoticeApi.then((response) => {
      navigate("/notice/list");
    });
    deleteNoticeApi.catch((error) => {
      alert("공지사항 작성에 실패하였습니다. error : ", error);
      console.error(error);
    });
  };

  return (
    <div>
      <h1>공지사항 ID : {id}</h1>
      {notice ? (
        <div>
          <h1 style={{ fontSize: "30px" }}>제목 : {notice.title}</h1>
          <p style={{ fontSize: "24px" }}>내용 : {notice.contents}</p>
          <button
            onClick={submitModifyClick}
            style={{ marginRight: "10px" }}
          >
            수정
          </button>
          <button onClick={() => submitDeleteNotice(id)}>삭제</button>
        </div>
      ) : (
        <p>Loading notice...</p>
      )}
    </div>
  );
};

export default NoticeEnd;
