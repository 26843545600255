/** @format */
import { useEffect, useRef, useState } from "react";

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
// 셀렉트 벨류 constant
import { depositFilterCondition, inoutState, useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { EachCheckForm, InputForm, NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle, HoverTableText } from "style/custom/useCustomText";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, InfoTabBox, InfoTabList, LineSection, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import UserInfo from "components/admin/user/info/UserInfo";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import { BlockBtn, DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import UserLevelFilter from "components/common/UserLevelFilter";
import { useCommonState } from "contexts/useCommonState";
import { dbUser, dbUserHistoryAccount } from "enum/db/useUserEnum";
import { checkboxYNEnum, infoTabEnum, validationTypeEnum } from "enum/useCommonEnum";
import { useTranslation } from "react-i18next";
import { useAlarmCheck } from "utils/ApiUtil";
import { getFormatDatetime } from "utils/date/DateUtil";
import DepositRejectForm from "./DepositRejectForm";
import UserLevelTab from "components/common/UserLevelTab";

const DepositManagement = () => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();
  const { t } = useTranslation();

  const [todayDepositRequestCount, setTodayDepositRequestCount] = useState(0);
  const [completedDepositCount, setCompletedDepositCount] = useState(0);
  const [rejectedDepositCount, setRejectedDepositCount] = useState(0);

  const [userNm, setUserNm] = useState("");
  const [openUserInfoTab, setOpenUserInfoTab] = useState(false);
  const [openAgentInfoTab, setOpenAgentInfoTab] = useState(false);
  const userTabRef = useRef(null);
  const agentTabRef = useRef(null);

  useAlarmCheck("deposit");

  const infoTabToggle = (tabType, Nm) => {
    if (tabType === infoTabEnum.tabType.u.value) {
      setUserNm(Nm);
      setOpenUserInfoTab(!openUserInfoTab);
    } else if (tabType === infoTabEnum.tabType.a.value) {
      setUserNm(Nm);
      setOpenAgentInfoTab(!openAgentInfoTab);
    }
  };

  useEffect(() => {
    const onBlurEvent = (event) => {
      if (userTabRef.current && !userTabRef.current.contains(event.target)) {
        setOpenUserInfoTab(false);
      }
      if (agentTabRef.current && !agentTabRef.current.contains(event.target)) {
        setOpenAgentInfoTab(false);
      }
    };

    document.addEventListener("mousedown", onBlurEvent);
    return () => {
      document.removeEventListener("mousedown", onBlurEvent);
    };
  }, []);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showErrorAlert(item.msg.code);
        if (item.msg.values === "notExist") {
          getTableItem();
        }
      }
    });
  }, [useStated.errors]);

  const showErrorAlert = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showConditionFailAlert = (isNoSelection) => {
    openAlert({
      title: isNoSelection ? t("user.user_not_select") : t("inout.condition_fail"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showMessageAlert = (message) => {
    openAlert({
      title: t("deposit.deposit_reject_reason"),
      message: message || "-",
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showApproveDepositRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("deposit.deposit_complete") : t("deposit.deposit_fail"),
      message: isSuccess ? t("deposit.deposit_complete_msg") : t("deposit.deposit_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  const showDepositCancelAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("deposit.deposit_cancel_complete") : t("deposit.deposit_cancel_fail"),
      message: isSuccess ? t("deposit.deposit_cancel_complete_msg") : t("deposit.deposit_cancel_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 입금 완료처리 Confirm
   */
  const showApproveDepositRequestConfirm = () => {
    const historyArr = getCheckedHistoryArr();
    const historyNoList = historyArr.map((item) => item.no);

    const userList = getCheckedUserList();

    if (!historyArr.length) {
      showConditionFailAlert(true);
      return;
    }

    const invalidItems = historyArr.filter((item) => item.state !== "D" && item.state !== "C");

    if (invalidItems.length) {
      showConditionFailAlert(false);
    } else {
      openConfirm({
        title: t("deposit.deposit_approve"),
        message: t("deposit.deposit_approve_confirm", { number: historyNoList.length }),
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () => approveDepositRequest(historyNoList, userList),
      });
    }
  };
  const showApproveSingleDepositRequestConfirm = (historyNo, nickName, userNo, agentNo) => {
    const userInfo = {
      no: userNo,
      agent_no: agentNo
    };

    openConfirm({
      title: t("deposit.deposit_approve"),
      message: t("deposit.deposit_approve_single_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => approveDepositRequest([historyNo], [userInfo]),
    });
  };
  /**
   * 입금 완료처리
   */
  const approveDepositRequest = (historyNoList, userList) => {
    setLoading(true);
    const apiUrl = `/v1/inout/deposit/approve`;
    api
      .put(apiUrl, {
        history_arr: historyNoList,
        user_arr: userList,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showApproveDepositRequestAlert(true);
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showApproveDepositRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * 입금 불가처리 팝업
   */
  const showRejectDepositRequestPopup = () => {
    const historyArr = getCheckedHistoryArr();
    const historyNoList = historyArr.map((item) => item.no);

    const userList = getCheckedUserList();

    if (!historyArr.length) {
      showConditionFailAlert(true);
      return;
    }

    const invalidItems = historyArr.filter((item) => item.state !== "D" && item.state !== "C");

    if (invalidItems.length) {
      showConditionFailAlert(false);
    } else {
      openPopup({
        title: t("deposit.deposit_reject"),
        content: DepositRejectForm,
        props: {
          historyNoList,
          userList,
          getTableItem,
        },
      });
    }
  };

  const showRejectSingleDepositRequestPopup = (historyNo, nickName, userNo, agentNo) => {
    const userInfo = {
      no: userNo,
      agent_no: agentNo
    };
    openPopup({
      title: t("deposit.deposit_reject"),
      content: DepositRejectForm,
      props: {
        historyNoList: [historyNo],
        userList: [userInfo],
        nickName,
        userNo,
        userInfo,
        getTableItem,
      },
    });
  };

  /**
   * 입금 취소 처리
   */
  const showDepositCancelConfirm = (historyNo, nickName, userNo) => {
    openConfirm({
      title: t("deposit.deposit_cancel"),
      message: t("deposit.deposit_cancel_confirm_msg1", { nickName: nickName || "-", userNo: userNo || "-" }),
      message2: t("deposit.deposit_cancel_confirm_msg2"),
      iconURL: true,
      mainBtn: t("common.check"),
      Func: () => depositCancel([historyNo]),
    });
  };
  const depositCancel = (checkedHistoryNo) => {
    setLoading(true);
    const apiUrl = `/v1/inout/deposit/cancel`;
    api
      .put(apiUrl, {
        history_arr: checkedHistoryNo,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showDepositCancelAlert(true);
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showDepositCancelAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCheckedHistoryArr = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no, state: item.state }));
  };

  const getCheckedUserList = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.user_no, agent_no: item.agent_no }));
  }

  const showUserInfoPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("user.tab_user_info")} (${nickName})` : t("user.tab_user_info"),
      content: UserInfo,
      props: {
        userNo,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/inout/deposit/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setTodayDepositRequestCount(res.data.todayDepositRequestCount);
        setCompletedDepositCount(res.data.completedDepositCount);
        setRejectedDepositCount(res.data.rejectedDepositCount);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_no: "",
      search_user_state1: "",
      search_inout_state: "",
      search_manage_user_yn: "Y",

      search_calendar_category: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.deposit_and_withdrawal_manage")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.deposit_manage")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle >
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.search_user_state")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />

            <Column $gap="4px">
              <FilterTitle>{t("inout.process_status")}</FilterTitle>
              <SelectForm name="search_inout_state" placeholder={t("common.all")} options={inoutState} {...useSearch} {...useStated} />
            </Column>
            <Row $pad="28px 0 0 0">
              <EachCheckForm
                $size="var(--s-caption)"
                name="search_manage_user_yn"
                labelFor="search_manage_user_yn"
                options={checkboxYNEnum}
                labelName={t("filter.include_manage_user")}
                {...useSearch}
              />
            </Row>

            <Column $gap="4px">
              <FilterTitle>{t("deposit.deposit_request_process_data")}</FilterTitle>
              <Row $gap="4px" $align="center">
                <SelectForm
                  name="search_calendar_category"
                  placeholder={t("common.select")}
                  options={depositFilterCondition}
                  {...useSearch}
                  {...useStated}
                />
                <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("deposit.today_deposit_request_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{todayDepositRequestCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("inout.complete")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{completedDepositCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("inout.impossible")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{rejectedDepositCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>
        </Row>

        <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showApproveDepositRequestConfirm()}>
              {t("deposit.deposit_approve")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showRejectDepositRequestPopup()}>
              {t("deposit.deposit_reject")}
            </RedBtn>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("inout.parent_agent")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader>{t("user.user")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_level")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_state")}</TableHeader>
                  <TableHeader>{t("deposit.deposit_account")}</TableHeader>
                  <TableHeader $width="130px">{t("deposit.deposit_amount")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.cumulative_count")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.request_at")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.process_at")}</TableHeader>
                  <TableHeader>{t("user.processor")}</TableHeader>
                  <TableHeader $width="130px">{t("inout.process_status")}</TableHeader>
                  <TableHeader>{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow
                          key={item.no}
                          className={
                            item.state === dbUserHistoryAccount.state.P.value
                              ? "complete"
                              : item.state === dbUserHistoryAccount.state.B.value
                                ? "warn"
                                : item.state === dbUserHistoryAccount.state.C.value
                                  ? "normal"
                                  : "wait"
                          }
                        >
                          <TableDeps>
                            <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                          </TableDeps>
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.parent_code || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left" onClick={() => infoTabToggle(infoTabEnum.tabType.u.value, item.no)}>
                            <HoverTableText>
                              {`${item.user_nickname || "-"} (${item.user_no || "-"})`}
                              {openUserInfoTab && item.no === userNm && (
                                <InfoTabBox ref={userTabRef}>
                                  <InfoTabList onClick={() => showUserInfoPopup(item.user_no, item.user_nickname)}>
                                    {t("user.tab_user_info")}
                                  </InfoTabList>
                                </InfoTabBox>
                              )}
                            </HoverTableText>
                          </TableDeps>

                          <TableDeps>
                            <UserLevelTab item={item} />
                          </TableDeps>

                          <TableDeps>{dbUser.state1[item.state1]?.label || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.account_name || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.amount?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps $tAlign="right">{item.account_in_count?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps>{getFormatDatetime(item.create_at) || "-"}</TableDeps>
                          <TableDeps>{getFormatDatetime(item.process_at) || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{`${item.process_nickname || "-"} (${item.process_no || "-"})`}</TableDeps>

                          <TableDeps>
                            <TextBox
                              className={
                                item.state === dbUserHistoryAccount.state.P.value
                                  ? "complete"
                                  : item.state === dbUserHistoryAccount.state.B.value
                                    ? "warn"
                                    : item.state === dbUserHistoryAccount.state.C.value
                                      ? "normal"
                                      : "wait"
                              }
                            >
                              {dbUserHistoryAccount.state[item.state]?.label || "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $width="120px">
                            <Row $width="100%" $jus="center" $gap="6px">
                              {item.user_no ? (
                                <>
                                  {(item.state === dbUserHistoryAccount.state.D.value ||
                                    item.state === dbUserHistoryAccount.state.C.value) && (
                                      <>
                                        <ModifyBtn
                                          name={t("deposit.approve")}
                                          eventProps={() =>
                                            showApproveSingleDepositRequestConfirm(item.no, item.user_nickname, item.user_no, item.agent_no)
                                          }
                                        />
                                        <DeleteBtn
                                          name={t("deposit.reject")}
                                          eventProps={() =>
                                            showRejectSingleDepositRequestPopup(item.no, item.user_nickname, item.user_no, item.agent_no)
                                          }
                                        />
                                      </>
                                    )}
                                  {item.state === dbUserHistoryAccount.state.P.value && (
                                    <ModifyBtn
                                      name={t("common.cancel")}
                                      eventProps={() => showDepositCancelConfirm(item.no, item.user_nickname, item.user_no)}
                                    />
                                  )}
                                  {item.state === dbUserHistoryAccount.state.B.value && (
                                    <BlockBtn name={t("inout.reason")} eventProps={() => showMessageAlert(item.message)} />
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </Row>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showApproveDepositRequestConfirm()}>
              {t("deposit.deposit_approve")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showRejectDepositRequestPopup()}>
              {t("deposit.deposit_reject")}
            </RedBtn>
          </Row>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default DepositManagement;
