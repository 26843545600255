const valueLoginLog = {
  status: {
    S: {
      label: "로그인 성공",
      value: "S",
      key: "S",
    },
    F: {
      label: "로그인 실패",
      value: "F",
      key: "F",
    },
    O: {
      label: "로그아웃",
      value: "O",
      key: "O",
    },
  },
};

const valuePointLog = {
  type: {
    IN: {
      label: "지급",
      value: "IN",
      key: "IN",
    },
    OUT: {
      label: "회수",
      value: "OUT",
      key: "OUT",
    },
    P: {
      label: "환전",
      value: "P",
      key: "P",
    },
  },
}

export { valueLoginLog, valuePointLog };