import React from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useCommonState } from "contexts/useCommonState";
// style import
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { AreaTitle, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { Column, FilterBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import { getFormatDatetime } from "utils/date/DateUtil";
import { FilterTitle } from "style/custom/useCustomText";
import { InputForm, SelectForm } from "components/common/CommonForm";
import useFormData from "common/useFormData";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { usePopup } from "contexts/useWindow";
import CalendarFilter from "components/common/CalendarFilter";
import { dbLogLogin } from "enum/db/useLogEnum";
import { NormalStatus, WarnStatus } from "components/common/TableStatus";
import { useCountPerPage } from "constants/select/useValue";
import NoDataTable from "components/common/NoDataTable";
import { addAllFromEnum } from "utils/common/EnumUtil";
import { t } from "i18next";

const LogLogin = ({ agentNo, no }) => {
  const { closePopup } = usePopup();
  const { setLoading } = useCommonState();

  const getTableItem = () => {
    setLoading(true);
    const apiUrl = `/v1/agent/info/log-login/${agentNo}`;

    api
      .get(apiUrl, {
        params: {
          no,
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "no-desc",
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_message: "",
      start_date: "",
      end_date: "",
      date_period: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column className="area-box">
        <SubTitleBox>
          <Row $gap="6px" $align="center">
            <AreaTitle>{t("agent.access_list")}</AreaTitle>
          </Row>
        </SubTitleBox>

        <Column $width="100%">
          <FilterBox $pad="12px">
            <Row $gap="12px" $align="center">
              <Column $gap="4px">
                <FilterTitle>{t("agent.access_at")}</FilterTitle>
                <Row $gap="4px">
                  <CalendarFilter {...useSearch} end />
                </Row>
              </Column>

              <Column $gap="4px">
                <FilterTitle>{t("agent.access_status")}</FilterTitle>
                <Row $gap="4px">
                  <SelectForm
                    name="search_status"
                    placeholder={t("common.all")}
                    options={addAllFromEnum(dbLogLogin.status)}
                    {...useSearch}
                  />
                </Row>
              </Column>

              <Column $gap="4px">
                <FilterTitle>{t("agent.message")}</FilterTitle>
                <Row $gap="4px">
                  <InputForm type="text" name="search_message" placeholder={t("common.input")} {...useSearch} />
                </Row>
              </Column>
            </Row>

            <Row $gap="8px">
              <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
              </MainBtn>
              <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
              </WhiteBtn>
            </Row>
          </FilterBox>

          <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
            <Row $gap="10px" $align="center">
              <Row className="total-count" $gap="4px">
                <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                  {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
                </TextBox>
                <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
              </Row>
            </Row>

            <Row $gap="8px">
              <SelectForm
                {...useTG}
                name="countPerPage"
                placeholder={useCountPerPage[0].label}
                size={"var(--s-table)"}
                line={"var(--l-table)"}
                options={useCountPerPage}
                formValue={useCountPerPage.value}
              />
            </Row>
          </Row>

          {useTG.error ? (
            <span>error</span>
          ) : (
            <>
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="60px">{t("common.no")}</TableHeader>
                    <TableHeader $width="300px">{t("agent.access_at")}</TableHeader>
                    <TableHeader $width="300px">{t("agent.logout_at")}</TableHeader>
                    <TableHeader $width="180px">{t("agent.access_ip")}</TableHeader>
                    <TableHeader $width="80px">{t("common.state")}</TableHeader>
                    <TableHeader>{t("agent.message")}</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => (
                      <TableRow key={item.no} className="table-content">
                        <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>

                        <TableDeps>
                          <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                            {item.login_time ? getFormatDatetime(item.login_time) : "-"}
                          </TextBox>
                        </TableDeps>
                        <TableDeps>
                          <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                            {item.logout_time ? getFormatDatetime(item.logout_time) : "-"}
                          </TextBox>
                        </TableDeps>
                        <TableDeps>{item.access_ip}</TableDeps>

                        <TableDeps>
                          <Row $width="100%" $jus="center">
                            {item.status === dbLogLogin.status.S.value ? (
                              <NormalStatus name={dbLogLogin.status[item.status].label} />
                            ) : (
                              <WarnStatus name={dbLogLogin.status[item.status].label} />
                            )}
                          </Row>
                        </TableDeps>
                        <TableDeps $tAlign="left">{item.message}</TableDeps>
                      </TableRow>
                    ))}
                </tbody>
              </Table>
              {useTG && useTG.data?.length === 0 && <NoDataTable />}
            </>
          )}
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>

            <Row $gap="8px">
              <SelectForm
                {...useTG}
                name="countPerPage"
                placeholder={useCountPerPage[0].label}
                size={"var(--s-table)"}
                line={"var(--l-table)"}
                options={useCountPerPage}
                formValue={useCountPerPage.value}
                top="top"
              />
            </Row>
          </Row>
        </Column>
        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};


export default LogLogin;
