import React from "react";
import styled from "styled-components";

const CategoryMenuBox = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--c-gray-300);
  margin: 0 0 20px;
`;

const CategoryMenu = styled.button`
  padding: 10px 0;
  text-align: center;
  white-space: nowrap;
  color: var(--c-gray-700);
  background-color: var(--c-white);

  font-size: var(--s-title);
  line-height: var(--l-title);
  font-family: var(--f-title);

  &.active {
    color: var(--c-gray-900);
    border-bottom: 3px solid var(--c-mint-600);
  }
`;

const CommonCategoryTab = ({ menus, tabEnum, tabMove }) => {
  // 액티브 조건 수정예정.
  return (
    <CategoryMenuBox>
      {menus.map((menu) => (
        <CategoryMenu key={menu.key} className={menu.key === tabEnum ? "active" : ""} onClick={() => tabMove(menu.key)}>
          {menu.title}
        </CategoryMenu>
      ))}
    </CategoryMenuBox>
  );
};

export default CommonCategoryTab;
