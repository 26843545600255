import { dbUserState2 } from "enum/db/useUserEnum";
import React, { useEffect, useState } from "react";
import { HoverCon, IconLink, UserStateHoverBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

const UserState2Hover = ({ state2List }) => {
  const [state2, setState2] = useState([]);

  useEffect(() => {
    if (state2List) {
      try {
        const parsedState2 = JSON.parse(state2List);
        setState2(parsedState2);
      } catch (error) {
        setState2([]);
      }
    }
  }, [state2List]);

  return (
    state2 &&
    state2.length > 0 && (
      <HoverCon>
        <IconLink className="h-a-box" $url="/assets/svg/warning.svg" $width="16px" $height="16px"></IconLink>
        <UserStateHoverBox className="h-box">
          {state2?.map((result, index) => {
            return (
              <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)" key={index}>
                {dbUserState2.state2[result]?.label}
                {index !== state2.length - 1 && ", "}
              </TextBox>
            );
          })}
        </UserStateHoverBox>
      </HoverCon>
    )
  );
};

export default UserState2Hover;
