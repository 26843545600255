import React from "react";
import styled, { css } from "styled-components";
import Calendar from "./common/Calendar";
import useStyle from "style/useStyle";
import { IconLink } from "style/useLayoutStyles";
import { SubText } from "style/useTextStyles";

const CategoryMenuBox = styled.div`
  width: fit-content;
`;

const CategoryMenu = styled.button`
  width: 120px;
  padding: 12px 0;
  text-align: center;
  white-space: nowrap;
  color: var(--c-black-op);
  background-color: rgba(95, 158, 160, 0.2);

  &:hover {
    background-color: var(--c-mint-600);
    color: var(--c-white);
  }
  transition: 0.2s ease-in-out;
  &.active {
    background-color: var(--c-mint-600);
  }
  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`;

const DropDown = styled.div`
  cursor: pointer;

  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 230px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #aaa;
  height: 40px;
`;

const DropMenuBox = styled.ul`
  position: absolute;

  top: 110%;
  left: 0;

  display: flex;
  gap: 6px;
  flex-direction: column;
  justify-content: space-between;

  z-index: 1;
  width: 100%;
  text-align: left;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #aaa;
  background-color: var(--c-white);

  overflow-y: auto;
  max-height: 120px;
`;

const DropMenuList = styled.li`
  cursor: pointer;

  font-size: var(--s-sub);
  font-family: var(--f-sub);
  line-height: var(--l-sub);
`;

const InfoRadiusBox = styled.div`
  width: 230px;
  text-align: end;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #aaa;
  min-height: 36px;

  font-size: var(--s-sub);
  font-family: var(--f-sub);
  line-height: var(--l-sub);
`;

const AreaTitle = styled.p`
  font-size: 22px;
  padding-bottom: 12px;
`;

const subText = styled.p`
  padding-bottom: 12px;

  color: #1c1c1c;
`;

const SearchBtn = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  height: fit-content;
  border: 1px solid #aaa;

  font-family: var(--f-caption);
`;

const createLineSection = ({ $pad, $borT, $borL, $borR, $borB }) => css`
  padding: ${$pad};
  border-top: ${$borT};
  border-left: ${$borL};
  border-right: ${$borR};
  border-bottom: ${$borB};

  width: fit-content;
`;

const LineSection = styled.div`
  ${createLineSection}
`;

const InfoInsertBox = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

const InfoInsertList = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InfoInsertTitle = styled.p`
  width: 60px;

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  line-height: var(--l-caption);

  color: #aaa;
  white-space: nowrap;
`;

const CheckInput = styled.input.attrs({ type: "checkbox" })`
  padding: 1px 0 !important;
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;

  background: url(/assets/svg/noncheck_icon.svg);

  &:checked {
    background: url(/assets/svg/checkbox_icon.svg);
  }

  &[disabled] {
    opacity: 0.5;
  }

  &[disabled] + label {
    opacity: 0.5;
  }
`;

const Styles = () => {
  const { TextBox, FlexBox, ShadowBox } = useStyle();
  return (
    <FlexBox $direct='column' $gap='20px'>
      <AreaTitle>[회원관리] 일반회원</AreaTitle>
      {/* 회원 보유캐시 / 검색 드롭다운 */}
      <ShadowBox $pad='12px' $height='fit-content'>
        <FlexBox $gap='20px' $width='100%' $jus='space-between'>
          <FlexBox $direct='column'>
            <AreaTitle>일반 회원 </AreaTitle>
            <FlexBox $gap='12px' $direct='column' $width='fit-content'>
              <FlexBox $direct='row-reverse'>
                <div>
                  <TextBox $size='var(--s-sub)' $font='var(--f-sub)' $line='var(--l-sub)'>
                    회원 보유 캐시
                  </TextBox>
                  <TextBox $size='var(--s-caption)' $font='var(--f-caption)' $line='var(--l-caption)'>
                    (C)
                  </TextBox>
                  <InfoRadiusBox>100 명</InfoRadiusBox>
                </div>
              </FlexBox>
              <FlexBox $gap='22px'>
                <div>
                  <TextBox $size='var(--s-sub)' $font='var(--f-sub)' $line='var(--l-sub)'>
                    회원 보유 캐시
                  </TextBox>
                  <TextBox $size='var(--s-caption)' $font='var(--f-caption)' $line='var(--l-caption)'>
                    (C)
                  </TextBox>
                  <InfoRadiusBox>100 명</InfoRadiusBox>
                </div>
                <FlexBox $direct='column'>
                  <div>
                    <TextBox $size='var(--s-sub)' $font='var(--f-sub)' $line='var(--l-sub)'>
                      회원 보유 캐시
                    </TextBox>
                    <TextBox $size='var(--s-caption)' $font='var(--f-caption)' $line='var(--l-caption)'>
                      (C)
                    </TextBox>
                    <InfoRadiusBox>100 명</InfoRadiusBox>
                  </div>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox $width='100%' $direct='column'>
            <AreaTitle>일반 회원 검색</AreaTitle>
            <FlexBox $pad='22px 0 0' $gap='34px' $width='100%' $flexWrap='wrap'>
              <DropDown>
                <TextBox $color='#222' $size='16px'>
                  ss
                </TextBox>
                <IconLink
                  as='button'
                  $url='/assets/svg/bottom_arrow.svg'
                  $width='14px'
                  $height='14px'></IconLink>
                <DropMenuBox>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                  <DropMenuList>ss</DropMenuList>
                </DropMenuBox>
              </DropDown>

              <DropDown>
                <TextBox $color='#222' $size='16px'>
                  ss
                </TextBox>
                <IconLink
                  as='button'
                  $url='/assets/svg/bottom_arrow.svg'
                  $width='14px'
                  $height='14px'></IconLink>
              </DropDown>
              <DropDown>
                <TextBox $color='#222' $size='16px'>
                  ss
                </TextBox>
                <IconLink
                  as='button'
                  $url='/assets/svg/bottom_arrow.svg'
                  $width='14px'
                  $height='14px'></IconLink>
              </DropDown>
              <DropDown>
                <TextBox $color='#222' $size='16px'>
                  ss
                </TextBox>
                <IconLink
                  as='button'
                  $url='/assets/svg/bottom_arrow.svg'
                  $width='14px'
                  $height='14px'></IconLink>
              </DropDown>
              <DropDown>
                <TextBox $color='#222' $size='16px'>
                  ss
                </TextBox>
                <IconLink
                  as='button'
                  $url='/assets/svg/bottom_arrow.svg'
                  $width='14px'
                  $height='14px'></IconLink>
              </DropDown>
            </FlexBox>
          </FlexBox>

          <FlexBox $jus='flex-end' $align='flex-end'>
            <SearchBtn>검색</SearchBtn>
          </FlexBox>
        </FlexBox>
      </ShadowBox>

      <FlexBox $pad='40px 0 0' $gap='40px'>
        <LineSection $borR='1px solid var(--c-gray-300)' $pad='0 50px 0 0'>
          <SubText>1. 일반회원</SubText>
          <InfoInsertBox>
            <InfoInsertList>
              <InfoInsertTitle>아이디</InfoInsertTitle>
              <InfoRadiusBox></InfoRadiusBox>
              <SearchBtn>변경</SearchBtn>
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>환전비밀번호</InfoInsertTitle>
              <InfoRadiusBox></InfoRadiusBox>
              <SearchBtn>변경</SearchBtn>
            </InfoInsertList>
          </InfoInsertBox>
        </LineSection>

        <Calendar />

        <FlexBox $align='center' $gap='4px'>
          <CheckInput type='checkbox' id='id' />
          <TextBox as='label' htmlFor='id' $size='var(--s-sub)' $line='var(--l-sub)' $fontType='var(--f-sub)'>
            체크체크
          </TextBox>
        </FlexBox>
      </FlexBox>

      <CategoryMenuBox>
        <CategoryMenu>메뉴버튼</CategoryMenu>
        <CategoryMenu>메뉴</CategoryMenu>
        <CategoryMenu>메뉴</CategoryMenu>
        <CategoryMenu>메뉴</CategoryMenu>
        <CategoryMenu>메뉴</CategoryMenu>
        <CategoryMenu>메뉴</CategoryMenu>
      </CategoryMenuBox>
    </FlexBox>
  );
};

export default Styles;
