import useFormData from "common/useFormData";
import { InputForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { useAlert, usePopup } from "contexts/useWindow";
import useStateData from "common/useStateData";
import { validationTypeEnum } from "enum/useCommonEnum";

const AgentAdminForm = ({ agentNo, getTableItem }) => {
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    useStated.setErrorData([], true);
  }, [agentNo]);

  const showFormSuccessAlert = () => {
    openAlert({
      title: "추가되었습니다.",
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: "확인",
    });
  };

  const showFormFailAlert = () => {
    openAlert({
      title: "추가에 실패했습니다.",
      message: "잠시 후 다시 시도해 주세요.",
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: "확인",
    });
  };

  const useForm = useFormData({
    id: "",
    nickname: "",
    phone: "",
    password: "",
    re_password: "",
  });

  /**
   * 중복체크
   * @param {String} key
   */
  const checkDuplicated = (key) => {
    const apiUrl = `/v1/agent/info/duplicate/${key}`;

    api
      .get(apiUrl, {
        params: {
          ...useForm.formValue,
        },
      })
      .then((res) => {
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        // showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };

  const insertAgent = () => {
    const apiUrl = "/v1/agent/info/agent-admin";
    api
      .post(apiUrl, {
        ...useForm.formValue,
        agent_no: agentNo,
      })
      .then((res) => {
        showFormSuccessAlert();
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };
  return (
    <>
      <Column className="area-box" $gap="20px" $width="500px">
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            관리자 ID
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm
                maxWidth="100%"
                type="text"
                name="id"
                maxLength={20}
                validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                {...useForm}
                {...useStated}
              />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("id")}
              >
                중복체크
              </WhiteBtn>
            </Row>

            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 6~20자리 영문 또는 숫자로만 입력 가능합니다.
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            닉네임
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm maxWidth="100%" type="text" name="nickname" maxLength={50} {...useForm} {...useStated} />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("nickname")}
              >
                중복체크
              </WhiteBtn>
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 4~50자리만 입력 가능합니다.
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            핸드폰 번호
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm
                maxWidth="100%"
                type="text"
                name="phone"
                placeholder="000-0000-0000"
                maskType="phone"
                {...useForm}
                {...useStated}
              />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("phone")}
              >
                중복체크
              </WhiteBtn>
            </Row>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            비밀번호
          </TextBox>
          <Column $gap="4px" $width="100%">
            <Row>
              <InputForm
                maxWidth="100%"
                type="password"
                name="password"
                maxLength={30}
                validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                {...useForm}
                {...useStated}
              />
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 16~30자리 아상의 영문 또는 숫자로만 입력 가능합니다.
            </TextBox>
          </Column>
        </FormBox>
        <FormBox $align="center">
          <TextBox $width="120px" $minWidth="120px">
            비밀번호 확인
          </TextBox>
          <InputForm maxWidth="100%" type="password" name="re_password" {...useForm} {...useStated} />
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              취소
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={insertAgent}>
              저장
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentAdminForm;
