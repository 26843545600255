import React, { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "utils/Pagination";
import api from "interceptor/api";
import styled from "styled-components";
import useStyle from "style/useStyle";

const ModifyBtn = styled.button`
  color: #2e81ff;
  box-shadow: var(--shadow);
  border-radius: 4px;

  padding: 4px 12px;
`;

const DeleteBtn = styled.button`
  color: #e71616;
  box-shadow: var(--shadow);
  border-radius: 4px;

  padding: 4px 12px;
`;

const NoticeTitle = styled.span`
  font-size: var(--s-title);
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const NoticeList = () => {
  const { FlexBox, TextBox, NoticeLayOut, ScrollContent } = useStyle();
  const [noticeList, setNoticeList] = useState([]);
  const [pagerHelper, setPagerHelper] = useState();

  const submitDeleteNotice = (noticeNo) => {
    api
      .delete(`/v1/api/notice?noticeNo=${noticeNo}`)

      .then((res) => {
        setNoticeList((noticeList) => noticeList.filter((notice) => notice.noticeNo !== noticeNo));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useLayoutEffect(() => {
    api
      .get("/v1/api/notice/list", {
        params: { page: 1, countPerPage: 10 },
      })
      .then((res) => {
        setNoticeList(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        alert("error : ", error);
        console.error(error);
      });
  }, []);

  const handleNoticeList = (page) => {
    api
      .get("/v1/api/notice/list", {
        params: {
          page: page,
          countPerPage: pagerHelper.countPerPage,
        },
      })

      .then((res) => {
        setNoticeList(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })

      .catch((error) => {
        alert("error : ", error);
        console.error(error);
      });
  };

  return (
    <FlexBox $width='100%' $direct='column'>
      <FlexBox $jus='space-between' $align='center' $pad='0 0 12px'>
        <TextBox $pad='0 0 12px'>공지사항</TextBox>
        <Link to='/notice/write'>
          <TextBox
            as='button'
            $size='14px'
            $shadow='var(--shadow)'
            $pad='4px 12px'
            $radius='4px'
            $color='var(--c-mint-600)'>
            작성하기
          </TextBox>
        </Link>
      </FlexBox>

      <NoticeLayOut>
        {noticeList.map((notice) => (
          <div key={notice.noticeNo}>
            <FlexBox $gap='6px' $pad='12px' $height='100%' $direct='column' $jus='space-between'>
              <FlexBox $direct='column' $gap='12px'>
                <FlexBox $direct='column'>
                  <FlexBox $align='center' $jus='space-between'>
                    <TextBox $size='var(--s-caption)' $font='var(--f-caption)'>
                      공지사항 번호 : {notice.noticeNo}
                    </TextBox>{" "}
                    <FlexBox $align='center' $jus='flex-end' $gap='6px'>
                      <Link to={`/notice/modify/${notice.noticeNo}`}>
                        <ModifyBtn>수정하기</ModifyBtn>
                      </Link>
                      <DeleteBtn onClick={() => submitDeleteNotice(notice.noticeNo)}>삭제하기</DeleteBtn>
                    </FlexBox>
                  </FlexBox>
                  <NoticeTitle>제목 : {notice.title}</NoticeTitle>
                </FlexBox>

                <ScrollContent $size='18px'>{notice.contents}</ScrollContent>
              </FlexBox>
            </FlexBox>
          </div>
        ))}
      </NoticeLayOut>

      {pagerHelper ? (
        <FlexBox>
          <Pagination pagerHelper={pagerHelper} onPageChange={handleNoticeList} />
        </FlexBox>
      ) : (
        <div></div>
      )}
    </FlexBox>
  );
};

export default NoticeList;
