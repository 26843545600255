/**
 * "1234" -> 1,234
 * @param {String} num 
 * @returns 
 */
export const formatNumber = (num) => {
  const parsedNum = Number(num);
  return isNaN(parsedNum) || parsedNum === 0 ? '-' : parsedNum.toLocaleString();
};

/**
 * 날짜를 기준으로 최신순으로 정렬합니다.
 * @param {Object} data 
 * @returns 
 */
export const sortDateObjectKeys = (data) => {
  return Object.keys(data).sort((a, b) => {
    const numA = parseInt(a);
    const numB = parseInt(b);
    return numB - numA;
  });
};
