import React, { useState } from "react";
import api from "interceptor/api";
import { useNavigate } from "react-router";
import styled from "styled-components";
import useStyle from "style/useStyle";

const WriterTitle = styled.input`
  background-color: #191a1e;

  border: 1px solid #e5e5e5 !important;
  border-radius: 12px !important;

  color: var(--c-white);

  width: 100%;
  max-width: 360px;
  padding: 10px;
`;

const WriterContent = styled.textarea`
  background-color: #191a1e;

  border: 1px solid #e5e5e5 !important;
  border-radius: 12px !important;

  color: var(--c-white);

  width: 100%;
  max-width: 100%;
  height: 300px;
  padding: 10px;
  resize: none;
`;

const WriteLayOut = styled.div`
  padding: 20px;
`;

const WriteBtn = styled.button`
  color: #2e81ff;
  background-color: var(--c-white);
  border-radius: 4px;

  padding: 4px 12px;
`;

const NoticeWrite = () => {
  const navigate = useNavigate();
  const { FlexBox, TextBox, ContentArea } = useStyle();

  const [writeTitle, setWriteTitle] = useState("");
  const [writeContent, setWriteContent] = useState("");

  const submitWriteNotice = () => {
    if (writeTitle.length < 5 || writeContent.length < 5) {
      alert("제목 또는 내용은 5글자 이상 입력해야합니다.");
      return;
    }
    const writeNoticeApi = api.post("/v1/api/notice", {
      adminNo: 0,
      title: writeTitle,
      contents: writeContent,
    });
    writeNoticeApi.then((response) => {
      navigate("/notice/list");
    });
    writeNoticeApi.catch((error) => {
      alert("Error writing notice error : ", error);
    });
  };

  return (
    <ContentArea>
      <>
        <WriteLayOut>
          <FlexBox
            $jus="space-between"
            $align="center"
          >
            <TextBox
              as="p"
              $size="24px"
            >
              글 작성
            </TextBox>
            <TextBox
              as="button"
              onClick={() => navigate("/notice/list")}
              $size="16px"
            >
              이전 페이지
            </TextBox>
          </FlexBox>
          <FlexBox
            $pad="20px 0"
            $gap="12px"
            $direct="column"
          >
            <TextBox>제목</TextBox>
            <WriterTitle
              type="text"
              placeholder="제목을 입력해주세요."
              value={writeTitle}
              onChange={(e) => setWriteTitle(e.target.value)}
            />
          </FlexBox>
          <FlexBox
            $pad="20px 0"
            $jus="flex-start"
            $gap="12px"
            $direct="column"
          >
            <TextBox $white="nowrap">내용</TextBox>
            <WriterContent
              value={writeContent}
              placeholder="내용을 입력해주세요."
              onChange={(e) => setWriteContent(e.target.value)}
            />
          </FlexBox>
          <FlexBox $jus="flex-end">
            <WriteBtn onClick={submitWriteNotice}>작성하기</WriteBtn>
          </FlexBox>
        </WriteLayOut>
      </>
    </ContentArea>
  );
};

export default NoticeWrite;
