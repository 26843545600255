import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { InputForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import React from "react";
import { Column, FormBox } from "style/custom/useCustomLayout";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { AreaTitle } from "style/useTextStyles";
import api from "interceptor/api";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import SelectIconPopup from "./SelectIconPopup";
import { useAlert, usePopup } from "contexts/useWindow";
import { t } from "i18next";

const CategoryModifyPopup = ({ item, getCategoryList }) => {
  const { openPopup, closePopup } = usePopup();
  const useStated = useStateData();

  const { setLoading } = useCommonState();
  const { openAlert } = useAlert();

  const useForm = useFormData({
    ...item,
  });

  const updateCategory = () => {
    setLoading(true);
    api
      .put(`/v1/site/menu-category/${item.no}`, useForm.formValue)
      .then((res) => {
        getCategoryList();
        closePopup();
      })
      .catch((error) => {
        openAlert({
          title: t(`error.${error.response.data.code}`),
          iconURL: true,
          mainBtn: t("common.check"),
        });
        setLoading(false);
        console.error(error);
      });
  };

  const showPopup = () => {
    openPopup({
      title: t("menuCategory.select_icon"),
      content: SelectIconPopup,
      props: {
        setIcon: (iconName) => {
          useForm.setFormValue((prev) => ({
            ...prev,
            icon: iconName,
          }));
        },
      },
    });
  };

  const deleteIcon = () => {
    useForm.setFormValue((prev) => ({
      ...prev,
      icon: "",
    }));
  };

  return (
    <Column $gap="30px" $pad="20px">
      <FormBox $gap="12px" $align="flex-start">
        <FormBox $flex="1">
          <AreaTitle>{t("menuCategory.title")} :</AreaTitle>
        </FormBox>
        <FormBox $flex="5">
          <InputForm name="name" width="100%" minWidth="100%" maxWidth="100%" {...useForm} {...useStated} />
        </FormBox>
      </FormBox>
      <FormBox $gap="12px" $align="flex-start">
        <FormBox $flex="1">
          <AreaTitle>{t("menuCategory.icon")} :</AreaTitle>
        </FormBox>
        <FormBox $flex="5" $gap="20px" $align="center">
          {useForm.formValue.icon && (
            <IconLink $url={`/assets/svg/${useForm.formValue.icon}.svg`} $width="24px" $height="24px"></IconLink>
          )}
          <MainBtn onClick={showPopup}>{t("common.select")}</MainBtn>
          <RedBtn onClick={deleteIcon}>{t("common.delete")}</RedBtn>
        </FormBox>
      </FormBox>

      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <FormBox $gap="10px" $jus="right" $width="100%">
          <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={updateCategory}>
            {t("common.modify")}
          </MainBtn>
          <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </WhiteBtn>
        </FormBox>
      </PositionBox>
    </Column>
  );
};

export default CategoryModifyPopup;
