import React, { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";

// style import
import { Column, Row } from "style/custom/useCustomLayout";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import { getFormatDateMin } from "utils/date/DateUtil";
import { TextBox } from "style/useTextStyles";
import styled from "styled-components";
import { t } from "i18next";

const GameinfoTitle = styled.div`
  width: 400px;
  background-color: var(--c-gray-300);
  padding: 8px 16px;
  border: 1px solid var(--c-gray-700);
`;

const GameinfoData = styled.div`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid var(--c-gray-700);
  border-left: none;
`;

const BettingGameInfo = ({ transactionId }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const [info, setInfo] = useState();
  const [result, setResult] = useState();

  useEffect(() => {
    setLoading(true);

    const apiUrl = `/v1/user/all/bet-win/game/info/${transactionId}`;

    api
      .get(apiUrl, {
        params: {},
      })
      .then((res) => {
        setResult(JSON.parse(res.data.content[0]?.result_json_data));
        setInfo(res.data.content[0]);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response?.data?.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Column $width="1600px" $height="600px">
        <>
          {result && (
            <Column>
              <Row>
                <Column>
                  <GameinfoTitle>
                    <TextBox>{t("betting.vendor")}</TextBox>
                  </GameinfoTitle>
                  <GameinfoTitle>
                    <TextBox>{t("betting.dealer")}</TextBox>
                  </GameinfoTitle>
                  <GameinfoTitle>
                    <TextBox>{t("betting.table")}</TextBox>
                  </GameinfoTitle>
                  <GameinfoTitle>
                    <TextBox>{t("betting.start_time")}</TextBox>
                  </GameinfoTitle>
                  <GameinfoTitle>
                    <TextBox>{t("betting.close_time")}</TextBox>
                  </GameinfoTitle>
                  <GameinfoTitle>
                    <TextBox>{t("betting.game_status")}</TextBox>
                  </GameinfoTitle>
                  <GameinfoTitle>
                    <TextBox>{t("betting.win_time")}</TextBox>
                  </GameinfoTitle>
                </Column>
                <Column $width="100%">
                  <GameinfoData>
                    <TextBox $width="100%">{result?.details?.game?.vendor ?? "-"}</TextBox>
                  </GameinfoData>
                  <GameinfoData>
                    <TextBox $width="100%">{result?.external?.detail?.data?.dealer?.name ?? "-"}</TextBox>
                  </GameinfoData>
                  <GameinfoData>
                    <TextBox $width="100%">{result?.external?.detail?.data?.table?.name ?? "-"}</TextBox>
                  </GameinfoData>
                  <GameinfoData>
                    <TextBox $width="100%">{getFormatDateMin(info?.bet_time) ?? "-"}</TextBox>
                  </GameinfoData>
                  <GameinfoData>
                    <TextBox $width="100%">{getFormatDateMin(info?.win_time) ?? "-"}</TextBox>
                  </GameinfoData>
                  <GameinfoData>
                    <TextBox $width="100%">{result?.external?.detail?.data?.status ?? "-"}</TextBox>
                  </GameinfoData>
                  <GameinfoData>
                    <TextBox $width="100%">{getFormatDateMin(info?.win_time) ?? "-"}</TextBox>
                  </GameinfoData>
                </Column>
              </Row>
            </Column>
          )}

          {!info && <NoDataTable />}
        </>
      </Column>
    </>
  );
};

export default BettingGameInfo;
