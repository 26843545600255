import React, { useState } from "react";

import { AGENT_AGENT_INFO_CATEGORY } from "constants/useCategory";
import CommonCategoryTab from "components/common/CommonCategoryTab";
import AgentAdminManage from "./AgentAdminManage";
import LogLogin from "components/logTable/LogLogin";


const AgentAdminInfoTab = ({ agentNo ,no,tabNm = 1 ,getTableItem, getAgentInfo}) => {
  const [tabEnum, setTabEnum] = useState(tabNm);

  const tabMove = (enums) => {
    setTabEnum(enums);
  };

  return (
    <>
      <CommonCategoryTab menus={AGENT_AGENT_INFO_CATEGORY} tabMove={tabMove} tabEnum={tabEnum} />
      {tabEnum === 1 ? <AgentAdminManage agentNo={agentNo} no={no} parentGetTable={getTableItem} getAgentInfo = {getAgentInfo}/> : tabEnum === 2 ? <LogLogin agentNo={agentNo} no={no} /> : <p>X</p>}
    </>
  );
};

export default AgentAdminInfoTab;
