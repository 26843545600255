import React, { useEffect } from "react";

// 공용
import Pagination from "utils/Pagination";
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useAlert, usePopup } from "contexts/useWindow";
import { NoLabelCheckForm, InputForm, SelectForm, TextareaForm, EachCheckForm } from "components/common/CommonForm";
import { getFormatDatetime } from "utils/date/DateUtil";
// 셀렉트 벨류 constant
import { useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaHeader, Column, FilterBox, FormBox, Row } from "style/custom/useCustomLayout";
import { isEmpty } from "lodash";
import { FilterTitle } from "style/custom/useCustomText";
import useFormData from "common/useFormData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import useStateData from "common/useStateData";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import { dbBoardNotice } from "enum/db/useBoardEnum";
import { useAlarmCheck } from "utils/ApiUtil";
import { ErrorMsgForm } from "utils/FormStateUtil";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import UserLevelFilter from "components/common/UserLevelFilter";
import { checkboxYNEnum, validationTypeEnum } from "enum/useCommonEnum";
import CalendarFilter from "components/common/CalendarFilter";
import { dbUser, dbUserBoardQna } from "enum/db/useUserEnum";
import UserState2Hover from "components/common/UserState2Hover";
import UserNameTab from "components/common/UserNameTab";
import AgentNameTab from "components/common/AgentNameTab";
import { t } from "i18next";
import UserLevelTab from "components/common/UserLevelTab";

const UserQna = () => {
  const useTG = useTableData(true);
  const useStated = useStateData();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { setLoading } = useCommonState();

  const showPopup = (item, isReadOnly) => {
    openPopup({
      title: t("userQna.answer_registration"),
      content: UserQnAPopup,
      props: {
        item,
        isReadOnly,
        getTableItem,
      },
    });
  };

  useAlarmCheck("user-qna");

  const getTableItem = () => {
    setLoading(true);
    api
      .get("/v1/cs-management/user-qna", {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          useStated.setErrorData(error.response.data.errors);
        }
        console.error(error);
      });
  };

  const deleteQnA = (no) => {
    const checkedList = useTG.data.filter((item) => item.isChecked);
    const no_list = no ? [no] : checkedList.map((item) => item.no);
    if (!no_list.length) {
      return;
    }
    api
      .delete(`/v1/cs-management/user-qna`, { params: { no_list: no_list } })
      .then((res) => {
        getTableItem();
      })
      .catch((error) => {
        const errArr = error.response?.data.errors;

        openAlert({
          title: t("common.delete_fail"),
          message:
            errArr === null || errArr === undefined ? null : (
              <Column>
                {errArr.map((err, index) => (
                  <ErrorMsgForm errorJson={err} key={index} />
                ))}
              </Column>
            ),
        });
        console.error(error);
      });
  };

  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_state1: "",
      search_user_no: "",

      search_manage_user_yn: "Y",
      search_answer_state: "",

      search_date_state: "",
      date_period: "",
      search_date_type: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  // 검색 조건이 변경되면 검색어 초기화 (InputForm 에서 SelectForm 으로 변경될 때 오류 방지)
  useEffect(() => {
    useSearch.setFormValue({
      ...useSearch.formValue,
      keyword: "",
    });
  }, [useSearch.formValue?.search_type]);

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.operate_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("userQna.inquiry_board")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 12px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("userQna.member")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("userQna.member_number")}</FilterTitle>
              <InputForm
                validationType={validationTypeEnum.NUMBER}
                name="search_user_no"
                placeholder={t("common.input")}
                {...useSearch}
              />
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("userQna.member_status")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />
            <Row $pad="28px 0 0 12px">
              <EachCheckForm
                $size="var(--s-caption)"
                name="search_manage_user_yn"
                labelFor="search_manage_user_yn"
                options={checkboxYNEnum}
                labelName={t("filter.include_manage_user")}
                {...useSearch}
              />
            </Row>
            <Column $gap="4px">
              <FilterTitle>{t("userQna.answer_status")}</FilterTitle>
              <SelectForm
                name="search_answer_state"
                placeholder={t("common.all")}
                options={dbUserBoardQna.state}
                {...useSearch}
                {...useStated}
              />
            </Column>
            <Row $gap="4px">
              <Column $gap="4px">
                <FilterTitle>{t("userQna.inquiry_date")}</FilterTitle>
                <Row $gap="4px">
                  <SelectForm
                    name="search_date_state"
                    placeholder={t("common.select")}
                    options={dbUserBoardQna.search_date_state}
                    {...useSearch}
                    {...useStated}
                  />
                  <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useSearch} end />
                </Row>
              </Column>
            </Row>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $gap="12px">
          <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
            <TextBox $font="var(--f-subB)">{t("userQna.total_inquiries")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
        </Row>

        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteQnA()}>
            {t("common.delete")}
          </RedBtn>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            {
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                    <TableHeader $width="60px">
                      <TextBox>No.</TextBox>
                    </TableHeader>
                    <TableHeader $width="130px">{t("userQna.agent")}</TableHeader>
                    <TableHeader $width="130px">{t("userQna.member")}</TableHeader>
                    <TableHeader $width="60px">{t("userQna.member_grade")}</TableHeader>
                    <TableHeader $width="60px">{t("userQna.member_status")}</TableHeader>
                    <TableHeader $width="130px">{t("userQna.inquiry_reception_date")}</TableHeader>
                    <TableHeader $width="130px">{t("userQna.title")}</TableHeader>
                    <TableHeader $width="100px">{t("userQna.answer_status")}</TableHeader>
                    <TableHeader $width="130px">{t("userQna.answer_date")}</TableHeader>
                    <TableHeader $width="130px">{t("userQna.answer_handler")}</TableHeader>
                    <TableHeader $width="60px">{t("userQna.management")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo.offset - index;
                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps>
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                            <TableDeps $width="60px">{number || "-"}</TableDeps>
                            <TableDeps $tAlign="left">
                              <AgentNameTab
                                no={item.agent_no}
                                code={item.agent_code}
                                name={item.agent_name}
                                type_name={item.type_name}
                              />
                            </TableDeps>
                            <TableDeps $width="130px" $tAlign="left">
                              <UserState2Hover state2List={item.state2_arr} />
                              <UserNameTab no={item.create_no} nickname={item.create_nickname} refresh={getTableItem} />
                            </TableDeps>
                            <TableDeps>
                              <UserLevelTab item={item} />
                            </TableDeps>
                            <TableDeps>
                              <TextBox
                                className={
                                  item.state1 === dbUser.state1.S.value
                                    ? "complete"
                                    : item.state1 === dbUser.state1.B.value
                                      ? "warn"
                                      : item.state1 === dbUser.state1.O.value
                                        ? "withdraw"
                                        : item.state1 === dbUser.state1.W.value
                                          ? "normal"
                                          : item.state1 === dbUser.state1.T.value
                                            ? "test"
                                            : ""
                                }
                              >
                                {dbUser.state1[item.state1]?.label || "-"}
                              </TextBox>
                            </TableDeps>
                            <TableDeps $width="130px">{getFormatDatetime(item.create_at)}</TableDeps>
                            <TableDeps $width="20%" $tAlign="left">
                              {item.question_title}
                            </TableDeps>
                            <TableDeps $width="100px">
                              {item.state === dbUserBoardQna.state.P.value
                                ? dbUserBoardQna.state.P.label
                                : dbUserBoardQna.state.C.label}
                            </TableDeps>
                            <TableDeps $tAlign="center">
                              {item.state === dbUserBoardQna.state.C.value
                                ? `${item.answer_nickname} (${item.answer_no})` ?? "-"
                                : "-"}
                            </TableDeps>
                            <TableDeps $width="130px">{getFormatDatetime(item.answer_at) ?? "-"}</TableDeps>
                            <TableDeps $width="60px">
                              <Row $width="100%" $jus="center" $gap="6px">
                                {item?.no ? (
                                  <>
                                    {item?.state === dbBoardNotice.provider_type.P.value ? (
                                      <ModifyBtn name={t("userQna.answer")} eventProps={() => showPopup(item, false)} />
                                    ) : (
                                      <ModifyBtn name={t("common.view")} eventProps={() => showPopup(item, true)} />
                                    )}
                                    <DeleteBtn eventProps={() => deleteQnA(item?.no)} />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Row>
                            </TableDeps>
                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            }
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteQnA()}>
            {t("common.delete")}
          </RedBtn>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              top="auto"
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

const UserQnAPopup = ({ item, isReadOnly, getTableItem }) => {
  const { closePopup } = usePopup();

  const useSearch = useFormData({
    no: item?.no,
    question_title: item?.question_title,
    question_contents: item?.question_contents,
    answer_contents: item?.answer_contents ?? "",
  });

  const confirmBtn = () => {
    if (isReadOnly) {
      closePopup();
      return;
    }
    api
      .put(`/v1/cs-management/user-qna/answer/${item?.no}`, { ...useSearch.formValue })
      .then((res) => {
        closePopup();
        getTableItem();
      })
      .catch((error) => {
        alert("error : ", error);
      });
  };

  return (
    <Column $width="600px" $gap="30px" $pad="20px">
      <FormBox $gap="12px" $align="flex-start">
        <FormBox $flex="1">
          <AreaTitle>{t("userQna.question_title")} : </AreaTitle>
        </FormBox>
        <FormBox $flex="5">
          <InputForm name="question_title" width="100%" minWidth="100%" maxWidth="100%" readOnly={true} {...useSearch} />
        </FormBox>
      </FormBox>
      <FormBox $gap="12px" $align="flex-start">
        <FormBox $flex="1">
          <AreaTitle>{t("userQna.question_content")} : </AreaTitle>
        </FormBox>
        <FormBox $flex="5">
          <TextareaForm
            name="question_contents"
            width="100%"
            height="300px"
            minWidth="100%"
            maxWidth="100%"
            readOnly={true}
            {...useSearch}
          ></TextareaForm>
        </FormBox>
      </FormBox>
      <FormBox $gap="12px" $align="flex-start">
        <FormBox $flex="1">
          <AreaTitle>{t("userQna.answer")} : </AreaTitle>
        </FormBox>
        <FormBox $flex="5">
          <TextareaForm
            name="answer_contents"
            width="100%"
            height="300px"
            minWidth="100%"
            maxWidth="100%"
            readOnly={isReadOnly}
            {...useSearch}
          ></TextareaForm>
        </FormBox>
      </FormBox>
      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <FormBox $gap="10px" $jus="right" $width="100%">
          <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={confirmBtn}>
            {isReadOnly ? t("userQna.confirm") : t("userQna.register")}
          </MainBtn>
        </FormBox>
      </PositionBox>
    </Column>
  );
};

export default UserQna;
