import React from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

// style import
import { TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { Between, Column, Row } from "style/custom/useCustomLayout";
import { getFormatDatetime } from "utils/date/DateUtil";
import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import { betEnum, gameEnum } from "enum/useCommonEnum";
import { NormalStatus, SuccessStatus, WarnStatus } from "components/common/TableStatus";
import styled from "styled-components";
import { t } from "i18next";

const Poker = styled.div`
  width: 24px;
  height: fit-content;

  text-align: center;

  border: 1px solid #d9e2e9;
  background: var(--c-white);
  color: black;
  padding: 2px 8px;

  border-radius: 4px;

  &.H {
    & > p {
      color: var(--c-red);
    }
  }

  &.D {
    & > p {
      color: var(--c-red);
    }
  }

  &.ho {
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 4px 8px;

    & > img {
      position: relative;
      margin-left: 3px;
    }
  }
`;

const PokerImg = styled.img`
  width: 10px;
`;

const PokerCon = styled.div`
  display: flex;
  align-items: center;

  gap: 4px;
  justify-content: center;

`;

const BettingCon = styled.div`
  width: 100%;
  padding: 8px;
  &.player-win {
    background-color: #eff0ff;
  }
  &.banker-win {
    background-color: #fff0ed;
  }
  &.tie {
    background-color: #f4fbdb;
  }
`;

const BettingTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const BettingTableHeader = styled.th`
  border: 1px solid var(--c-gray-300);
  text-align: center;
  padding: 4px;
  font-size: var(--s-caption);
  font-family: var(--f-tableB);
`;

const BettingTableCell = styled.td`
  border: 1px solid var(--c-gray-300);
  text-align: center;
  padding: 4px;
  font-size: var(--s-caption);
`;
const BettingDetail = ({ transactionId }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/user/all/bet-win/game/info/${transactionId}`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData({}, getTableItem, useTG);

  return (
    <>
      <Column $width="1600px" $height="600px">
        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="150px">{t("betting.no")}</TableHeader>
                  <TableHeader>{t("betting.game")}</TableHeader>
                  <TableHeader $width="160px">{t("betting.bet_time")}</TableHeader>
                  <TableHeader $width="160px">{t("betting.win_time")}</TableHeader>
                  <TableHeader $width="250px">{t("betting.user_betting")}</TableHeader>
                  <TableHeader $width="300px">{t("betting.game_result")}</TableHeader>
                  <TableHeader $width="170px">{t("betting.price")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const data = JSON.parse(item?.result_json_data);

                    const extractedCodes = data?.external?.detail?.data?.participants?.[0]?.bets?.map((bet) => bet.code) ?? null;

                    const player = data?.external?.detail?.data?.result?.player;
                    const playerScore = player?.score;
                    const playerCard = player?.cards;
                    if (playerCard && playerCard.length > 2) {
                      const lastIndex = playerCard.length - 1;
                      const lastItem = playerCard[lastIndex];

                      // 맨 앞으로 이동시키기
                      playerCard.splice(lastIndex, 1); // 마지막 요소 제거
                      playerCard.unshift(lastItem);
                    }

                    const banker = data?.external?.detail?.data?.result?.banker;
                    const bankerScore = banker?.score;
                    const bankerCard = banker?.cards;

                    return data ? (
                      <TableRow key={index} className="table-content">
                        <TableDeps $tAlign="right">{item.refer_id}</TableDeps>

                        <TableDeps $tAlign="left">
                          <Column>
                            <Row $gap="4px">
                              <NormalStatus name={item.vendor} />
                              <WarnStatus name={gameEnum.type[item.game_type]?.label} />
                              <SuccessStatus name={betEnum.bet_status[item.bet_status]?.label || ""} />
                            </Row>
                            <TextBox $line="var(--l-caption)" $font="var(--f-captionB)">
                              {item.title}
                            </TextBox>
                            <TextBox>{item.round}</TextBox>
                          </Column>
                        </TableDeps>
                        <TableDeps>{getFormatDatetime(item?.bet_time) || "-"}</TableDeps>
                        <TableDeps>{getFormatDatetime(item?.win_time) || "-"}</TableDeps>
                        <TableDeps>
                          <Column>
                            {extractedCodes
                              ? extractedCodes.map((code, index) => (
                                  <TextBox key={index} $size="var(--s-caption)">
                                    {code}
                                  </TextBox>
                                ))
                              : "-"}
                          </Column>
                        </TableDeps>
                        <TableDeps>
                          {(item.game_type === "baccarat" && playerCard && bankerCard) ?(
                            <Row>
                              <BettingCon
                                className={bankerScore === playerScore ? "tie" : bankerScore < playerScore ? "player-win" : ""}
                              >
                                <Between $pad="0 0 8px">
                                  <TextBox>Player</TextBox>
                                  <Row $gap="4px">
                                    <TextBox>Score</TextBox>
                                    <TextBox $font="var(--f-subB)">{player?.score || 0}</TextBox>
                                  </Row>
                                </Between>
                                <PokerCon>
                                  {playerCard.map((item, index) => {
                                    const shape = item.charAt(item.length - 1);
                                    return (
                                      <Poker
                                        key={index}
                                        className={index === 0 && playerCard.length === 3 ? `ho ${shape}` : `ve ${shape}`}
                                      >
                                        <TextBox as="p" $size="var(--s-caption)" $font="var(--f-subB)" $line="14px">
                                          {item.slice(0, -1)}
                                        </TextBox>
                                        <PokerImg src={`/assets/svg/trump/${shape}.svg`} />
                                      </Poker>
                                    );
                                  })}
                                </PokerCon>
                              </BettingCon>

                              <BettingCon
                                className={bankerScore === playerScore ? "tie" : bankerScore > playerScore ? "banker-win" : ""}
                              >
                                <Between $pad="0 0 8px">
                                  <TextBox>Banker</TextBox>
                                  <Row $gap="4px">
                                    <TextBox>Score</TextBox>
                                    <TextBox $font="var(--f-subB)">{banker?.score || 0}</TextBox>
                                  </Row>
                                </Between>
                                <PokerCon>
                                  {bankerCard.map((item, index) => {
                                    const shape = item.charAt(item.length - 1);
                                    return (
                                      <Poker key={index} className={index !== 2 ? `ve ${shape}` : `ho ${shape}`}>
                                        <TextBox as="p" $size="var(--s-caption)" $font="var(--f-subB)" $line="14px">
                                          {item.slice(0, -1)}
                                        </TextBox>
                                        <PokerImg src={`/assets/svg/trump/${shape}.svg`} />
                                      </Poker>
                                    );
                                  })}
                                </PokerCon>
                              </BettingCon>
                            </Row>
                          ) : "-"}
                        </TableDeps>
                        <TableDeps $pad="0">
                          <BettingTable>
                            <tbody>
                              <tr>
                                <BettingTableHeader>{t("betting.bet_amount")}</BettingTableHeader>
                                <BettingTableCell>{`₩ ${(item?.bet_amount || 0).toLocaleString()}`}</BettingTableCell>
                              </tr>
                              <tr>
                                <BettingTableHeader>{t("betting.win_amount")}</BettingTableHeader>
                                <BettingTableCell>{`₩ ${(item?.win_amount || 0).toLocaleString()}`}</BettingTableCell>
                              </tr>
                              <tr>
                                <BettingTableHeader>{t("betting.user_bet_win_amount")}</BettingTableHeader>
                                <BettingTableCell>{`₩ ${(
                                  (item?.bet_amount || 0) + (item?.win_amount || 0)
                                ).toLocaleString()}`}</BettingTableCell>
                              </tr>
                            </tbody>
                          </BettingTable>
                        </TableDeps>
                      </TableRow>
                    ) : null;
                  })}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
      </Column>
    </>
  );
};

export default BettingDetail;
