import useFormData from "common/useFormData";
import { InputForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { useAlert, usePopup } from "contexts/useWindow";
import useStateData from "common/useStateData";
import { useTranslation } from "react-i18next";
import AgentInfoList from "components/agent/list/AgentInfoList";
import { useCommonState } from "contexts/useCommonState";

const UserAgentChangeForm = ({ item, getTableItem }) => {
  const useStated = useStateData();
  const { openAlert } = useAlert();
  const { closePopup, openPopup } = usePopup();
  const { setLoading } = useCommonState();

  const { t } = useTranslation();

  useEffect(() => {
    getAgentInfo();
  }, [item]);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showPopup = () => {
    openPopup({
      title: t("user.modify_parent_agent"),
      content: AgentInfoList,
      props: {
        setAgent: setAgent,
        item,
        getTableItem,
      },
    });
  };

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const updateAgentState = () => {
    setLoading(true);
    const apiUrl = `v1/user-agent-change/change/${item.no}`;
    api
      .put(apiUrl, {
        ...useForm.formValue,
        parent_code: useForm.formValue.code,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showStateSuccessAlert();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showStateFailAlert();
      })
      .finally(() => {
        setLoading(false);
        getTableItem();
      });
  };

  const showStateSuccessAlert = () => {
    openAlert({
      title: t("user.change_user_agent_complete_title", { nickname: item.nickname }),
      message: t("user.change_user_agent_complete_msg"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showStateFailAlert = () => {
    openAlert({
      title: t("user.change_user_agent_fail"),
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const getAgentInfo = () => {
    const apiUrl = `v1/user-agent-change/agent-info/${item.user_no}`;

    api
      .get(apiUrl, {})
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,
        });
      })
      .catch((err) => {});
  };
  const useForm = useFormData({});
  const setAgent = (agent) => {
    const { code, name, type_name } = agent;
    useForm.formValue.type_name = type_name;
    useForm.formValue.name = name;
    useForm.formValue.code = code;
  };
  return (
    <>
      <Column className="area-box" $gap="20px">
        <FormBox $align="center">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.code")}
          </TextBox>

          <TextBox $width="120px" $minWidth="120px" $font="var(--f-subB)">
            {useForm.formValue.code}
          </TextBox>
          <WhiteBtn $minWidth="80px" $height="30px" $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
            {t("user.modify_parent")}
          </WhiteBtn>
        </FormBox>

        <FormBox $align="center">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.agent")}
          </TextBox>

          <TextBox $width="120px" $minWidth="120px" $font="var(--f-subB)">
            {`${useForm.formValue.name}(${useForm.formValue.type_name})`}
          </TextBox>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="110px" $size="var(--s-table)" $line="var(--l-table)" onClick={updateAgentState}>
              {t("user.agent_change")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default UserAgentChangeForm;
