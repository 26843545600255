import React from "react";
import { usePopup } from "contexts/useWindow";
import useFormData from "common/useFormData";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { TextBox } from "style/useTextStyles";
import { TextareaForm } from "components/common/CommonForm";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { t } from "i18next";

const UserLvlUpPopup = ({ item, level, formData }) => {
  const { closePopup } = usePopup();

  const clickBtn = () => {
    formData.setFormValue((prev) => ({
      ...prev,
      site_level_mng: [
        ...prev.site_level_mng.map((data) => {
          if (data.level === level) {
            return {
              ...data,
              levelup_msg: useForm.formValue.levelup_msg,
            };
          }
          return data;
        }),
      ],
    }));
    closePopup();
  };

  const useForm = useFormData({
    levelup_msg: item?.levelup_msg,
  });

  return (
    <>
      <Column className="area-box" $gap="20px" $width="600px">
        <FormBox $align="flex-start">
          <TextBox $white="nowrap" $minWidth="140px" $size="var(--s-sub)" $line="var(--l-sub)">
            {t("siteConfig.member_level")}
          </TextBox>
          <TextBox $width="100%" $size="var(--s-title)">
            {`LV${level}`}
          </TextBox>
          <TextBox $white="nowrap">{t("siteConfig.upgrade_to")}</TextBox>
        </FormBox>
        <FormBox>
          <TextBox $width="140px" $minWidth="140px" $size="var(--s-sub)" $line="var(--l-sub)">
            {t("siteConfig.upgrade_message")}
          </TextBox>
          <Column $width="100%" $gap="10px">
            <TextareaForm
              maxWidth="100%"
              type="text"
              placeholder={t("siteConfig.message_placeholder")}
              name="levelup_msg"
              {...useForm}
            />
            <TextBox>{t("siteConfig.send_as_message")}</TextBox>
          </Column>
        </FormBox>
        <Row $gap="10px" $jus="right">
          <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </WhiteBtn>
          <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={clickBtn}>
            {t("common.apply")}
          </MainBtn>
        </Row>
      </Column>
    </>
  );
};

export default UserLvlUpPopup;
