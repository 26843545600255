import { dbAuth } from "enum/db/useAuthEnum";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useState } from "react";

const CommonState = createContext();

export const CommonStateProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [siteName, setSiteName] = useState("game");
  const [alarmConfig, setAlarmConfig] = useState({
    alarm_in_type: 1,
    alarm_out_type: 1,
    alarm_join_type: 1,
    alarm_qna_type: 1,
    agent_qna_type: 1,
    alarm_in_delay_time: 5,
    alarm_out_delay_time: 5,
    alarm_join_delay_time: 5,
    alarm_qna_delay_time: 5,
    agent_qna_delay_time: 5,
  });

  const accessToken = localStorage.getItem("access-token");
  const decoded = accessToken ? jwtDecode(accessToken) : null;
  const roleType = decoded?.type;

  const isAdmin = roleType === dbAuth.type.A.value;
  const isAgent = roleType === dbAuth.type.G.value || roleType === dbAuth.type.D.value;
  const [favorites, setFavorites] = useState([]);

  const contextValue = {
    roleType,

    isAdmin,
    isAgent,

    siteName,
    setSiteName,

    loading,
    setLoading,

    alarmConfig,
    setAlarmConfig,

    favorites,
    setFavorites,
  };

  return <CommonState.Provider value={contextValue}>{children}</CommonState.Provider>;
};

export const useCommonState = () => {
  const context = useContext(CommonState);
  if (!context) {
    throw new Error("useCommonState must be used within a CommonStateProvider");
  }
  return context;
};
