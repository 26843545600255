import React, { useEffect, useState } from "react";
import Pagination from "utils/Pagination";
import api from "interceptor/api";
import useStyle from "style/useStyle";

const UserBalanceManagement = () => {
  const {
    Table,
    FlexBox,
    TextBox,
    MainBtn,
    TableDeps,
    TableHeader,
    InputTextBox,
  } = useStyle();
  const [userList, setUserList] = useState([]);
  const [pagerHelper, setPageHelper] = useState();
  const [showInputBox, setShowInputBox] = useState(false);
  const [amount, setAmount] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [agentInfo, setAgentInfo] = useState();

  useEffect(() => {
    api
      .get("v1/api/balance/log/total")
      .then((res) => {
        setAgentInfo(res.data);
      })
      .catch((error) => {
        console.error(error);
      });

    api
      .get("/v1/api/balance/user-balance-list")
      .then((res) => {
        setUserList(res.data.content);
        setPageHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getUserInfoList = (page) => {
    api
      .get("/v1/api/balance/user-balance-list", {
        params: {
          page: page,
          countPerPage: pagerHelper.countPerPage,
        },
      })

      .then((res) => {
        setUserList(res.data.content);
        setPageHelper(res.data.pageHelperDto);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const handleMainBtnClick = (userNo) => {
    if (userNo !== userInfo?.userNo) {
      api
        .get("/v1/api/balance/log/user", {
          params: {
            userNo: userNo,
          },
        })
        .then((res) => {
          setUserInfo(res.data);
          setShowInputBox(true);
          setAmount("");
        })

        .catch((error) => {
          alert("error : ", error);
          console.error(error);
        });
    } else {
      setShowInputBox(!showInputBox);
    }
  };

  const submitUserBalance = () => {
    if (!/^-?\d+$/.test(amount)) {
      alert("유효한 숫자를 입력하세요.");
      return;
    }

    const numericAmount = parseInt(amount);

    if (numericAmount >= 1 || numericAmount <= -1) {
      api
        .put(
          `/v1/api/balance/balance?userNo=${userInfo.userNo}&amount=${numericAmount}`
        )

        .then((response) => {
          alert("수정에 성공했습니다.");
          setShowInputBox(false); // 입력 상자 토글
          getUserInfoList(pagerHelper.currentPage);
        })

        .catch((error) => {
          alert("error : ", error);
          console.error(error);
        });
    } else {
      alert("유효한 숫자를 입력하세요.");
    }
  };

  return (
    <>
      <FlexBox $direct="column" $width="100%">
        <TextBox $pad="0 0 12px">어드민정보</TextBox>
        {agentInfo ? (
          <Table className="user-table-box">
            <thead>
              <tr className="user-table-title">
                <TableHeader>총 지급한 금액</TableHeader>
                <TableHeader>총 회수한 금액</TableHeader>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableDeps>
                  {agentInfo?.addAmount ? agentInfo?.addAmount : "없음"}
                </TableDeps>
                <TableDeps>
                  {agentInfo?.subAmount ? agentInfo?.subAmount : "없음"}
                </TableDeps>
              </tr>
            </tbody>
          </Table>
        ) : (
          <FlexBox $jus="center" $pad="0 0 12px">
            <TextBox>어드민 정보를 불러오지 못했습니다.</TextBox>
          </FlexBox>
        )}

        {showInputBox && (
          <>
            <TextBox className="user-info" $pad="12px 0">
              유저 정보
            </TextBox>
            <Table className="user-table-box">
              <thead>
                <tr className="user-table-title">
                  <TableHeader>유저 번호</TableHeader>
                  <TableHeader>지급한 금액</TableHeader>
                  <TableHeader>회수한 금액</TableHeader>
                </tr>
              </thead>
              <tbody>
                {userInfo ? (
                  <tr className="table-content">
                    <TableDeps>{userInfo.userNo}</TableDeps>
                    <TableDeps>
                      {userInfo.addAmount ? userInfo.addAmount : "없음"}
                    </TableDeps>
                    <TableDeps>
                      {userInfo.subAmount ? userInfo.subAmount : "없음"}
                    </TableDeps>
                  </tr>
                ) : (
                  <TextBox>유저 정보를 불러오지 못했습니다.</TextBox>
                )}
              </tbody>
            </Table>
            <FlexBox
              className="input-box"
              $align="center"
              $gap="8px"
              $pad="12px 0 0"
            >
              <InputTextBox
                $maxWidth="320px"
                placeholder="변경 금액 입력(회수는 앞에 -추가)"
                value={amount}
                $height="36px"
                onChange={(e) => setAmount(e.target.value)}
              />
              <MainBtn $width="48px" onClick={() => submitUserBalance()}>
                입력
              </MainBtn>
            </FlexBox>
          </>
        )}
        {/* 검색 전 or 검색 결과 x 일때 텍스트 노출 */}
        <TextBox $pad="12px 0 12px">유저리스트</TextBox>
        {/* 검색 후 텍스트 hide 테이블 show */}
        <Table className="table-box">
          <thead>
            <tr className="table-title">
              <TableHeader>유저번호</TableHeader>
              <TableHeader>닉네임</TableHeader>
              <TableHeader>이메일</TableHeader>
              <TableHeader>유저잔고</TableHeader>
              <TableHeader>지급/회수</TableHeader>
            </tr>
          </thead>
          <tbody>
            {userList.map((user) => (
              <tr key={user.userNo} className="table-content">
                <TableDeps>{user.userNo}</TableDeps>
                <TableDeps>{user.nickname ? user.nickname : "none"}</TableDeps>
                <TableDeps>{user.email}</TableDeps>
                <TableDeps>{user.balance}</TableDeps>
                <TableDeps>
                  <MainBtn onClick={() => handleMainBtnClick(user.userNo)}>
                    지급/회수
                  </MainBtn>
                </TableDeps>
              </tr>
            ))}
          </tbody>
        </Table>
        {pagerHelper ? (
          <FlexBox $align="center" $jus="center" $width="100%">
            <Pagination
              FlexBox={FlexBox}
              pagerHelper={pagerHelper}
              onPageChange={getUserInfoList}
            />
          </FlexBox>
        ) : (
          <div></div>
        )}
      </FlexBox>
    </>
  );
};

export default UserBalanceManagement;
