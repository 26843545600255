import React, { useEffect } from "react";

import useFormData from "common/useFormData";
import { AreaTitle, MainText } from "style/useTextStyles";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { LineSection, PositionBox } from "style/useLayoutStyles";
import { InputForm, NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import { Column, FilterBox, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import { getFormatDatetime } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { ModifyBtn, NormalStatus, WarnStatus } from "components/common/TableStatus";
import CalendarFilter from "components/common/CalendarFilter";
import { dbAgentAdmin } from "enum/db/useAgentEnum";
import AgentAdminForm from "./AgentAdminForm";
import AgentAdminInfoTab from "./AgentAdminInfoTab";
import { useCountPerPage } from "constants/select/useValue";
import useStateData from "common/useStateData";
import { addAllFromEnum } from "utils/common/EnumUtil";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";

const AgentAdminInfo = ({ agentNo }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup, closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  useEffect(() => {
    useStated.setErrorData([], true);

    getAgentInfo();
  }, []);

  const showAgentAdminFormPopup = () => {
    openPopup({
      title: t("admin.regis_admin"),
      content: AgentAdminForm,
      props: {
        agentNo,
        getTableItem,
      },
    });
  };

  const showAgentAdminInfoPopup = (no, name) => {
    openPopup({
      title: `${t("agent.admin_id_manage")} (${name})`,
      content: AgentAdminInfoTab,
      props: {
        agentNo,
        no,
        getTableItem,
        getAgentInfo,
      },
    });
  };

  const showChangeSuperAdminConfirm = () => {
    const adminArr = getCheckedAgentAdminArr();

    if (adminArr.length !== 1) {
      showAgentAdminLvConditionFail();
    } else {
      openConfirm({
        title: t("agent.rep_agent_admin_manage"),
        message: `${t("agent.select_id")} "${adminArr.map((item) => item.id)[0]}" ${t("agent.update_rep_admin_msg")}`,
        iconURL: true,
        mainBtn: t("common.check"),
        Func: () => updateSuperAgentAdmin(adminArr.map((item) => item.no)),
      });
    }
  };

  const showUpdateAgentAdminsStateConfirm = () => {
    const adminArr = getCheckedAgentAdminArr();

    if (adminArr.length > 0) {
      openConfirm({
        title: t("agent.block_admin"),
        message: `${t("agent.select_id")} "${adminArr.map((item) => item.id)[0]}" ${t("agent.block_admin_msg")}`,
        iconURL: true,
        mainBtn: t("common.check"),
        Func: () => updateAgentAdminsState(adminArr.map((item) => item.no)),
      });
    } else {
      showAgentAdminBlockConditionFail();
    }
  };

  /**
   * 차단으로 변경
   */
  const updateAgentAdminsState = (checkedAdminNo) => {
    setLoading(true);
    const apiUrl = `v1/agent/info/agent-admins/state`;
    api
      .put(apiUrl, {
        agent_admin_no_arr: checkedAdminNo,
      })
      .then((res) => {
        showBlockStateSuccessAlert();
        getTableItem();
      })
      .catch((err) => {
        showBlockStateFailAlert();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  /**
   * 대표관리자로 변경
   *
   */
  const updateSuperAgentAdmin = (checkedAdminNo) => {
    setLoading(true);

    const apiUrl = `v1/agent/info/agent-admins/lv`;
    api
      .put(apiUrl, {
        no: checkedAdminNo[0],
        agent_no: agentNo,
      })
      .then((res) => {
        showSuccessAlert();
        getAgentInfo();
        getTableItem();
      })
      .catch((err) => {
        showFailAlert();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showBlockStateSuccessAlert = () => {
    openAlert({
      title: t("admin.block_complete"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showBlockStateFailAlert = () => {
    openAlert({
      title: t("admin.block_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getCheckedAgentAdminArr = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no, id: item.id }));
  };
  const getAgentInfo = () => {
    setLoading(true);

    const apiUrl = `v1/agent/info/admin-info/${agentNo}`;
    api
      .get(apiUrl, {
        params: {
          lv: dbAgentAdmin.lv.S.value,
        },
      })
      .then((res) => {
        useForm.setFormValue(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showAgentAdminLvConditionFail = () => {
    openAlert({
      title: t("agent.only_one_select_admin"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showAgentAdminBlockConditionFail = () => {
    openAlert({
      title: t("agent.no_select_admin"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showSuccessAlert = () => {
    openAlert({
      title: t("agent.update_rep_agent_admin_complete"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showFailAlert = () => {
    openAlert({
      title: t("agent.update_rep_agent_admin_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const useForm = useFormData({});

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/info/admin-list/${agentNo}`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_state: "",
      search_id: "",
      start_date: "",
      end_date: "",
      date_period: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column $gap="48px" $width="1500px">
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.rep_agent_admin")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.id}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("common.password")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                *******
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("common.phone")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.phone}
              </TextBox>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("admin.admin_list")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Column $width="100%">
            <FilterBox $pad="12px">
              <Row $gap="12px" $align="center">
                <Column $gap="4px">
                  <FilterTitle>{t("agent.access_at")}</FilterTitle>
                  <Row $gap="4px">
                    <CalendarFilter {...useSearch} end />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.access_status")}</FilterTitle>
                  <Row $gap="4px">
                    <SelectForm
                      name="search_state"
                      placeholder={t("common.all")}
                      options={addAllFromEnum(dbAgentAdmin.state)}
                      {...useSearch}
                      {...useStated}
                    />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("admin.admin_id")}</FilterTitle>
                  <Row $gap="4px">
                    <InputForm type="text" name="search_id" placeholder={t("common.input")} {...useSearch} {...useStated} />
                  </Row>
                </Column>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                  {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row className="total-count" $gap="4px" $pad="20px 0 14px">
              <TextBox $size="var(--s-title)" $font="var(--f-title)" $line="var(--l-title)">
                {t("common.total")}
              </TextBox>
              <MainText $size="var(--s-title)" $font="var(--f-title)" $line="var(--l-title)">
                {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords : 0}
              </MainText>

              <TextBox $size="var(--s-title)" $font="var(--f-title)" $line="var(--l-title)">
                {t("common.count")}
              </TextBox>
            </Row>

            <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
              <Row $gap="8px">
                <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showChangeSuperAdminConfirm}>
                  {t("agent.rep_agent_admin_manage")}
                </WhiteBtn>
                <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showUpdateAgentAdminsStateConfirm}>
                  {t("agent.block_admin")}
                </RedBtn>
              </Row>

              <Row $gap="8px">
                <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showAgentAdminFormPopup()}>
                  {t("admin.regis_admin")}
                </MainBtn>
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>

            {useTG.error ? (
              <span>error</span>
            ) : (
              <>
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="34px">
                        <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                      </TableHeader>

                      <TableHeader $width="60px">{t("common.no")}</TableHeader>
                      <TableHeader>{t("admin.admin")}</TableHeader>
                      <TableHeader $width="150px">{t("agent.access_at")}</TableHeader>
                      <TableHeader $width="150px">{t("agent.logout_at")}</TableHeader>
                      <TableHeader>{t("agent.access_ip")}</TableHeader>
                      <TableHeader>{t("agent.type")}</TableHeader>
                      <TableHeader>{t("common.state")}</TableHeader>
                      <TableHeader $width="200px">{t("common.manage")}</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>
                            <NoLabelCheckForm eventType="each" keys={item.no} base={useTG} eachData={item} />
                          </TableDeps>
                          <TableDeps $width="80px">{useTG?.pagingInfo?.offset - index}</TableDeps>
                          <TableDeps $tAlign="left">{`${item.nickname}(${item.no})`}</TableDeps>
                          <TableDeps $width="200px">
                            <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                              {item.last_login_at ? getFormatDatetime(item.last_login_at) : "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $width="200px">
                            <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                              {item.last_logout_at ? getFormatDatetime(item.last_logout_at) : "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps>{item.last_login_ip}</TableDeps>
                          <TableDeps> {dbAgentAdmin.lv[item.lv]?.label}</TableDeps>

                          <TableDeps>
                            <Row $width="100%" $jus="center">
                              {item.state === dbAgentAdmin.state.N.value ? (
                                <NormalStatus name={dbAgentAdmin.state[item.state]?.label} />
                              ) : (
                                <WarnStatus name={dbAgentAdmin.state[item.state]?.label} />
                              )}
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <Row $width="100%" $jus="center" $gap="6px">
                              {/* 클릭이벤트 보내기 예시 */}
                              <ModifyBtn
                                name={t("agent.id_manage")}
                                eventProps={() => showAgentAdminInfoPopup(item.no, item.id)}
                              />
                            </Row>
                          </TableDeps>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
                {useTG && useTG.data?.length === 0 && <NoDataTable />}
              </>
            )}
            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
              <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
              </PositionBox>

              <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
                <Row $gap="8px">
                  <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showChangeSuperAdminConfirm}>
                    {t("agent.rep_agent_admin_manage")}
                  </WhiteBtn>
                  <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showUpdateAgentAdminsStateConfirm}>
                    {t("agent.block_admin")}
                  </RedBtn>
                </Row>

                <Row $gap="8px">
                  <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showAgentAdminFormPopup()}>
                    {t("admin.regis_admin")}
                  </MainBtn>

                  <SelectForm
                    {...useTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                    formValue={useCountPerPage.value}
                    top="top"
                  />
                </Row>
              </Row>
            </Row>
          </Column>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentAdminInfo;
