const dbAuth = {
  type: {
    A: {
      label: "Admin",
      value: "A",
      key: "A",
    },
    G: {
      label: "Agent",
      value: "G",
      key: "G",
    },
    D: {
      label: "User Agent",
      value: "D",
      key: "D",
    },
    U: {
      label: "User",
      value: "U",
      key: "U",
    },
  },
  provider_type: {
    ID: {
      label: "id",
      value: "id",
      key: "id",
    },
    Google: {
      label: "Google",
      value: "Google",
      key: "Google",
    },
  },
  confirm_yn: {
    Y: {
      label: "인증",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미인증",
      value: "N",
      key: "N",
    },
  },
  is_login_yn: {
    Y: {
      label: "로그인 상태",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "로그아웃 상태",
      value: "N",
      key: "N",
    },
  },
  last_login_device: {
    P: {
      label: "PC",
      value: "P",
      key: "P",
    },
    M: {
      label: "MOBILE",
      value: "M",
      key: "M",
    },
  },
};

export { dbAuth };