import React from "react";
import { Column, Row } from "style/custom/useCustomLayout";
import { usePopup } from "contexts/useWindow";
import { IconLink } from "style/useLayoutStyles";

const SelectIconPopup = ({ setIcon }) => {
  const { closePopup } = usePopup();

  const handleClick = (iconName) => {
    setIcon(iconName);
    closePopup();
  };

  return (
    <Column $gap="30px" $pad="20px">
      <Row $gap="30px">
        <IconLink
          $url="/assets/svg/side_bar_hot.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_hot")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_recent.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_recent")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_best.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_best")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_slot.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_slot")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_casino.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_casino")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_eightball.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_eightball")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_luck.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_luck")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_joystick.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_joystick")}
        ></IconLink>
      </Row>
      <Row $gap="30px">
        <IconLink
          $url="/assets/svg/side_bar_roulette.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_roulette")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_sicbo.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_sicbo")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_rocket.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_rocket")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_chess.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_chess")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_spade.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_spade")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_lottery.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_lottery")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_bonus.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_bonus")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_trophy.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_trophy")}
        ></IconLink>
      </Row>
      <Row $gap="30px">
        <IconLink
          $url="/assets/svg/side_bar_target.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_target")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_gift.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_gift")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_controller.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_controller")}
        ></IconLink>
        <IconLink
          $url="/assets/svg/side_bar_card.svg"
          $width="24px"
          $height="24px"
          onClick={() => handleClick("side_bar_card")}
        ></IconLink>
      </Row>
    </Column>
  );
};

export default SelectIconPopup;
