const getData = (data, name, index, groupNm) => {
  if (index !== undefined && index !== null) {
    if (groupNm) {
      return data[groupNm][index][name];
    } else {
      return Array.isArray(data) ? data[index][name] : data[name][index];
    }
  } else {
    return data[name] == null ? "" : data[name];
  }
};

const checkboxOnclick = (name, chgVal, index, groupNm, formValue, onChange, trueValue, falseValue) => {
  let tmpData = [...formValue[name]];
  let cnt = tmpData.length;
  tmpData[index] = chgVal;

  if (index == 0) {
    let chgData = Array.from({ length: cnt }, (v, i) => (i === 0 ? chgVal : falseValue)).join("");
    onChange(name, chgData);
  } else {
    let sct_cnt = tmpData.filter((row, index) => index !== 0 && row === trueValue);
    if (cnt - 1 == sct_cnt.length) {
      // 전체 Y의 경우
      let chgData = Array.from({ length: cnt }, (v, i) => (i === 0 ? trueValue : falseValue)).join("");
      onChange(name, chgData);
    } else {
      tmpData[0] = falseValue;
      let chgData = Array.from({ length: cnt }, (v, i) => (tmpData[i] === trueValue ? trueValue : falseValue)).join("");
      onChange(name, chgData);
    }
  }
};

function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

function parseFormData(options) {
  if (!Array.isArray(options)) {
    options = Object.values(options);
  }

  const trueValue = options[0].value;
  const falseValue = options[1].value;
  const trueDisplayValue = options[0].label;
  const falseDisplayValue = options[1].label;

  return [options, trueValue, falseValue, trueDisplayValue, falseDisplayValue];
}

/**
 * JWT 치환
 * @param {String} token 토큰 값
 */
function TokenSubstitution(token) {
  if (!token) return;
  const base64Payload = token.split('.')[1];
  const payload = JSON.parse(atob(base64Payload));
  return payload
}
/**
 * JWT 유효성 검사
 * @param {Int} exp 토큰 유효 시간
 */
function isTokenValid(exp) {
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime < exp;
}

export { getData, checkboxOnclick, fileToBase64, parseFormData, TokenSubstitution, isTokenValid };
