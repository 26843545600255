import styled, { css } from "styled-components";

const createTextBox = ({
  $color,
  $size,
  $font,
  $weight,
  $line,
  $tAlign,
  $white,
  $width,
  $minWidth,
  $op,
  $over,
  $textDeco,
  $pad,
  $radius,
  $mask,
  $border,
  $shadow,
  $mar,
}) => css`
  color: ${$color};

  font-size: ${$size};
  font-family: ${$font};
  font-weight: ${$weight};

  line-height: ${$line};
  width: ${$width};
  min-width: ${$minWidth};

  text-align: ${$tAlign};
  white-space: ${$white};
  opacity: ${$op};
  overflow: ${$over};
  text-decoration: ${$textDeco};

  margin: ${$mar};
  padding: ${$pad};
  border-radius: ${$radius};
  border: ${$border};

  mask-image: ${$mask};
  -webkit-mask-image: ${$mask};
  box-shadow: ${$shadow};

  &.warn {
    color: var(--c-red);
    font-family: var(--f-subB);
  }

  &.complete {
    color: var(--c-blue);
    font-family: var(--f-subB);
  }

  &.normal {
    color: var(--c-brown);
    font-family: var(--f-subB);
  }

  &.withdraw {
    color: var(--c-gray-900);
    font-family: var(--f-subB);
  }

  &.test {
    color: var(--c-mint-600);
    font-family: var(--f-subB);
  }
  &.wait {
    font-family: var(--f-subB);
  }
`;

const createBorderTextBox = ({ $pad, $width, $height, $tAlign }) => css`
  width: ${$width ? $width : "180px"};
  padding: ${$pad ? $pad : "8px 0"};
  border-bottom: 1px solid #aaa;
  height: ${$height ? $height : "40px"};

  font-size: var(--s-caption);
  line-height: var(--l-caption);
  text-align: ${$tAlign};
`;

const createErrorMsg = ({ $top, $size, $font, $line, $position }) => css`
  font-size: ${$size ? $size : " var(--s-caption)"};
  font-family: ${$font ? $font : " var(--f-caption)"};
  line-height: ${$line ? $line : " var(--l-caption)"};

  top: ${$top};
  position: ${$position};
  color: var(--c-red);
`;

const createSuccessMsg = ({ $top, $size, $font, $line, $position }) => css`
  font-size: ${$size ? $size : " var(--s-caption)"};
  font-family: ${$font ? $font : " var(--f-caption)"};
  line-height: ${$line ? $line : " var(--l-caption)"};

  top: ${$top};
  position: ${$position};
  color: var(--c-blue);
`;

const createMainText = ({ $size, $font, $line }) => css`
  font-size: ${$size ? $size : " var(--s-title)"};
  font-family: ${$font ? $font : " var(--f-title)"};
  line-height: ${$line ? $line : " var(--l-title)"};

  color: var(--c-mint-600);
`;

const MainText = styled.span`
  ${createMainText}
`;

const TextBox = styled.span`
  ${createTextBox}
`;

const BorderTextBox = styled.p`
  ${createBorderTextBox}
`;

const SubText = styled.span`
  white-space: nowrap;
  color: var(--c-gray-500);
  line-height: var(--l-sub);
  font-family: var(--f-subB);
`;

const AreaTitle = styled.p`
  font-family: var(--f-subB);
  line-height: var(--l-sub);
  white-space: nowrap;
`;

const InfoTitle = styled.p`
  min-width: 60px;

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  line-height: var(--l-caption);
  font-weight: bold;

  color: var(--c-toastBg);
  white-space: nowrap;
`;

const ErrorMsg = styled.span.attrs({ className: "error" })`
  ${createErrorMsg}
`;

const SuccessMsg = styled.span.attrs({ className: "Success" })`
  ${createSuccessMsg}
`;

const Label = styled.label`
  white-space: nowrap;
  color: var(--c-gray-900);
  font-size: ${(props) => props.size || "var(--s-sub)"};
  line-height: var(--l-sub);
`;

export { Label, TextBox, SubText, MainText, AreaTitle, InfoTitle, ErrorMsg, SuccessMsg, BorderTextBox };
