import React, { useState, useEffect } from "react";
import Pagination from "utils/Pagination";
import api from "interceptor/api";
import useStyle from "style/useStyle";

const UsersTransactionsPage = () => {
  const { Table, FlexBox, TextBox, MainBtn, TableDeps, TableHeader, InputTextBox } = useStyle();
  const [usersTransactionsList, setUsersTransactionsList] = useState([]);
  const [pagerHelper, setPagerHelper] = useState();
  const [searchUserNo, setSearchUserNo] = useState("");

  let getParameter = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  };

  const userNo = getParameter("userNo");

  useEffect(() => {
    api
      .get("/v1/api/game-admin/users-transactions", {
        params: { userNo: userNo },
      })

      .then((res) => {
        setUsersTransactionsList(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        alert("거래 정보를 불러오지 못했습니다.");
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfosTransactionsPage = (page) => {
    api
      .get("/v1/api/game-admin/users-transactions", {
        params: {
          // TODO : userNo이 왜 들어가야하는지
          userNo: searchUserNo || userNo,
          page: page,
          countPerPage: pagerHelper.countPerPage,
        },
      })

      .then((res) => {
        setUsersTransactionsList(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        alert("거래 정보를 불러오지 못했습니다.");
        console.error(error);
      });
  };

  return (
    <>
      <FlexBox $direct='column' $gap='12px'>
        <FlexBox $jus='space-between' $align='flex-start'>
          <TextBox>거래 내역</TextBox>
          <FlexBox $gap='12px'>
            <InputTextBox
              placeholder='유저 번호'
              value={searchUserNo}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 9) {
                  setSearchUserNo(value);
                }
              }}
              $height='40px'
              $maxWidth='160px'
            />
            <MainBtn onClick={() => getUserInfosTransactionsPage(1)} $height='40px' $width='60px'>
              검색
            </MainBtn>
          </FlexBox>
        </FlexBox>
        {/* 검색 후 텍스트 hide 테이블 show */}
        <Table className='table-box'>
          <thead>
            {/* WIDTH는 고정하지 않은 상태 아직 텍스트 관련 내용없음. */}
            <tr className='table-title'>
              <TableHeader $width='100px'>거래ID</TableHeader>
              <TableHeader $width='100px'>유저번호</TableHeader>
              <TableHeader $width='100px'>거래타입</TableHeader>
              <TableHeader $width='100px'>거래금액</TableHeader>
              <TableHeader $width='160px'>거래시간</TableHeader>
              <TableHeader>상세정보</TableHeader>
            </tr>
          </thead>
          <tbody>
            {/* tr 맵 돌리기 */}
            {usersTransactionsList.map((userStats) => (
              <tr key={userStats.transactionId} className='table-content'>
                <TableDeps>{userStats.transactionId}</TableDeps>
                <TableDeps>{userStats.userNo}</TableDeps>
                <TableDeps>{userStats.type}</TableDeps>
                <TableDeps>{userStats.amount?.toLocaleString()}</TableDeps>
                <TableDeps>{userStats.createAt}</TableDeps>
                <TableDeps>
                  <FlexBox $gap='8px' $jus='center' $align='centet' $flexWrap='wrap'>
                    <TextBox
                      $white='break-spaces'
                      $size='var(--s-sub)'
                      $font='var(--f-sub)'
                      $line='var(--l-sub)'>
                      {userStats.responseJson}
                    </TextBox>
                  </FlexBox>
                </TableDeps>
              </tr>
            ))}
          </tbody>
        </Table>
        {pagerHelper && (
          <FlexBox $align='center' $jus='center' $width='100%'>
            <Pagination pagerHelper={pagerHelper} onPageChange={getUserInfosTransactionsPage} />
          </FlexBox>
        )}
      </FlexBox>
    </>
  );
};

export default UsersTransactionsPage;
