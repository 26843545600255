import { useEffect } from "react";

import useFormData from "common/useFormData";
import { useAlert, usePopup } from "contexts/useWindow";
import api from "interceptor/api";

import useStateData from "common/useStateData";
import BankSelectForm from "components/common/BankSelectForm";
import { EachCheckForm, InputForm, SelectForm, TextareaForm, ToggleButtonForm } from "components/common/CommonForm";
import UserLevelForm from "components/common/UserLevelForm";
import { useCommonState } from "contexts/useCommonState";
import { dbUser, dbUserState2 } from "enum/db/useUserEnum";
import { checkboxYNEnum } from "enum/useCommonEnum";
import { formModifyUser } from "enum/user/useUserFromEnum";
import { t } from "i18next";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import { getFormatDatetime } from "utils/date/DateUtil";

const UserInfoForm = ({ userNo, refreshUser }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    getUserInfo();
  }, []);

  const showFormAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("userInfo.modify_complete") : t("userInfo.modify_fail"),
      message: isSuccess ? t("userInfo.modify_complete_msg") : t("userInfo.modify_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  const getUserInfo = () => {
    setLoading(true);

    const apiUrl = `/v1/user/all/info/${userNo}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,

          nickname: res.data.content.nickname || "-",
          code: res.data.content.code || "-",
          type: res.data.content.type_name || "-",
          join_code: res.data.content.join_code || "-",
          money: res.data.content.money.toLocaleString(),

          join_request_at: getFormatDatetime(res.data.content.join_request_at) || "-",
          join_confirm_at: getFormatDatetime(res.data.content.join_confirm_at) || "-",

          account_in_money: res.data.content.account_in_money?.toLocaleString() || "0",
          account_out_money: res.data.content.account_out_money?.toLocaleString() || "0",
          account_in_out_total: (res.data.content.account_in_money - res.data.content.account_out_money)?.toLocaleString() || "0",
          
          bet_money: res.data.content.bet_money?.toLocaleString() || "0",
          win_money: res.data.content.win_money?.toLocaleString() || "0",
          bet_minus_win_total: (res.data.content.bet_money - res.data.content.win_money)?.toLocaleString() || "0",

          win_point: res.data.content.win_point?.toLocaleString() || "0",
          change_point: res.data.content.change_point?.toLocaleString() || "0",
          point: res.data.content.point?.toLocaleString() || "0",
          accumulated_point : res.data.content.accumulated_point?.toLocaleString() || "0",

          high_value_user: res.data.content.state2_arr?.includes(dbUserState2.state2.H.value) ? "Y" : "N",
          two_way_user: res.data.content.state2_arr?.includes(dbUserState2.state2.B.value) ? "Y" : "N",
          threat_user: res.data.content.state2_arr?.includes(dbUserState2.state2.T.value) ? "Y" : "N",
          fake_user: res.data.content.state2_arr?.includes(dbUserState2.state2.O.value) ? "Y" : "N",
          deficit_user: res.data.content.state2_arr?.includes(dbUserState2.state2.M.value) ? "Y" : "N",
          other_user: res.data.content.state2_arr?.includes(dbUserState2.state2.E.value) ? "Y" : "N",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const clickSubmitBtn = () => {
    const apiUrl = `/v1/user/all/info/${userNo}`;

    api
      .put(apiUrl, {
        ...useForm.formValue,
      })
      .then(() => {
        showFormAlert(true);
        getUserInfo();
        refreshUser();
        closePopup();
      })
      .catch((err) => {
        console.error(err);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showFormAlert(false);
      });
  };

  const useForm = useFormData({});

  return (
    <>
      <Column className="area-box" $gap="20px" $width="1000px">
        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_state1")}
            </TextBox>
            {useForm.formValue.state1 === dbUser.state1.W.value || useForm.formValue.state1 === dbUser.state1.T.value ? (
              <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
                {dbUser.state1[useForm.formValue.state1]?.label}
              </TextBox>
            ) : (
              <SelectForm
                name="state1"
                placeholder={t("user.user_state1_msg")}
                options={formModifyUser.state1}
                {...useForm}
                {...useStated}
              />
            )}
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.nickname")}
            </TextBox>
            <InputForm maxWidth="100%" disabled="ture" name="nickname" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_state2")}
            </TextBox>
            <EachCheckForm
              $size="var(--s-caption)"
              name="high_value_user"
              labelFor="high_value_user"
              options={checkboxYNEnum}
              labelName={t("userInfo.high_value")}
              {...useForm}
            />
            <EachCheckForm
              $size="var(--s-caption)"
              name="two_way_user"
              labelFor="two_way_user"
              options={checkboxYNEnum}
              labelName={t("userInfo.two_way")}
              {...useForm}
            />
            <EachCheckForm
              $size="var(--s-caption)"
              name="threat_user"
              labelFor="threat_user"
              options={checkboxYNEnum}
              labelName={t("userInfo.threat")}
              {...useForm}
            />
            <EachCheckForm
              $size="var(--s-caption)"
              name="fake_user"
              labelFor="fake_user"
              options={checkboxYNEnum}
              labelName={t("userInfo.fake")}
              {...useForm}
            />
            <EachCheckForm
              $size="var(--s-caption)"
              name="deficit_user"
              labelFor="deficit_user"
              options={checkboxYNEnum}
              labelName={t("userInfo.deficit")}
              {...useForm}
            />
            <EachCheckForm
              $size="var(--s-caption)"
              name="other_user"
              labelFor="other_user"
              options={checkboxYNEnum}
              labelName={t("userInfo.other")}
              {...useForm}
            />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("common.phone")}
            </TextBox>
            <InputForm
              maxWidth="100%"
              name="phone_number"
              maskType="phone"
              placeholder="000-0000-0000"
              {...useForm}
              {...useStated}
            />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_id")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" name="id" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.refer_code")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" name="refer_code" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.money_balance")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="money" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.point_balance")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="point" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <UserLevelForm useForm={useForm} useStated={useStated} width="130px" isRequired={false} />

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.join_confirm_at")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" name="join_confirm_at" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center"></FormBox>
          <FormBox $flex="1" $align="center"></FormBox>
        </Row>

        <Row $gap="48px">
          <BankSelectForm useForm={useForm} useStated={useStated} width="130px" isRequired={false} />

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_agent_code")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" name="code" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.account_name")}
            </TextBox>

            <InputForm width="100%" maxWidth="100%" name="account_name" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_agent_type_name")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" name="type" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.account_number")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" name="account_no" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.join_code")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" name="join_code" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row></Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_total_deposit")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="account_in_money" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_total_bet_money")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="bet_money" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_total_withdrawal")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="account_out_money" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.user_total_win_money")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="win_money" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.total_deposits_withdrawals")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="account_in_out_total" {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.total_bets_wins")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="bet_minus_win_total" {...useForm} {...useStated} />
          </FormBox>
        </Row>

        <Row></Row>

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.first_charge_point")}
            </TextBox>
            <ToggleButtonForm name="first_charge_point_yn" options={dbUser.first_charge_point_yn} {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.total_points")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="accumulated_point" {...useForm} {...useStated} />
          </FormBox>
        </Row >

        <Row $gap="48px">
          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.every_charge_point")}
            </TextBox>
            <ToggleButtonForm name="every_charge_point_yn" options={dbUser.every_charge_point_yn} {...useForm} {...useStated} />
          </FormBox>

          <FormBox $flex="1" $align="center">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.change_point")}
            </TextBox>
            <InputForm width="100%" maxWidth="100%" disabled="ture" tAlign="right" name="change_point" {...useForm} {...useStated} />
          </FormBox >
        </Row >

        <Row $align="center" $gap="8px" $pad="24px 0px">
          <Row $width="100%">
            <TextBox $width="130px" $minWidth="130px" $line="var(--l-sub)">
              {t("user.admin_memo")}
            </TextBox>
            <TextareaForm type="text" name="memo" placeholder={t("user.admin_memo_msg")} {...useForm} {...useStated} />
          </Row>
        </Row>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={clickSubmitBtn}>
              {t("common.save")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column >
    </>
  );
};

export default UserInfoForm;
