import React, { useEffect, useState } from "react";

import useFormData from "common/useFormData";
import { AreaTitle, Label } from "style/useTextStyles";
import { MainBtn, MenuToggleBtn } from "style/useButtonStyles";
import { SubText, TextBox } from "style/useTextStyles";
import { HoverBox, HoverCon, IconLink, LineSection } from "style/useLayoutStyles";
import { EachCheckFormCustom, InputForm, ToggleButtonForm } from "components/common/CommonForm";
import { AreaHeader, Column, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useCommonState } from "contexts/useCommonState";
import { useAlert } from "contexts/useWindow";
import useStateData from "common/useStateData";
import { dbSitePointConfig } from "enum/db/useSiteEnum";
import { checkboxYNEnum, validationTypeEnum } from "enum/useCommonEnum";
import { t } from "i18next";
import styled, { css } from "styled-components";

const RowSpanTableDeps = styled.td`
  display: table-cell;
  border-bottom: 1px solid var(--c-gray-300);
  vertical-align: middle;
  font-size: var(--s-table);
  line-height: var(--l-table);
  background-color: var(--c-white);
`;

const CompTableRow = styled.tr`
  background-color: ${(props) => (props.type === "user" ? "var(--c-white)" : "var(--c-tableDeps)")};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    `}

  &:hover {
    background-color: var(--c-gray-300) !important;
  }
`;

const SiteBonus = () => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();

  const [levelBonus, setLevelBonus] = useState();
  const { openAlert } = useAlert();

  const [dropdownState, setDropdownState] = useState({});

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true,
      2: true,
      3: true,
      4: true,
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false,
      2: false,
      3: false,
      4: false,
    });
  };

  useEffect(() => {
    useStated.setErrorData([], true);

    getSiteBonusConfig();
  }, []);

  const getSiteBonusConfig = () => {
    setLoading(true);

    const apiUrl = "v1/site/bonus/1";
    api
      .get(apiUrl)
      .then((res) => {
        console.log(res.data.content);
        const { levelBonusData, pointConfigData, chargeBonusData, siteLevelData } = res.data.content;

        useBonusConfig.setFormValue({ ...pointConfigData, chargeBonusData, siteLevelData });

        setLevelBonus(levelBonusData);
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const putSiteSignBonusConfig = (body) => {
    setLoading(true);

    const apiUrl = "v1/site/bonus/sign-bonus/1";
    api
      .put(apiUrl, {
        ...body,
      })
      .then((res) => {
        showSuccessAlert();
        getSiteBonusConfig();
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        showFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const putSiteChargeBonusConfig = (body) => {
    setLoading(true);

    const apiUrl = "v1/site/bonus/charge-bonus/1";
    api
      .put(apiUrl, {
        ...body,
      })
      .then((res) => {
        showSuccessAlert();
        getSiteBonusConfig();
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        showFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const putCompByLevelConfig = (body) => {
    setLoading(true);

    const apiUrl = "v1/site/bonus/comp-by-level/1";
    api
      .put(apiUrl, {
        ...body,
      })
      .then((res) => {
        showSuccessAlert();
        getSiteBonusConfig();
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        showFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const putPointToCashConfig = (body) => {
    setLoading(true);

    const apiUrl = "v1/site/bonus/point-to-cash/1";
    api

      .put(apiUrl, {
        ...body,
      })
      .then((res) => {
        showSuccessAlert();
        getSiteBonusConfig();
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        showFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showSuccessAlert = () => {
    openAlert({
      title: t("common.save_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showFailAlert = () => {
    openAlert({
      title: t("common.save_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  function putSiteSignBonus() {
    const { sign_up_bonus_yn, sign_up_bonus_yn_point } = useBonusConfig.formValue;
    putSiteSignBonusConfig({
      sign_up_bonus_yn,
      sign_up_bonus_yn_point,
    });
  }

  function putCompByLevel() {
    const formValues = useBonusConfig.formValue;
    const { member_rolling_point_yn, referrer_rolling_point_yn, member_losing_point_yn, referrer_losing_point_yn } = formValues;
    const siteConfig = {
      member_rolling_point_yn,
      referrer_rolling_point_yn,
      member_losing_point_yn,
      referrer_losing_point_yn,
    };

    const config = {
      ...siteConfig,
      ...{
        siteLevelData: [...formValues.siteLevelData],
      },
    };

    putCompByLevelConfig(config);
  }

  function putPointToCash() {
    const {
      point_change_time_yn,
      point_to_cash_min,
      point_to_cash_max,
      point_to_cash_unit,
      point_to_cash_time_yn,
      point_to_cash_change_week,
      point_to_cash_start_time,
      point_to_cash_end_time,
    } = useBonusConfig.formValue;
    putPointToCashConfig({
      point_change_time_yn,
      point_to_cash_min,
      point_to_cash_max,
      point_to_cash_unit,
      point_to_cash_time_yn,
      point_to_cash_change_week,
      point_to_cash_start_time,
      point_to_cash_end_time,
    });
  }

  function putSiteChargeBonus() {
    const formValues = useBonusConfig.formValue;
    const {
      charge_bonus_yn,
      exchange_first_charge_yn,
      exchange_every_charge_yn,
      hold_first_charge_yn,
      hold_every_charge_yn,
      unpaid_standard_exg_money,
      unpaid_standard_hold_money,
      chargeBonusData,
    } = formValues;

    const siteConfig = {
      charge_bonus_yn,
      exchange_first_charge_yn,
      exchange_every_charge_yn,
      hold_first_charge_yn,
      hold_every_charge_yn,
      unpaid_standard_exg_money,
      unpaid_standard_hold_money,
    };

    const config = {
      ...siteConfig,
      ...(levelBonus.user_level_yn === check_options.trueValue && {
        chargeBonusData: [...chargeBonusData],
      }),
    };

    putSiteChargeBonusConfig(config);
  }

  const useBonusConfig = useFormData({});

  const generateRows = (title, name) => {
    const rows = [];
    for (let i = 0; i <= levelBonus?.user_max_level; i++) {
      rows.push(
        <TableDeps key={i} $bor="1px solid var(--c-gray-300)" $height="42px" $minHeight="42px">
          <Row>
            <InputForm
              width="135px"
              type="number"
              tAlign="right"
              name={name}
              placeholder="0"
              height="28px"
              index={i}
              groupNm="chargeBonusData"
              {...useBonusConfig}
              {...useStated}
            />
            <TextBox $line="var(--l-sub)">%</TextBox>
          </Row>
        </TableDeps>
      );
    }
    return (
      <TableRow>
        <TableHeader colSpan={1} $bor="1px solid var(--c-gray-300)">
          {title}
        </TableHeader>
        {rows}
      </TableRow>
    );
  };

  const generateTitle = () => {
    const rows = [];
    for (let i = 0; i <= levelBonus?.user_max_level; i++) {
      rows.push(
        <TableHeader key={i} $bor="1px solid var(--c-gray-300)">
          {i}
          {t("common.level")}
        </TableHeader>
      );
    }
    return (
      <TableRow>
        <TableHeader $width="130px"></TableHeader>
        {rows}
      </TableRow>
    );
  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.site_config_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.site_bonus")}</AreaTitle>
        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}
          </MenuToggleBtn>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>
      <Column $gap="64px">
        {/* 1. 회원 가입 보너스 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(1)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>{"1. " + t("bonus.user_sign_bonus")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("bonus.ser_sign_bonus_msg")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  putSiteSignBonus();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[1] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>

          {dropdownState[1] && (
            <Row>
              <OptionBox $flex="1">
                <Row $gap="16px" $align="center">
                  <TextBox $width="160px" $line="var(--l-sub)">
                    {t("bonus.sign_bonus")}
                  </TextBox>
                  <ToggleButtonForm
                    name="sign_up_bonus_yn"
                    options={dbSitePointConfig.sign_up_bonus_yn}
                    {...useBonusConfig}
                    {...useStated}
                  />
                </Row>
              </OptionBox>

              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

              <OptionBox $flex="1" $align="center">
                <TextBox $width="160px" $line="var(--l-sub)">
                  {t("bonus.sign_bonus_point")} (P)
                </TextBox>
                <InputForm
                  tAlign="right"
                  name="sign_up_bonus_yn_point"
                  placeholder="0"
                  maxLength={19}
                  validationType={validationTypeEnum.NUMBER}
                  disabled={useBonusConfig.formValue.sign_up_bonus_yn !== dbSitePointConfig.sign_up_bonus_yn.Y.value}
                  {...useBonusConfig}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">{t("bonus.sign_bonus_point_msg")}</TextBox>
              </OptionBox>
            </Row>
          )}
        </Column>

        {/* 2. 충전보너스 전역설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(2)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>{"2. " + t("bonus.charge_bonus_global")}</AreaTitle>

              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("bonus.charge_bonus_msg")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  putSiteChargeBonus();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[2] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[2] && (
            <>
              <OptionBox $align="center">
                <Row $gap="16px" $align="center">
                  <TextBox $width="160px" $line="var(--l-sub)">
                    {t("bonus.charge_bonus")}
                  </TextBox>
                  <ToggleButtonForm name="charge_bonus_yn" options={dbSitePointConfig.charge_bonus_yn} {...useBonusConfig} />
                </Row>
              </OptionBox>

              <Row>
                <OptionBox $align="center">
                  <Row $gap="16px" $align="center">
                    <TextBox $width="160px" $line="var(--l-sub)">
                      {t("bonus.first_charge")}
                    </TextBox>
                    <ToggleButtonForm
                      name="exchange_first_charge_yn"
                      options={dbSitePointConfig.exchange_first_charge_yn}
                      {...useBonusConfig}
                      {...useStated}
                    />
                  </Row>
                </OptionBox>

                <OptionBox $flex="1" $align="center">
                  <TextBox $width="80px" $line="var(--l-sub)">
                    {t("bonus.every_charge")}
                  </TextBox>
                  <ToggleButtonForm
                    name="exchange_every_charge_yn"
                    options={dbSitePointConfig.exchange_every_charge_yn}
                    {...useBonusConfig}
                    {...useStated}
                  />

                  <InputForm
                    tAlign="right"
                    name="unpaid_standard_exg_money"
                    placeholder="0"
                    maxLength={19}
                    validationType={validationTypeEnum.NUMBER}
                    {...useBonusConfig}
                    {...useStated}
                  />
                  <TextBox $line="var(--l-sub)">{t("bonus.condition_exchage")}</TextBox>
                </OptionBox>
              </Row>

              <Row>
                <OptionBox $align="center">
                  <TextBox $width="160px" $line="var(--l-sub)">
                    {t("bonus.first_charge")}
                  </TextBox>
                  <ToggleButtonForm
                    name="hold_first_charge_yn"
                    options={dbSitePointConfig.hold_first_charge_yn}
                    {...useBonusConfig}
                    {...useStated}
                  />
                </OptionBox>

                <OptionBox $flex="1" $align="center">
                  <TextBox $width="80px" $line="var(--l-sub)">
                    {t("bonus.every_charge")}
                  </TextBox>
                  <ToggleButtonForm
                    name="hold_every_charge_yn"
                    options={dbSitePointConfig.hold_every_charge_yn}
                    {...useBonusConfig}
                    {...useStated}
                  />

                  <InputForm
                    tAlign="right"
                    name="unpaid_standard_hold_money"
                    placeholder="0"
                    maxLength={19}
                    validationType={validationTypeEnum.NUMBER}
                    {...useBonusConfig}
                    {...useStated}
                  />
                  <TextBox $line="var(--l-sub)">{t("bonus.condition_held_money")}</TextBox>
                </OptionBox>
              </Row>

              {levelBonus?.user_level_yn === "Y" && (
                <Table $tLayout="fixed">
                  <thead>{generateTitle()}</thead>
                  <tbody>
                    {generateRows(t("bonus.first_sign_charge"), "join_first_charge")}
                    {generateRows(t("bonus.first_charge"), "first_charge")}
                    {generateRows(t("bonus.every_charge"), "every_charge")}
                  </tbody>
                </Table>
              )}
            </>
          )}
        </Column>

        {/* 3. 회원 콤프 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(3)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>{"3. " + t("bonus.user_comp_config")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("bonus.user_comp_config_msg_1")}
                  </TextBox>
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("bonus.user_comp_config_msg_F")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  putCompByLevel();
                }}
              >
                {t("common.go_save")}
              </MainBtn>{" "}
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[3] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>
          {dropdownState[3] && (
            <>
              <Row>
                <OptionBox $flex="1" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $tAlign="center" $line="var(--l-sub)">
                      {t("bonus.member_rolling_point_yn")}
                    </TextBox>
                  </Row>
                  <Row>
                    <ToggleButtonForm
                      name="member_rolling_point_yn"
                      options={dbSitePointConfig.member_rolling_point_yn}
                      {...useBonusConfig}
                      {...useStated}
                    />
                  </Row>
                </OptionBox>
                <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
                <OptionBox $flex="1" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)" $white="nowrap">
                      {t("bonus.referrer_rolling_point_yn")}
                    </TextBox>
                  </Row>
                  <Row>
                    <ToggleButtonForm
                      name="referrer_rolling_point_yn"
                      options={dbSitePointConfig.referrer_rolling_point_yn}
                      {...useBonusConfig}
                      {...useStated}
                    />
                  </Row>
                </OptionBox>
              </Row>
              {(useBonusConfig.formValue?.member_rolling_point_yn === dbSitePointConfig.member_rolling_point_yn.Y.value ||
                useBonusConfig.formValue?.referrer_rolling_point_yn === dbSitePointConfig.referrer_rolling_point_yn.Y.value) && (
                  <>
                    <OptionBox $backColor="var(--c-gray-100)">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $pad="4px 0" $line="var(--l-sub)">
                          {t("bonus.rolling_point_ratio")}
                        </TextBox>
                      </Row>
                      <Column $gap="12px" $width="100%">
                        <Table className="table-box">
                          <thead>
                            <TableRow className="table-title">
                              <TableHeader $width="100px">{t("common.level")}</TableHeader>
                              <TableHeader>{t("bonus.comp_type")}</TableHeader>
                              <TableHeader>{t("defaultPath.casino_rolling_rate")}</TableHeader>
                              <TableHeader>{t("defaultPath.slot_rolling_rate")}</TableHeader>
                              <TableHeader>{t("defaultPath.mini_game_rolling_rate")}</TableHeader>
                              <TableHeader>{t("defaultPath.sports_rolling_rate")}</TableHeader>
                            </TableRow>
                          </thead>
                          <tbody>
                            {useBonusConfig.formValue?.siteLevelData.map((item, index) => (
                              <React.Fragment key={index}>
                                {useBonusConfig.formValue?.member_rolling_point_yn ===
                                  dbSitePointConfig.member_rolling_point_yn.Y.value && (
                                    <CompTableRow type="user" className="table-content">
                                      {useBonusConfig.formValue?.member_rolling_point_yn ===
                                        dbSitePointConfig.member_rolling_point_yn.Y.value &&
                                        useBonusConfig.formValue?.referrer_rolling_point_yn ===
                                        dbSitePointConfig.referrer_rolling_point_yn.Y.value ? (
                                        <RowSpanTableDeps rowSpan={2}>{`${index} 레벨`}</RowSpanTableDeps>
                                      ) : (
                                        <TableDeps>{`${index} 레벨`}</TableDeps>
                                      )}
                                      <TableDeps $height="42px">{t("bonus.member_rolling")}</TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_casino_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_slot_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_mini_game_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_sports_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                    </CompTableRow>
                                  )}
                                {useBonusConfig.formValue?.referrer_rolling_point_yn ===
                                  dbSitePointConfig.referrer_rolling_point_yn.Y.value && (
                                    <CompTableRow type="recommend" className="table-content">
                                      {useBonusConfig.formValue?.member_rolling_point_yn !==
                                        dbSitePointConfig.member_rolling_point_yn.Y.value &&
                                        useBonusConfig.formValue?.referrer_rolling_point_yn ===
                                        dbSitePointConfig.referrer_rolling_point_yn.Y.value ? (
                                        <TableDeps>{`${index} 레벨`}</TableDeps>
                                      ) : null}
                                      <TableDeps $height="42px">{t("bonus.referrer_rolling")}</TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_casino_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_slot_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_mini_game_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_sports_rolling_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                    </CompTableRow>
                                  )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </Column>
                    </OptionBox>
                  </>
                )}
              <Row>
                <OptionBox $flex="1" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $tAlign="center" $line="var(--l-sub)">
                      {t("bonus.member_losing_point_yn")}
                    </TextBox>
                  </Row>
                  <Row>
                    <ToggleButtonForm
                      name="member_losing_point_yn"
                      options={dbSitePointConfig.member_losing_point_yn}
                      {...useBonusConfig}
                    />
                  </Row>
                </OptionBox>
                <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
                <OptionBox $flex="1" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $width="160px" $minWidth="160px" $line="var(--l-sub)" $white="nowrap">
                      {t("bonus.referrer_losing_point_yn")}
                    </TextBox>
                  </Row>
                  <Row>
                    <ToggleButtonForm
                      name="referrer_losing_point_yn"
                      options={dbSitePointConfig.referrer_losing_point_yn}
                      {...useBonusConfig}
                    />
                  </Row>
                </OptionBox>
              </Row>
              {(useBonusConfig.formValue?.member_losing_point_yn === dbSitePointConfig.member_losing_point_yn.Y.value ||
                useBonusConfig.formValue?.referrer_losing_point_yn === dbSitePointConfig.referrer_losing_point_yn.Y.value) && (
                  <>
                    <OptionBox $backColor="var(--c-gray-100)">
                      <Row $width="160px" $minWidth="160px">
                        <TextBox $pad="4px 0" $line="var(--l-sub)">
                          {t("bonus.losing_point_ratio")}
                        </TextBox>
                      </Row>
                      <Column $gap="12px" $width="100%">
                        <Table className="table-box">
                          <thead>
                            <TableRow className="table-title">
                              <TableHeader $width="100px">{t("common.level")}</TableHeader>
                              <TableHeader>{t("bonus.comp_type")}</TableHeader>
                              <TableHeader>{t("defaultPath.casino_losing_rate")}</TableHeader>
                              <TableHeader>{t("defaultPath.slot_losing_rate")}</TableHeader>
                              <TableHeader>{t("defaultPath.mini_game_losing_rate")}</TableHeader>
                              <TableHeader>{t("defaultPath.sports_losing_rate")}</TableHeader>
                            </TableRow>
                          </thead>
                          <tbody>
                            {useBonusConfig.formValue?.siteLevelData.map((item, index) => (
                              <React.Fragment key={index}>
                                {useBonusConfig.formValue?.member_losing_point_yn ===
                                  dbSitePointConfig.member_losing_point_yn.Y.value && (
                                    <CompTableRow type="user" className="table-content">
                                      {useBonusConfig.formValue?.member_losing_point_yn ===
                                        dbSitePointConfig.member_losing_point_yn.Y.value &&
                                        useBonusConfig.formValue?.referrer_losing_point_yn ===
                                        dbSitePointConfig.referrer_losing_point_yn.Y.value ? (
                                        <RowSpanTableDeps rowSpan={2}>{`${index} 레벨`}</RowSpanTableDeps>
                                      ) : (
                                        <TableDeps>{`${index} 레벨`}</TableDeps>
                                      )}
                                      <TableDeps $height="42px">{t("bonus.member_losing")}</TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_casino_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_slot_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_mini_game_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="user_sports_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                    </CompTableRow>
                                  )}
                                {useBonusConfig.formValue?.referrer_losing_point_yn ===
                                  dbSitePointConfig.referrer_losing_point_yn.Y.value && (
                                    <CompTableRow type="recommend" className="table-content">
                                      {useBonusConfig.formValue?.member_losing_point_yn !==
                                        dbSitePointConfig.member_losing_point_yn.Y.value &&
                                        useBonusConfig.formValue?.referrer_losing_point_yn ===
                                        dbSitePointConfig.referrer_losing_point_yn.Y.value ? (
                                        <TableDeps>{`${index} 레벨`}</TableDeps>
                                      ) : null}
                                      <TableDeps $height="42px">{t("bonus.referrer_losing")}</TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_casino_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_slot_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_mini_game_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                      <TableDeps $pad="0px 12px" $height="42px">
                                        <InputForm
                                          maxWidth="240px"
                                          type="number"
                                          name="recommend_sports_losing_prop"
                                          groupNm="siteLevelData"
                                          index={index}
                                          height={"28px"}
                                          placeholder="0%"
                                          tAlign={"right"}
                                          {...useBonusConfig}
                                          {...useStated}
                                        />
                                      </TableDeps>
                                    </CompTableRow>
                                  )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </Column>
                    </OptionBox>
                  </>
                )}
            </>
          )}
        </Column>

        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(4)}>
            <Row $gap="6px" $align="center">
              <AreaTitle>{"4. " + t("bonus.point_change_config")}</AreaTitle>
              <HoverCon
                onClick={(e) => {
                  e.stopPropagation(); //탭이 접히지 않게
                }}
              >
                <IconLink className="h-a-box" $url="/assets/svg/help.svg" $width="20px" $height="20px"></IconLink>
                <HoverBox className="h-box">
                  <TextBox $size="var(--s-table)" $line="var(--l-table)">
                    {t("bonus.point_change_config_msg")}
                  </TextBox>
                </HoverBox>
              </HoverCon>
            </Row>
            <Row $gap="20px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  putPointToCash();
                }}
              >
                {t("common.go_save")}
              </MainBtn>
              <IconLink
                $url="/assets/svg/site_config_menu_fold.svg"
                $width="32px"
                $height="32px"
                $bor="1px solid var(--c-gray-500)"
                $trans="rotate(180deg)"
                className={dropdownState[4] && "active"}
              ></IconLink>
            </Row>
          </SubTitleBox>

          {dropdownState[4] && (
            <>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $tAlign="center" $line="var(--l-sub)">
                    {t("bonus.point_change_config")}
                  </TextBox>
                </Row>
                <Row $align="center" $gap="12px">
                  <TextBox $white="nowrap">{t("bonus.point_change_config_text_1")}</TextBox>
                  <InputForm type="text" tAlign={"right"} name="point_to_cash_min" placeholder={"0"} {...useBonusConfig} />
                  <TextBox $white="nowrap">{t("bonus.point_change_config_text_2")}</TextBox>
                  <InputForm type="text" tAlign={"right"} name="point_to_cash_max" placeholder={"0"} {...useBonusConfig} />
                  <TextBox $white="nowrap">{t("bonus.point_change_config_text_3")}</TextBox>
                  <InputForm type="text" tAlign={"right"} name="point_to_cash_unit" placeholder={"0"} {...useBonusConfig} />
                  <TextBox $white="nowrap">{t("bonus.point_change_config_text_4")}</TextBox>
                </Row>
              </OptionBox>
              <OptionBox $align="center">
                <Row $width="160px" $minWidth="160px">
                  <TextBox $line="var(--l-sub)">{t("bonus.point_conversion_request_time")}</TextBox>
                </Row>
                <Row>
                  <ToggleButtonForm
                    name="point_to_cash_time_yn"
                    options={dbSitePointConfig.point_to_cash_time_yn}
                    {...useBonusConfig}
                  />
                </Row>
              </OptionBox>
              {useBonusConfig.formValue?.point_to_cash_time_yn === dbSitePointConfig.point_to_cash_time_yn.Y.value && (
                <OptionBox $backColor="var(--c-gray-100)" $align="center">
                  <Row $width="160px" $minWidth="160px">
                    <TextBox $line="var(--l-sub)">{t("bonus.point_conversion_request_allowed_time")}</TextBox>
                  </Row>
                  <Row $gap="24px" $align="center">
                    <Row $gap="12px" $align="center">
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-all-days"
                          name="point_to_cash_change_week"
                          index={0}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-all-days">{t("days.every_day")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-sun"
                          name="point_to_cash_change_week"
                          index={1}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-sun">{t("days.sunday")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-mon"
                          name="point_to_cash_change_week"
                          index={2}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-mon">{t("days.monday")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-tue"
                          name="point_to_cash_change_week"
                          index={3}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-tue">{t("days.tuesday")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-wen"
                          name="point_to_cash_change_week"
                          index={4}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-wen">{t("days.wednesday")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-thur"
                          name="point_to_cash_change_week"
                          index={5}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-thur">{t("days.thursday")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-fri"
                          name="point_to_cash_change_week"
                          index={6}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-fri">{t("days.friday")}</Label>
                      </Row>
                      <Row $gap="4px" $align="center">
                        <EachCheckFormCustom
                          labelFor="point-time-sat"
                          name="point_to_cash_change_week"
                          index={7}
                          options={checkboxYNEnum}
                          {...useBonusConfig}
                        />
                        <Label htmlFor="point-time-sat">{t("days.saturday")}</Label>
                      </Row>
                    </Row>
                  </Row>
                  <Row $gap="4px" $align="center">
                    <InputForm
                      width="120px"
                      type="text"
                      name="point_to_cash_start_time"
                      placeholder="23:59:59"
                      maskType="time"
                      {...useBonusConfig}
                    />
                    <TextBox>~</TextBox>
                    <InputForm
                      width="120px"
                      type="text"
                      name="point_to_cash_end_time"
                      placeholder="23:59:59"
                      maskType="time"
                      {...useBonusConfig}
                    />
                  </Row>
                </OptionBox>
              )}
            </>
          )}
        </Column>
      </Column>
    </>
  );
};

const check_options = {
  trueValue: "Y",
  falseValue: "N",
};

export default SiteBonus;
