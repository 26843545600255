import React, { useEffect, useState } from "react";
import { Column } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { SelectForm } from "./CommonForm";
import api from "interceptor/api";
import { t } from "i18next";
import i18n from "locales/i18n";
const UserLevelFilter = ({ useSearch, useStated }) => {
  const [userLevelList, setUserLevelList] = useState([]);

  useEffect(() => {
    getUserLevelList();
  }, []);

  const getUserLevelList = () => {
    const apiUrl = "/v1/site/config/user/max-levels";
    api
      .get(apiUrl, {})
      .then((res) => {
        setUserLevelList(res.data?.content && convertLevels(res.data.content));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertLevels = (levels) => {
    const convertedLevels = levels.map((item) => ({
      label: `${item.level_name} (${item.level === 0 ? t("user.waiting_join") : `LV${item.level}`})`,
      value: item.level,
      key: item.level,
    }));

    return [
      {
        label: i18n.t("common.all"),
        value: "",
        key: 0,
      },
      ...convertedLevels,
    ];
  };
  return (
    <Column $gap="4px">
      <FilterTitle>{t("user.user_lv")}</FilterTitle>
      <SelectForm name="search_user_lv" placeholder={t("common.all")} options={userLevelList} {...useSearch} {...useStated} />
    </Column>
  );
};

export default UserLevelFilter;
