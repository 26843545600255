/** @format */
import { useState } from "react";

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

// 셀렉트 벨류 constant
import { useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import { InputForm, NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, InfoTabList, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { BlockBtn, DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import UserLevelFilter from "components/common/UserLevelFilter";
import UserNameTab from "components/common/UserNameTab";
import UserState2Hover from "components/common/UserState2Hover";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import { dbUserAgentChange } from "enum/db/useUserEnum";
import { validationTypeEnum } from "enum/useCommonEnum";
import { getFormatDatetime } from "utils/date/DateUtil";
import { t } from "i18next";
import CalendarFilter from "components/common/CalendarFilter";
import UserInfo from "../info/UserInfo";
import BlockUserAgentChangeForm from "../form/BlockUserAgentChangeForm";
import BlockUserAgentChangeInfo from "../info/BlockUserAgentChangeInfo";
import UserAgentChangeForm from "../form/UserAgentChangeForm";
import UserLevelTab from "components/common/UserLevelTab";

const AgentChangeRequestList = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openAlert } = useAlert();
  const { openPopup } = usePopup();

  const showConditionFailAlert = () => {
    openAlert({
      title: t("user.user_not_select"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showUpdateStateOnlyWait = () => {
    openAlert({
      title: t("user.only_request"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showUpdateAgentPaymentStatePopupCheckBox = () => {
    const userArr = getCheckedUserArr();

    if (userArr.length < 1) {
      showConditionFailAlert();
    } else if (userArr.filter((item) => item.state === dbUserAgentChange.state.B.value).length) {
      showUpdateStateOnlyWait();
    } else {
      openPopup({
        title: t("user.agent_change_block"),
        content: BlockUserAgentChangeForm,
        props: {
          isCheckBox: true,
          userArr: userArr,
          getTableItem,
        },
      });
    }
  };

  const showBlockStatePopup = (item) => {
    if (item.state !== dbUserAgentChange.state.D.value) {
      showUpdateStateOnlyWait();
    } else {
      openPopup({
        title: t("user.agent_change_block"),
        content: BlockUserAgentChangeForm,
        props: {
          isCheckBox: false,
          userArr: [item],
          getTableItem,
        },
      });
    }
  };

  const showCompleteStatePopup = (item) => {
    if (item.state !== dbUserAgentChange.state.D.value) {
      showUpdateStateOnlyWait();
    } else {
      openPopup({
        title: t("user.agent_change_with_nickname", { nickname: item.nickname }),
        content: UserAgentChangeForm,
        props: {
          item: item,
          getTableItem,
        },
      });
    }
  };

  const showInfo = ({ no }) => {
    openPopup({
      title: t("user.agent_change_block"),
      content: BlockUserAgentChangeInfo,
      props: {
        no,
      },
    });
  };

  const getCheckedUserArr = () => {
    return useTG.data.filter((item) => item.isChecked);
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/user-agent-change/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: "uac.no-desc",
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_nickname: "",
      search_user_no: "",
      search_user_lv: "",

      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );
  const showUserInfoPopup = (userNo, nickName) => {
    openPopup({
      title: t("user.user_info_with_nickname", { nickname: nickName || "-" }),
      content: UserInfo,
      props: {
        userNo,
        getTableItem,
      },
    });
  };

  const customTabList = (item) => {
    return (
      <>
        <InfoTabList onClick={() => showUserInfoPopup(item.user_no, item.nickname)}>{t("user.user_info")}</InfoTabList>
        {item.state !== dbUserAgentChange.state.B.value && (
          <InfoTabList onClick={() => showBlockStatePopup(item)}>{t("user.agent_change_block")}</InfoTabList>
        )}
      </>
    );
  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.user_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.agent_change_request")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder="입력" {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm
                validationType={validationTypeEnum.NUMBER}
                name="search_user_no"
                placeholder={t("common.input")}
                {...useSearch}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />

            <Column $gap="4px">
              <FilterTitle>{t("user.agent_requst_at")}</FilterTitle>

              <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("user.total_user_requst_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {(useTG?.pagingInfo?.totalRecords || 0).toLocaleString()}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>
        </Row>
        <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showUpdateAgentPaymentStatePopupCheckBox()}>
              {t("user.change_block")}
            </MainBtn>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("agent.code")}</TableHeader>
                  <TableHeader>{t("agent.agent")}</TableHeader>
                  <TableHeader>{t("user.user")}</TableHeader>
                  <TableHeader>{t("user.user_lv")}</TableHeader>
                  <TableHeader>{t("user.request_at")}</TableHeader>
                  <TableHeader>{t("user.recommend_user_count")}</TableHeader>
                  <TableHeader>{t("user.recommend_user_betting")}(C)</TableHeader>
                  <TableHeader>{t("user.recommend_user_conf")}(P)</TableHeader>
                  <TableHeader>{t("common.state")}</TableHeader>
                  <TableHeader>{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>
                            <NoLabelCheckForm keys={item?.no} base={useTG} eachData={item} />
                          </TableDeps>

                          <TableDeps $width="60px">{number || "-"}</TableDeps>

                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab no={item.agent_no} code={item.code} name={item.name} type_name={item.type_name} />
                          </TableDeps>

                          <TableDeps $tAlign="left">
                            <Row $gap="6px" $align="center">
                              <UserState2Hover state2List={item.state2_arr} />
                              <UserNameTab
                                no={item.user_no}
                                nickname={item.nickname}
                                refresh={getTableItem}
                                tabList={customTabList(item)}
                              />
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <UserLevelTab item={item} />
                          </TableDeps>

                          <TableDeps>{getFormatDatetime(item?.create_at) || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{(item?.refer_user_count || 0).toLocaleString()}</TableDeps>
                          <TableDeps $tAlign="right">{(item?.recommend_user_bet_money || 0).toLocaleString()}</TableDeps>
                          <TableDeps $tAlign="right">
                            {((item?.recommend_rolling || 0) + (item?.recommend_losing || 0)).toLocaleString()}
                          </TableDeps>

                          <TableDeps>
                            <TextBox
                              className={
                                item.state === dbUserAgentChange.state.D.value
                                  ? "withdraw"
                                  : item.state === dbUserAgentChange.state.B.value
                                    ? "warn"
                                    : item.state === dbUserAgentChange.state.P.value
                                      ? "complete"
                                      : ""
                              }
                            >
                              {dbUserAgentChange.state[item?.state]?.label || "-"}
                            </TextBox>
                          </TableDeps>

                          <TableDeps>
                            <Row $width="100%" $jus="center" $gap="6px">
                              {item?.state === dbUserAgentChange.state.B.value ? (
                                <ModifyBtn name={t("user.detail_list")} eventProps={() => showInfo(item)} />
                              ) : (
                                <>
                                  <BlockBtn name={t("user.agent_change")} eventProps={() => showCompleteStatePopup(item)} />
                                  <DeleteBtn name={t("user.change_block")} eventProps={() => showBlockStatePopup(item)} />
                                </>
                              )}
                            </Row>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showUpdateAgentPaymentStatePopupCheckBox()}>
            {t("user.change_block")}
          </MainBtn>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default AgentChangeRequestList;
