import React, { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";

// style import
import { Column } from "style/custom/useCustomLayout";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import { getFormatDatetime } from "utils/date/DateUtil";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { t } from "i18next";

const BettingUserList = ({ transactionId }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const [result, setResult] = useState();

  useEffect(() => {
    setLoading(true);

    const apiUrl = `/v1/user/all/betting/info/${transactionId}`;
    api
      .get(apiUrl, {
        params: {},
      })
      .then((res) => {
        setResult(JSON.parse(res.data.content?.result_json_data));
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response?.data?.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Column $width="1600px" $height="600px">
        <>
          {result && (
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="300px">{t("betting.pick")}</TableHeader>
                  <TableHeader $width="100px">{t("betting.allocation")}</TableHeader>
                  <TableHeader $width="200px">{t("betting.bet_amount")}</TableHeader>
                  <TableHeader $width="200px">{t("betting.win_amount")}</TableHeader>
                  <TableHeader $width="200px">{t("betting.win_amount")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {result?.external?.detail?.data?.participants?.[0]?.bets.map((item, index) => (
                  <TableRow key={index} className="table-content">
                    <TableDeps $tAlign="left">{item.code}</TableDeps>
                    <TableDeps>{`X${((item.payout || 0) / (item.stake || 0)).toFixed(2)}`}</TableDeps>

                    <TableDeps $tAlign="right">{(item.stake || 0).toLocaleString()}</TableDeps>
                    <TableDeps $tAlign="right">{(item.payout || 0).toLocaleString()}</TableDeps>

                    <TableDeps>{getFormatDatetime(item?.placedOn) || "-"}</TableDeps>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          )}

          {!result && <NoDataTable />}
        </>
      </Column>
    </>
  );
};

export default BettingUserList;
