import React, { useEffect } from "react";
import axios from "axios";
import { useCommonState } from "contexts/useCommonState";

const AlarmConfigSetter = () => {
  const { setAlarmConfig } = useCommonState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_BASE_URL}/v1/common/alarm-config`;
    axios
      .get(url)
      .then((res) => {
        setAlarmConfig(res.data?.content);
      })
      .catch(() => {});
  }, [setAlarmConfig]);

  return null;
};

export default AlarmConfigSetter;
