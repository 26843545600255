import i18n from "locales/i18n";

const dbLogCommissionUpdate = {
  account_type: {
    S: {
      label: "Site",
      value: "S",
      key: "S",
    },
    G: {
      label: "Agent",
      value: "G",
      key: "G",
    },
    U: {
      label: "User",
      value: "U",
      key: "U",
    },
  },

  updated_type: {
    AGENT_COMMISSION_YN: {
      label: i18n.t("agent.is_commission_use"),
      value: "AGENT_COMMISSION_YN",
      key: "1",
    },
    AGENT_COMMISSION_TYPE: {
      label: i18n.t("agent.commission_type"),
      value: "AGENT_COMMISSION_TYPE",
      key: "2",
    },
    CASINO_ROLLING_RATE: {
      label: `${i18n.t("defaultPath.casino_rolling_rate")}%`,
      value: "CASINO_ROLLING_RATE",
      key: "3",
    },
    CASINO_LOSING_RATE: {
      label: `${i18n.t("defaultPath.casino_losing_rate")}%`,
      value: "CASINO_LOSING_RATE",
      key: "4",
    },
    SLOT_ROLLING_RATE: {
      label: `${i18n.t("defaultPath.slot_rolling_rate")}%`,
      value: "SLOT_ROLLING_RATE",
      key: "5",
    },
    SLOT_LOSING_RATE: {
      label: `${i18n.t("defaultPath.slot_losing_rate")}%`,
      value: "SLOT_LOSING_RATE",
      key: "6",
    },
    MINI_ROLLING_RATE: {
      label: `${i18n.t("defaultPath.mini_game_rolling_rate")}%`,
      value: "MINI_ROLLING_RATE",
      key: "7",
    },
    MINI_LOSING_RATE: {
      label: `${i18n.t("defaultPath.mini_game_losing_rate")}%`,
      value: "MINI_LOSING_RATE",
      key: "8",
    },
    SPORTS_ROLLING_RATE: {
      label: `${i18n.t("defaultPath.sports_rolling_rate")}%`,
      value: "SPORTS_ROLLING_RATE",
      key: "9",
    },
    SPORTS_LOSING_RATE: {
      label: `${i18n.t("defaultPath.sports_losing_rate")}%`,
      value: "SPORTS_LOSING_RATE",
      key: "10",
    },
  },
};

const dbLogInfoUpdate = {
  account_type: {
    A: {
      label: "Admin",
      value: "A",
      key: "A",
    },
    G: {
      label: "Agent",
      value: "G",
      key: "G",
    },
    U: {
      label: "User",
      value: "U",
      key: "U",
    },
  },

  updated_type: {
    PHONE: {
      label: i18n.t("common.phone"),
      value: "PHONE",
      key: "1",
    },
    ID: {
      label: i18n.t("common.id"),
      value: "ID",
      key: "2",
    },
    PASSWORD: {
      label: i18n.t("common.password"),
      value: "PASSWORD",
      key: "3",
    },
    TYPE: {
      label: i18n.t("common.type"),
      value: "TYPE",
      key: "4",
    },
    STATE: {
      label: i18n.t("common.state"),
      value: "STATE",
      key: "5",
    },
  },

  user_updated_type: {
    STATE1: {
      label: "회원상태1",
      value: "STATE1",
      key: "1",
    },
    LV: {
      label: "회원 등급",
      value: "LV",
      key: "2",
    },
    PHONE_NUMBER: {
      label: "연락처",
      value: "PHONE_NUMBER",
      key: "3",
    },
    ACCOUNT_BANK: {
      label: "은행",
      value: "ACCOUNT_BANK",
      key: "4",
    },
    ACCOUNT_NAME: {
      label: "계좌 주",
      value: "ACCOUNT_NAME",
      key: "5",
    },
    ACCOUNT_NO: {
      label: "계좌 번호",
      value: "ACCOUNT_NO",
      key: "6",
    },
    FIRST_CHARGE_POINT_YN: {
      label: "첫 충전 포인트 지급",
      value: "FIRST_CHARGE_POINT_YN",
      key: "7",
    },
    EVERY_CHARGE_POINT_YN: {
      label: "매 충전 포인트 지급",
      value: "EVERY_CHARGE_POINT_YN",
      key: "8",
    },
    MEMO: {
      label: "관리자 메모",
      value: "MEMO",
      key: "9",
    },
    STATE2: {
      label: "회원상태2",
      value: "STATE2",
      key: "10",
    },
  },
};

const dbLogLogin = {
  type: {
    A: {
      label: "Admin",
      value: "A",
      key: "A",
    },
    G: {
      label: "Agent",
      value: "G",
      key: "G",
    },
    U: {
      label: "User",
      value: "U",
      key: "U",
    },
  },
  status: {
    S: {
      label: i18n.t("common.success"),
      value: "S",
      key: "S",
    },
    F: {
      label: i18n.t("common.fail"),
      value: "F",
      key: "F",
    },
  },
};

const dbLogGameInfoUpdate = {
  updated_type: {
    U: {
      label: "사용 여부",
      value: "U",
      key: 0,
    },
    R: {
      label: "롤링",
      value: "R",
      key: 1,
    },
    L: {
      label: "루징",
      value: "L",
      key: 2,
    },
  },
};

export { dbLogCommissionUpdate, dbLogInfoUpdate, dbLogLogin, dbLogGameInfoUpdate };
