/** @format */
import React, { useEffect, useRef, useState } from "react";

// 공용
import Pagination from "utils/Pagination";
import api from "interceptor/api";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";
import CalendarFilter from "components/common/CalendarFilter";

import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import { NoLabelCheckForm, EachCheckForm, InputForm, SelectForm, SortForm, RadioForm } from "components/common/CommonForm";
// 셀렉트 벨류 constant
import { useFiveCountPerPage, userStateLv1, userStateLv2 } from "constants/select/useValue";
// 공용 팝업
// style import
import { FilterTitle, HoverTableText } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { MainRadiusBtn } from "style/custom/useCustomButton";
import { IconLink, InfoTabBox, InfoTabList, LineSection, PositionBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaHeader, Column, FilterBox, Row, RowLankAgent } from "style/custom/useCustomLayout";
import { isEmpty } from "lodash";
import NoDataTable from "components/common/NoDataTable";

import CommonCategoryTab from "../../../common/CommonCategoryTab";
import { DETAIL_PAGE_CATEGORY } from "constants/useCategory";
import AdminForm from "components/site/admin/AdminForm";
import { DeleteBtn, ModifyBtn, NormalStatus } from "components/common/TableStatus";
import { dateFormat } from "utils/date/DateUtil";
import { checkboxYNEnum } from "enum/useCommonEnum";
import useStateData from "common/useStateData";
import { dbAgentBoardQna } from "enum/db/useAgentEnum";
import { useCommonState } from "contexts/useCommonState";

const Reference = () => {
  const useStated = useStateData();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  const [userNm, setUserNm] = useState("");
  const [openInfoTab, setOpenInfoTab] = useState(false);
  const { setLoading } = useCommonState();
  const tabRef = useRef(null);

  const infoTabToggle = (Nm) => {
    setUserNm(Nm);
    setOpenInfoTab(!openInfoTab);
  };

  useEffect(() => {
    const onBlurEvent = (event) => {
      if (tabRef.current && !tabRef.current.contains(event.target)) {
        infoTabToggle();
      }
    };

    document.addEventListener("mousedown", onBlurEvent);
    return () => {
      document.removeEventListener("mousedown", onBlurEvent);
    };
  }, []);

  const showAlert = () => {
    openAlert({
      title: "관리자 계정을 삭제하시겠습니까?",
      message: "선택하신 관리자 계정을 차단하시겠습니까?선택하신 관리자 계정을 차단하시겠습니까?",
      message2: "계정은 삭제되지 않고 ip만 차단됩니다.계정은 삭제되지 않고 ip만 차단됩니다.계정은 삭제되지 않고 ip만 차단됩니다.",
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      // mainBtn: "삭제", 디폴트는 삭제 / 필요시에만 텍스트 변경해 주세요.
    });
  };

  const showConfirm = () => {
    openConfirm({
      title: "관리자 계정을 삭제하시겠습니까?",
      message: "선택하신 관리자 계정을 차단하시겠습니까?선택하신 관리자 계정을 차단하시겠습니까?",
      message2: "계정은 삭제되지 않고 ip만 차단됩니다.계정은 삭제되지 않고 ip만 차단됩니다.계정은 삭제되지 않고 ip만 차단됩니다.",
      iconURL: true,
      // subBtn: "취소", 디폴트는 취소 / 삭제 필요시에만 텍스트 변경해 주세요.
      // mainBtn: "삭제",
    });
  };

  const showPopup = (item) => {
    openPopup({
      title: "기본 정보",
      content: AdminForm,
      props: {
        item,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/user/users";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useState.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true, "5");
  // 테이블 관련 공통 - 인자값(true: 최초 조회, false: 최초 미조회)
  // 5는 countPerPage 설정옵션 설정 없을경우 디폴트로 10

  const useSearch = useFormData(
    {
      email: "",
      check: "",
      check2: "",
      user_state1: -1, // 없음 값이 -1이면 최초 값을 -1로 처리해야 함
      user_state2: "",
      date_period: "",
      start_date: "",
      end_date: "",
      groupA: "",
    },
    getTableItem, // 조회 메소드 매핑
    useTG // 페이지 기능 매핑
  );
  // formValue에 먼저 담고 검색 버튼 누를 시 searchValue에 담김

  return (
    <>
      <AreaHeader>
        <SubText>회원 관리</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>전체 회원</AreaTitle>
        <Row $gap="20px">
          <button onClick={showAlert}>alert</button>
          <button onClick={showConfirm}>confirm</button>
          <MainRadiusBtn onClick={showPopup}>상세</MainRadiusBtn>
        </Row>
      </AreaHeader>

      <CommonCategoryTab menus={DETAIL_PAGE_CATEGORY} />

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <Column $gap="4px">
              <FilterTitle>소속 에이전트</FilterTitle>
              <Row $gap="4px">
                <SelectForm name="user_state1" placeholder={"전체 등급"} options={userStateLv1} {...useSearch} {...useStated} />
                <SelectForm
                  name="user_state1"
                  placeholder={"전체 에이전트"}
                  options={userStateLv1}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>

            <Column $gap="4px">
              <FilterTitle>회원 상태</FilterTitle>
              <SelectForm name="user_state2" placeholder={"전체"} options={userStateLv2} {...useSearch} {...useStated} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>필터 이름</FilterTitle>
              <Row $align="center" $gap="12px" $height="34px">
                <EachCheckForm name="check" labelFor="check" options={checkboxYNEnum} labelName={"출금 체크"} {...useSearch} />
                <EachCheckForm name="check2" labelFor="check2" options={checkboxYNEnum} labelName={"입금 체크"} {...useSearch} />
              </Row>
            </Column>

            <Column $gap="4px">
              <FilterTitle>입금 여부</FilterTitle>
              <Row $gap="4px">
                <SelectForm name="user_state2" placeholder={"미선택"} options={userStateLv2} {...useSearch} {...useStated} />
                <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
              </Row>
            </Column>

            <Column $gap="4px">
              <FilterTitle>직접 입력</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="user_state2"
                  placeholder={"아이디"}
                  options={userStateLv2}
                  {...useSearch}
                  {...useStated}
                  success
                  successMsg={"성공"}
                />

                <InputForm type="text" name="email" placeholder="입력" {...useSearch} disabled={"disabled"} />
              </Row>
            </Column>

            <Column $gap="4px">
              <FilterTitle>라디오 필터</FilterTitle>
              <Row $align="center" $gap="12px" $height="34px">
                <RadioForm name="groupA" options={dbAgentBoardQna.state} {...useSearch} />
              </Row>
            </Column>
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              검색
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              초기화
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">총</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                9,999
              </TextBox>
              <TextBox $font="var(--f-subB)">건</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-partner" $gap="4px">
              <TextBox $font="var(--f-subB)">총 파트너 수</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                9,999
              </TextBox>
              <TextBox $font="var(--f-subB)">명</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">파트너 보유 머니(C)</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                9,999
              </TextBox>
              <TextBox $font="var(--f-subB)">건</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-point" $gap="4px">
              <TextBox $font="var(--f-subB)">총</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                9,999
              </TextBox>
              <TextBox $font="var(--f-subB)">건</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)">
              회원 등록
            </MainBtn>
            <SelectForm
              name="countPerPage"
              placeholder={useFiveCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useFiveCountPerPage}
              formValue={useFiveCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader $width="60px">
                    <Row $gap="2px" $jus="center" $align="center">
                      <TextBox>No.</TextBox>
                      <SortForm colNm="no" sortNm={useTG.extendData.sortNm} onSortClick={useTG.onSortClick} />
                    </Row>
                  </TableHeader>
                  <TableHeader $width="130px">에이전트</TableHeader>
                  <TableHeader $width="130px">가입일</TableHeader>
                  <TableHeader $width="130px">아이디</TableHeader>
                  <TableHeader $width="130px">이름</TableHeader>
                  <TableHeader $width="130px">회원등급</TableHeader>
                  <TableHeader $width="130px">가입코드</TableHeader>
                  <TableHeader $width="130px">보유머니</TableHeader>
                  <TableHeader $width="130px">보유포인트</TableHeader>
                  <TableHeader $width="130px">누적입금</TableHeader>
                  <TableHeader $width="130px">누적출금</TableHeader>
                  <TableHeader $width="130px">최종접속일</TableHeader>
                  <TableHeader $width="130px">회원상태</TableHeader>
                  <TableHeader $width="130px">관리</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>
                            <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                          </TableDeps>
                          <TableDeps $width="80px">{number || "-"}</TableDeps>
                          <TableDeps $width="130px">
                            <RowLankAgent
                              $pad={
                                item.agent_no === 2
                                  ? "0px"
                                  : item.agent_no < 5
                                  ? `0 0 0 ${(item.agent_no - 1) * 12}px`
                                  : "0 0 0 60px" // 최대 5까지만 증가하도록 설정
                              }
                            >
                              {item.agent_no !== 2 && (
                                <IconLink $url="/assets/svg/rowlank_agent.svg" $width="16px" $height="16px"></IconLink>
                              )}
                              <TextBox $size="var(--s-table)" $line="var(--l-table)">
                                {item.agent_no || "-"}
                              </TextBox>
                            </RowLankAgent>
                          </TableDeps>
                          <TableDeps>{dateFormat(item.create_at)}</TableDeps>
                          <TableDeps onClick={() => infoTabToggle(item.no)}>
                            <HoverTableText>
                              {item?.nickname?.length > 20 ? `${item.nickname.slice(0, 20)}...` : item.nickname || "-"}
                              {openInfoTab && item.no === userNm && (
                                <InfoTabBox ref={tabRef}>
                                  <InfoTabList>에이전트 정보</InfoTabList>
                                  <InfoTabList>정산 내역</InfoTabList>
                                  <InfoTabList>회원 목록</InfoTabList>
                                  <InfoTabList>문의 내역</InfoTabList>
                                </InfoTabBox>
                              )}
                            </HoverTableText>
                          </TableDeps>
                          <TableDeps>{item.createAt || "-"}</TableDeps>
                          <TableDeps>{item.lv || "-"}</TableDeps>
                          <TableDeps>{item.user_state2 || "-"}</TableDeps>
                          <TableDeps>{item.money?.toLocaleString() || "-"}</TableDeps>
                          <TableDeps>{item.point?.toLocaleString() || "-"}</TableDeps>
                          <TableDeps>{item.balancePoint?.toLocaleString() || "-"}</TableDeps>
                          <TableDeps>{item.totalProfit?.toLocaleString() || "-"}</TableDeps>
                          <TableDeps>{dateFormat(item.last_login_at)}</TableDeps>
                          <TableDeps>
                            <Row $width="100%" $jus="center">
                              <NormalStatus />
                            </Row>
                          </TableDeps>
                          <TableDeps>
                            <Row $width="100%" $jus="center" $gap="6px">
                              {/* 클릭이벤트 보내기 예시 */}
                              <ModifyBtn eventProps={() => console.log("sss")} />
                              <DeleteBtn />
                            </Row>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <WhiteBtn $size="var(--s-table)" $line="var(--l-table)">
              버튼 미정
            </WhiteBtn>
            <WhiteBtn $size="var(--s-table)" $line="var(--l-table)">
              버튼 미정
            </WhiteBtn>
            <WhiteBtn $size="var(--s-table)" $line="var(--l-table)">
              버튼 미정
            </WhiteBtn>
          </Row>

          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)">
              회원 등록
            </MainBtn>
            <SelectForm
              top="top"
              name="countPerPage"
              placeholder={useFiveCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useFiveCountPerPage}
              formValue={useFiveCountPerPage.value}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

export default Reference;
