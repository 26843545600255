import { useState } from "react";

function useStateData() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);

  function setErrorData(errors, isInit = false) {
    if (isInit) {
      setSuccesses([]);
      setErrors(errors);
    } else {
      setSuccesses((prev) => {
        const newSuccesses = [...prev];

        for (let i = errors.length - 1; i >= 0; i--) {
          let index = newSuccesses.findIndex((item) => item.path === errors[i].path);
          if (index >= 0) {
            newSuccesses.splice(index, 1);
          }
        }

        return newSuccesses;
      });

      setErrors((prev) => {
        const newErrors = [...prev];

        for (let i = 0; i < errors.length; i++) {
          let index = newErrors.findIndex((item) => item.path === errors[i].path);
          if (index >= 0) {
            newErrors[index] = errors[i];
          } else {
            newErrors.push(errors[i]);
          }
        }

        return newErrors;
      });
    }
  }

  function setSuccessData(successes, isInit = false) {
    if (isInit) {
      setErrors([]);
      setSuccesses(successes);
    } else {
      setSuccesses((prev) => {
        const newSuccesses = [...prev];

        for (let i = 0; i < successes.length; i++) {
          let index = newSuccesses.findIndex((item) => item.path === successes[i].path);
          if (index >= 0) {
            newSuccesses[index] = successes[i];
          } else {
            newSuccesses.push(successes[i]);
          }
        }

        return newSuccesses;
      });

      setErrors((prev) => {
        const newErrors = [...prev];

        for (let i = successes.length - 1; i >= 0; i--) {
          let index = newErrors.findIndex((item) => item.path === successes[i].path);
          if (index >= 0) {
            newErrors.splice(index, 1);
          }
        }

        return newErrors;
      });
    }
  }

  return {
    loading,
    setLoading,

    errors,
    setErrorData,

    successes,
    setSuccessData,
  };
}

export default useStateData;
