import React, { useEffect } from "react";
import axios from "axios";
import { useCommonState } from "contexts/useCommonState";

const SiteNameSetter = () => {
  const { setSiteName } = useCommonState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_BASE_URL}/v1/common/site-name`;
    axios
      .get(url)
      .then((res) => {
        setSiteName(res.data?.content);
        document.title = `${res.data.content || "game"} (${process.env.REACT_APP_TITLE})`;
      })
      .catch(() => {
        document.title = `game (${process.env.REACT_APP_TITLE})`;
      });
  }, [setSiteName]);

  return null;
};

export default SiteNameSetter;
