import { initReactI18next } from "react-i18next";
import Korean from "locales/ko/translator.json";
import English from "locales/en/translator.json";
import i18n from "i18next";

// 다국어 지원을 초기화하고 번역 리소스를 설정합니다.
i18n.use(initReactI18next).init({
  resources: {
    ko: { translation: Korean },
    en: { translation: English },
  },
  lng: "ko", // 기본 설정 언어
  fallbackLng: "en", // 번역 파일에서 찾을 수 없는 경우 기본 언어
  interpolation: {
    escapeValue: false, // React 컴포넌트 포함 여부
  },
  // 에러 발생 시 콜백 함수 설정
  react: {
    bindI18n: "languageChanged",
    bindStore: false,
    nsMode: "default",
  },
});

// 저장된 언어 설정을 불러와서 언어를 변경합니다.
const storedLanguage = localStorage.getItem("language");
if (storedLanguage) {
  i18n.changeLanguage(storedLanguage);
}

// 언어 변경 시 로컬 스토리지에 언어 설정을 저장합니다.
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

// 국가 코드에 따라 언어를 변경하는 함수
export function changeLanguage(language) {
  i18n.changeLanguage(language);
  window.location.reload();
}

export default i18n;
