import useFormData from "common/useFormData";
import { useAlert, usePopup } from "contexts/useWindow";
import api from "interceptor/api";
import { useEffect } from "react";

import useStateData from "common/useStateData";
import CalendarFilter from "components/common/CalendarFilter";
import { ImageForm, InputForm, RadioForm, ToggleButtonForm, TextareaEditorForm } from "components/common/CommonForm";
import { dbSitePopupMng } from "enum/db/useSiteEnum";
import { t } from "i18next";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import styled from "styled-components";
import { getFormatDatetime } from "utils/date/DateUtil";

const DisabledWrapper = styled.div`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

const SiteOperationalForm = ({ item, getTableItem }) => {
  const useStated = useStateData();
  const isModify = !!item;

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    if (isModify) {
      getPopupInfo();
    } else {
      resetFormValues();
    }
  }, [item]);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showErrorAlert(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showErrorAlert = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showFormAlert = (isSuccess) => {
    if (isModify) {
      openAlert({
        title: isSuccess ? t("popup.popup_modify_complete") : t("popup.popup_modify_fail"),
        message: isSuccess ? t("popup.popup_modify_complete_msg") : t("popup.popup_modify_fail_msg"),
        iconURL: isSuccess ? false : true,
      });
    } else {
      openAlert({
        title: isSuccess ? t("popup.popup_save_complete") : t("popup.popup_save_fail"),
        message: isSuccess ? t("popup.popup_save_complete_msg") : t("popup.popup_save_fail_lack_bd"),
        iconURL: isSuccess ? false : true,
      });
    }
  };

  const getPopupInfo = () => {
    const apiUrl = `/v1/site/operational/popup/1/${item}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,
          site_popup_mng: {},
          publish_start: getFormatDatetime(res.data.content.publish_start),
          publish_end: getFormatDatetime(res.data.content.publish_end),
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const clickSubmitBtn = () => {
    let apiUrl = isModify ? `/v1/site/operational/popup/1/${item}` : "/v1/site/operational/popup/1";
    let apiType = isModify ? api.put : api.post;

    apiType(apiUrl, {
      ...useForm.formValue,
    })
      .then(() => {
        showFormAlert(true);
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showFormAlert(false);
      });
  };

  const useForm = useFormData({
    popup_expo_yn: "N",
    title: "",
    contents: "",
    img_uuid: "",
    site_popup_mng: {},
    // width: "",
    // height: "",
    location_type: "C",
    top: "",
    left: "",
    pop_link: "",
    state: "O",
    publish_start: "",
    publish_end: "",
  });

  const resetFormValues = () => {
    useForm.setFormValue({
      popup_expo_yn: "N",
      title: "",
      contents: "",
      img_uuid: "",
      site_popup_mng: {},
      // width: "",
      // height: "",
      location_type: "C",
      top: "",
      left: "",
      pop_link: "",
      state: "O",
      publish_start: "",
      publish_end: "",
    });
  };

  return (
    <>
      <Column className="area-box" $gap="20px" $width="800px">
        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.popup_expo_yn")}:
          </TextBox>
          <ToggleButtonForm name="popup_expo_yn" options={dbSitePopupMng.popup_expo_yn} {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.popup_title")}:
          </TextBox>
          <InputForm width="100%" maxWidth="100%" type="text" name="title" {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.popup_contents")}:
          </TextBox>
          {/* <InputForm width="100%" maxWidth="100%" type="text" name="contents" {...useForm} {...useStated} /> */}
          <TextareaEditorForm type="text" name="contents" placeholder={t("user.admin_memo_msg")} {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start" $pad="36px 0px 0px 0px">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.img_select")}
          </TextBox>
          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <ImageForm name="img_uuid" groupNm="site_popup_mng" width="28px" height="28px" {...useForm} {...useStated} />
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              {t("popup.img_msg")}
            </TextBox>
          </Column>
        </FormBox>

        {/* <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            팝업 넓이(px):
          </TextBox>
          <InputForm width="100%" maxWidth="100%" type="text" name="width" {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            팝업 높이(px):
          </TextBox>
          <InputForm width="100%" maxWidth="100%" type="text" name="height" {...useForm} {...useStated} />
        </FormBox> */}

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.popup_location_type")}:
          </TextBox>
          <Column $gap="12px" $width="100%">
            <RadioForm name='location_type' options={dbSitePopupMng.location_type} {...useForm} {...useStated} />
            <DisabledWrapper disabled={useForm.formValue.location_type !== "F"}>
              <FormBox $align="flex-start">
                <TextBox $width="135px" $minWidth="135px">
                  {t("popup.popup_top")}:
                </TextBox>
                <InputForm width="100%" maxWidth="100%" type="text" name="top" {...useForm} {...useStated} disabled={useForm.formValue.location_type !== "F"} />
              </FormBox>
            </DisabledWrapper>

            <DisabledWrapper disabled={useForm.formValue.location_type !== "F"}>
              <FormBox $align="flex-start">
                <TextBox $width="135px" $minWidth="135px">
                  {t("popup.popup_left")}:
                </TextBox>
                <InputForm width="100%" maxWidth="100%" type="text" name="left" {...useForm} {...useStated} disabled={useForm.formValue.location_type !== "F"} />
              </FormBox>
            </DisabledWrapper>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.popup_link")}:
          </TextBox>
          <InputForm width="100%" maxWidth="100%" type="text" name="pop_link" {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("popup.popup_display_status")}:
          </TextBox>
          <Column $gap="12px" $width="100%">
            <RadioForm name="state" options={dbSitePopupMng.state} {...useForm}  {...useStated} />
            <DisabledWrapper disabled={useForm.formValue.state !== "A"}>
              <FormBox $align="flex-start">
                <TextBox $width="135px" $minWidth="135px">
                  {t("popup.publish_start")}:
                </TextBox>
                <CalendarFilter startName="publish_start" top {...useForm} {...useStated} />
              </FormBox>
            </DisabledWrapper>

            <DisabledWrapper disabled={useForm.formValue.state !== "A"}>
              <FormBox $align="flex-start">
                <TextBox $width="135px" $minWidth="135px">
                  {t("popup.publish_end")}:
                </TextBox>
                <CalendarFilter startName="publish_end" top {...useForm} {...useStated} />
              </FormBox>
            </DisabledWrapper>
          </Column>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={clickSubmitBtn}>
              {isModify ? t("common.save") : t("common.add")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default SiteOperationalForm;
