import axios from "axios";
import { t } from "i18next";
import { SwalAlertCallBack } from "utils/SwalAlert"
import { TokenSubstitution, isTokenValid } from "utils/FormUtils";

// 요청 큐
let pendingRequests = [];
// 요청 카운팅
let requestCnt = 0;
// 정상 카운팅
let successCnt = 0;
// 오류(401) 카운팅
let errorCnt = 0;

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use((config) => {
  requestCnt++;

  const accessToken = localStorage.getItem("access-token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`; 
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    successCnt++;
    errorCnt > 0 && requestCnt === (successCnt + errorCnt) && RefreshToken();

    return response
  },
  (error) => {
    const { response } = error; 
    // token 인증 만료인 경우
    if (response.status === 401) {
      errorCnt++;
      requestCnt === (successCnt + errorCnt) && RefreshToken();

      // 요청 대기 및 요청 큐 추가
      return new Promise((resolve) => {
        pendingRequests.push(() => {
          resolve(api(error.config)); 
        });
      });
    }
    // 권한이 부족한 경우
    if (response.status === 403) {
      localStorage.clear();
      return window.location.href = `${window.location.origin}/`
    }
    // 중복 로그인
    if (response.status === 409) { 
      localStorage.clear();
      return SwalAlertCallBack('error', t("myMenu.logout"), `<p>${t("alert.duplicate_login")}</p>`, () => {
        window.location.href = `${window.location.origin}/`
      });
    }

    return Promise.reject(error);
  }
);
/**
 * 토큰 갱신
 */
const RefreshToken = async () => {
  // 요청 및 결과 갯수 초기화
  requestCnt = 0;
  successCnt = 0;
  errorCnt = 0;
  
  const accessToken = localStorage.getItem("access-token");
  const { exp } = TokenSubstitution(accessToken);
  // 특이 케이스로 /refresh-token 를 두번 호출하는 것을 막고자 JWT 유효성 검사
  if (!isTokenValid(exp)) {
    // 토큰 재발급 요청
    const RefreshToken = localStorage.getItem("refresh-token");
    try {
      const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/auth/refresh-token`, {}, {
        headers: {
          'Authorization': `Bearer ${RefreshToken}`
        }
      });
      // 토큰 갱신
      const { accessToken, refreshToken } = result.data.content;
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      localStorage.setItem("access-token", accessToken);
      localStorage.setItem("refresh-token", refreshToken);
      // 이전 API 재요청
      pendingRequests.forEach((callback) => callback());
    } catch (error) {
      console.error(error)
      localStorage.clear();
      window.location.href = `${window.location.origin}/`
    }
  } else {
    // 현재 JWT가 정상적이라면 저장된 큐만 호출
    pendingRequests.forEach((callback) => callback());
  }
}

export default api;
