const checkboxYNEnum = {
  Y: {
    label: "Y",
    value: "Y",
    key: "Y",
  },
  N: {
    label: "N",
    value: "N",
    key: "N",
  },
};

const errorFlagEnum = {
  LOGOUT: "logout",
};

const infoTabEnum = {
  tabType: {
    u: {
      label: "user",
      value: "u",
      key: "u",
    },
    a: {
      label: "agent",
      value: "a",
      key: "a",
    },
  },
};

const betEnum = {
  type: {
    win: {
      label: "당첨금 지급",
      value: "win",
      key: "win",
    },
    bet: {
      label: "유저 베팅",
      value: "bet",
      key: "bet",
    },
  },
  bet_status: {
    S: {
      label: "success",
      value: "S",
      key: "S",
    },
    C: {
      label: "cancel",
      value: "C",
      key: "C",
    },
  },
};

const gameEnum = {
  type: {
    S: {
      label: "slot",
      value: "S",
      key: "S",
    },
    C: {
      label: "casino",
      value: "C",
      key: "C",
    },
    M: {
      label: "mini game",
      value: "M",
      key: "M",
    },
    P: {
      label: "sports",
      value: "P",
      key: "P",
    },
  },
};

const validationTypeEnum = {
  ENGLISH: "english",
  NUMBER: "number",
  ENGLISH_AND_NUMBER: "englishAndNumber",
  DECIMAL: "decimal",
};

export { checkboxYNEnum, infoTabEnum, errorFlagEnum, validationTypeEnum, betEnum, gameEnum };
