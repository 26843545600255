import useFormData from "common/useFormData";
import { TextareaForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { useAlert, usePopup } from "contexts/useWindow";
import useStateData from "common/useStateData";
import { dbAgentBoardQna } from "enum/db/useAgentEnum";
import { useCommonState } from "contexts/useCommonState";
import { t } from "i18next";

const AgentBoardQnaInfo = ({ no, getTableItem }) => {
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { isAdmin } = useCommonState();

  useEffect(() => {
    useStated.setErrorData([], true);
    getQnaInfo();
  }, [no]);

  const getQnaInfo = () => {
    const apiUrl = `/v1/agent/qna/info/${no}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue(res.data.content);
      })
      .catch((err) => {});
  };

  const regisAnswer = () => {
    const apiUrl = `/v1/agent/qna/${no}`;
    api
      .put(apiUrl, {
        ...useForm.formValue,
        no,
      })
      .then(() => {
        showFormSuccessAlert();
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const showFormSuccessAlert = () => {
    openAlert({
      title: t("common.save_msg"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showFormFailAlert = () => {
    openAlert({
      title: t("common.save_fail"),
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const isAnswered = () => {
    return useForm.formValue.state === dbAgentBoardQna.state.C.value;
  };
  const useForm = useFormData({
    question_title: "",
    question_contents: "",
    answer_contents: "",
  });

  return (
    <>
      <Column className="area-box" $gap="20px" $width="650px">
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("agentQna.title")}
          </TextBox>
          <TextBox>{useForm.formValue.question_title}</TextBox>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="0px" $minWidth="120px">
            {t("agentQna.contents")} 
          </TextBox>
          <Column $width="100%" $gap="4px">
            <TextareaForm
              height="250px"
              width="500px"
              type="text"
              name="question_contents"
              disabled={true}
              {...useForm}
              {...useStated}
            />
          </Column>
        </FormBox>

        {isAdmin || isAnswered() ? (
          <FormBox $align="flex-start">
            <TextBox $width="120px" $minWidth="120px">
              {t("agentQna.answer_contents")} 
            </TextBox>
            <Column $width="100%" $gap="4px">
              <TextareaForm
                height="250px"
                width="500px"
                type="text"
                name="answer_contents"
                placeholder={isAdmin ? t("common.enter_answer_plh") : ""}
                disabled={!isAdmin || isAnswered()}
                {...useForm}
                {...useStated}
                maxLength="255"
              />
            </Column>
          </FormBox>
        ) : null}

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            {isAdmin && !isAnswered() ? (
              <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={regisAnswer}>
                {t("common.save")}
              </MainBtn>
            ) : null}
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentBoardQnaInfo;
