import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";

import { TextBox } from "style/useTextStyles";
import { IconLink } from "style/useLayoutStyles";
import { Row } from "style/custom/useCustomLayout";
import { InputForm, SelectForm } from "components/common/CommonForm";

import Calendar from "./Calendar";
import { getData } from "utils/FormUtils";
import { t } from "i18next";
import i18n from "locales/i18n";

const CalendarBox = styled.li`
  gap: 6px;
  height: 34px;
  display: flex;
  align-items: center;
  position: relative;
  width: 120px;

  background-color: var(--c-white);

  border: 1px solid var(--c-gray-300);
  &:focus-within {
    border: 1px solid var(--c-mint-600);
  }
`;

const CalIconBox = styled.button`
  background-color: var(--c-white);
  min-width: 34px;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--c-gray-300);
  border-left: none;
`;

const CalendarFilter = ({ ...useSearch }) => {
  const [startDate, setStartDate] = useState(useSearch?.formValue[useSearch?.startName] || "");
  const [endDate, setEndDate] = useState("");
  const [dateType, setDateType] = useState();
  const [calendarToggle, setCalenderToggle] = useState(false);

  const calendarRef = useRef(null);

  const toggleOpen = (name) => {
    const sDate = getData(useSearch.formValue, useSearch.startName || "start_date", useSearch.index, useSearch.groupNm);
    setStartDate(sDate);

    const eDate = getData(useSearch.formValue, useSearch.endName || "end_date", useSearch.index, useSearch.groupNm);
    setEndDate(eDate);

    setDateType(name);
    setCalenderToggle(!calendarToggle);
  };

  useEffect(() => {
    useSearch.onChange(useSearch.startName || "start_date", startDate, useSearch.index, useSearch.groupNm);
    useSearch.onChange(useSearch.endName || "end_date", endDate, useSearch.index, useSearch.groupNm);

    //  기간에 맞춰 필터값 변경
    if (isDateFilterActive("today")) {
      useSearch.onChange(useSearch.date_period || "date_period", "today", useSearch.index, useSearch.groupNm);
    } else if (isDateFilterActive("yesterday")) {
      useSearch.onChange(useSearch.date_period || "date_period", "yesterday", useSearch.index, useSearch.groupNm);
    } else if (isDateFilterActive("lastweek")) {
      useSearch.onChange(useSearch.date_period || "date_period", "lastweek", useSearch.index, useSearch.groupNm);
    } else if (isDateFilterActive("lastmonth")) {
      useSearch.onChange(useSearch.date_period || "date_period", "lastmonth", useSearch.index, useSearch.groupNm);
    } else if (isDateFilterActive("thisweek")) {
      useSearch.onChange(useSearch.date_period || "date_period", "thisweek", useSearch.index, useSearch.groupNm);
    } else if (isDateFilterActive("thismonth")) {
      useSearch.onChange(useSearch.date_period || "date_period", "thismonth", useSearch.index, useSearch.groupNm);
    } else if (isDateFilterActive("last6months")) {
      useSearch.onChange(useSearch.date_period || "date_period", "last6months", useSearch.index, useSearch.groupNm);
    } else {
      useSearch.onChange(useSearch.date_period || "date_period", "", useSearch.index, useSearch.groupNm);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setCalenderToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isDateFilterActive = (filter) => {
    const currentDate = new Date();
    const formatCurrentDate = format(currentDate, "yyyy-MM-dd");

    switch (filter) {
      case "today":
        return startDate === formatCurrentDate && endDate === formatCurrentDate;

      case "yesterday":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const formatYesterday = format(yesterday, "yyyy-MM-dd");

        return startDate === formatYesterday && endDate === formatYesterday;

      case "lastweek":
        const lastWeekEnd = new Date();
        const lastWeekStart = new Date(lastWeekEnd);

        const dayOfWeek = lastWeekStart.getDay();
        const diff = lastWeekStart.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        lastWeekStart.setDate(diff - 7);
        lastWeekEnd.setDate(diff - 1);

        const formatLastWeekStart = format(lastWeekStart, "yyyy-MM-dd");
        const formatLastWeekEnd = format(lastWeekEnd, "yyyy-MM-dd");

        return startDate === formatLastWeekStart && endDate === formatLastWeekEnd;

      case "lastmonth":
        const lastMonthStart = new Date();
        lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
        lastMonthStart.setDate(1);

        const formatLastMonthStart = format(lastMonthStart, "yyyy-MM-dd");
        const lastMonthEnd = new Date();

        lastMonthEnd.setDate(0);
        const formatLastMonthEnd = format(lastMonthEnd, "yyyy-MM-dd");

        return startDate === formatLastMonthStart && endDate === formatLastMonthEnd;

      case "thisweek":
        const dayOfThisWeek = currentDate.getDay();
        const diffToMonday = dayOfThisWeek === 0 ? -6 : 1 - dayOfThisWeek;

        const thisWeekStart = new Date(currentDate);
        thisWeekStart.setDate(currentDate.getDate() + diffToMonday);

        const thisWeekEnd = new Date(thisWeekStart);
        thisWeekEnd.setDate(thisWeekStart.getDate() + 6);

        const formatThisWeekStart = format(thisWeekStart, "yyyy-MM-dd");
        const formatThisWeekEnd = format(thisWeekEnd, "yyyy-MM-dd");

        return startDate === formatThisWeekStart && endDate === formatThisWeekEnd;

      case "thismonth":
        const thisMonthStart = new Date(currentDate);
        thisMonthStart.setDate(1);

        const thisMonthEnd = new Date(currentDate);
        thisMonthEnd.setMonth(thisMonthEnd.getMonth() + 1);
        thisMonthEnd.setDate(0);

        const formatThisMonthStart = format(thisMonthStart, "yyyy-MM-dd");
        const formatThisMonthEnd = format(thisMonthEnd, "yyyy-MM-dd");

        return startDate === formatThisMonthStart && endDate === formatThisMonthEnd;

      case "last6months":
        const last6MonthsStart = new Date(currentDate);
        last6MonthsStart.setMonth(last6MonthsStart.getMonth() - 5);
        last6MonthsStart.setDate(1);

        const last6MonthsEnd = new Date(currentDate);
        last6MonthsEnd.setMonth(last6MonthsEnd.getMonth() + 1);
        last6MonthsEnd.setDate(0);

        const formatLast6MonthsStart = format(last6MonthsStart, "yyyy-MM-dd");
        const formatLast6MonthsEnd = format(last6MonthsEnd, "yyyy-MM-dd");

        return startDate === formatLast6MonthsStart && endDate === formatLast6MonthsEnd;

      default:
        return false;
    }
  };

  const dateFilter = (dataName, filter) => {
    const currentDate = new Date();
    let newStartDate = null;
    let newEndDate = null;

    switch (filter) {
      case "today":
        newStartDate = currentDate;
        newEndDate = currentDate;
        break;

      case "yesterday":
        newStartDate = new Date(currentDate);
        newEndDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() - 1);
        newEndDate.setDate(currentDate.getDate() - 1);
        break;

      case "lastweek":
        newEndDate = new Date();
        newStartDate = new Date(newEndDate);
        const dayOfWeek = newStartDate.getDay();
        const diff = newStartDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        newStartDate.setDate(diff - 7);
        newEndDate.setDate(diff - 1);
        break;

      case "lastmonth":
        newEndDate = new Date();
        newStartDate = new Date();
        newStartDate.setMonth(newEndDate.getMonth() - 1);
        newStartDate.setDate(1);
        newEndDate.setDate(0);
        break;

      case "thisweek":
        const dayOfThisWeek = currentDate.getDay();
        const diffToMonday = dayOfThisWeek === 0 ? -6 : 1 - dayOfThisWeek;

        newStartDate = new Date(currentDate);
        newStartDate.setDate(currentDate.getDate() + diffToMonday);
        newEndDate = new Date(newStartDate);
        newEndDate.setDate(newStartDate.getDate() + 6);
        break;

      case "thismonth":
        newEndDate = new Date();
        newStartDate = new Date();
        newStartDate.setDate(1);
        newEndDate.setMonth(newEndDate.getMonth() + 1);
        newEndDate.setDate(0);
        break;

      case "last6months":
        newEndDate = new Date();
        newStartDate = new Date();
        newStartDate.setMonth(newEndDate.getMonth() - 5);
        newStartDate.setDate(1);
        newEndDate.setMonth(newEndDate.getMonth() + 1);
        newEndDate.setDate(0);
        break;

      default:
        setStartDate("");
        setEndDate("");
        break;
    }

    if (newEndDate && typeof newEndDate !== "string") {
      newEndDate = format(newEndDate, "yyyy-MM-dd");
    }

    if (newStartDate && typeof newStartDate !== "string") {
      newStartDate = format(newStartDate, "yyyy-MM-dd");
    }

    useSearch.onChange(useSearch.startName || "start_date", newStartDate, useSearch.index, useSearch.groupNm);
    if (useSearch.end) {
      useSearch.onChange(dataName, filter, useSearch.index, useSearch.groupNm);
      useSearch.onChange(useSearch.endName || "end_date", newEndDate, useSearch.index, useSearch.groupNm);
    }

    setCalenderToggle(false);
  };

  return (
    <Row $gap='4px'>
      {useSearch.end && (
        <SelectForm
          placeholder={t("date.period")}
          options={useSearch.formValue.period === "M" ? monthly_datatype_options : useSearch.formValue.period === "D" ? daily_datatype_options : datatype_options}
          {...useSearch}
          onChange={(name, filter) => {
            dateFilter(name, filter);
            isDateFilterActive(filter);
          }}
          name={useSearch.date_period || "date_period"}
        />
      )}

      <Row $gap='8px' $width='100%' $align='center' ref={calendarRef}>
        <Row $align='center'>
          <CalendarBox>
            <InputForm
              type='text'
              padding='0'
              height={"32px"}
              maskType='date'
              placeholder='YYYY-MM-DD'
              border={"0px solid #fff !important"}
              {...useSearch}
              name={useSearch.startName || "start_date"}
            />
            {calendarToggle && dateType === "start" && (
              <Calendar
                top={useSearch.top}
                endDate={endDate}
                dateType={dateType}
                startDate={startDate}
                onToggle={setCalenderToggle}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
              />
            )}
          </CalendarBox>
          <CalIconBox onClick={() => toggleOpen("start")}>
            <IconLink $url='/assets/svg/calendar.svg' $width='16px' $minWidth='16px' $height='16px'></IconLink>
          </CalIconBox>
        </Row>
        {useSearch.end && (
          <>
            <TextBox $line='var(--l-text)'>~</TextBox>
            <Row $align='center'>
              <CalendarBox>
                <InputForm
                  type='text'
                  padding='0'
                  maskType='date'
                  height={"32px"}
                  placeholder='YYYY-MM-DD'
                  border={"0px solid #fff !important"}
                  {...useSearch}
                  name={useSearch.endName || "end_date"}
                />
                {calendarToggle && dateType === "end" && (
                  <Calendar
                    top={useSearch.top}
                    endDate={endDate}
                    dateType={dateType}
                    startDate={startDate}
                    onToggle={setCalenderToggle}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                  />
                )}
              </CalendarBox>

              <CalIconBox onClick={() => toggleOpen("end")}>
                <IconLink $url='/assets/svg/calendar.svg' $width='16px' $minWidth='16px' $height='16px'></IconLink>
              </CalIconBox>
            </Row>
          </>
        )}
      </Row>
    </Row>
  );
};

const datatype_options = {
  today: {
    label: i18n.t("date.today"),
    value: "today",
    key: "today",
  },
  yesterday: {
    label: i18n.t("date.yesterday"),
    value: "yesterday",
    key: "yesterday",
  },
  lastweek: {
    label: i18n.t("date.lastweek"),
    value: "lastweek",
    key: "lastweek",
  },
  lastmonth: {
    label: i18n.t("date.lastmonth"),
    value: "lastmonth",
    key: "lastmonth",
  },
};

const daily_datatype_options = {
  yesterday: {
    label: i18n.t("date.yesterday"),
    value: "yesterday",
    key: "yesterday",
  },
  thisweek: {
    label: i18n.t("date.thisweek"),
    value: "thisweek",
    key: "thisweek",
  },
};

const monthly_datatype_options = {
  thismonth: {
    label: i18n.t("date.thismonth"),
    value: "thismonth",
    key: "thismonth",
  },
  lastmonth: {
    label: i18n.t("date.lastmonth"),
    value: "lastmonth",
    key: "lastmonth",
  },
  last6months: {
    label: i18n.t("date.last6months"),
    value: "last6months",
    key: "last6months",
  },
};

export default CalendarFilter;
