import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import api from "interceptor/api";
import styled from "styled-components";
import useStyle from "style/useStyle";

const WriterContent = styled.textarea`
  background-color: #191a1e;

  border: 1px solid #e5e5e5 !important;
  border-radius: 12px !important;

  color: var(--c-white);

  width: 100%;
  max-width: 100%;
  height: 300px;
  padding: 10px;
  resize: none;
`;

const WriteLayOut = styled.div`
  padding: 20px;
`;

const WriteBtn = styled.button`
  color: #2e81ff;
  background-color: var(--c-white);
  border-radius: 4px;

  padding: 4px 12px;
`;

const InquiryModify = () => {
  const navigate = useNavigate();
  const { FlexBox, TextBox } = useStyle();
  const { inquiryNo } = useParams();
  const [inquiryContent, setInquiryContent] = useState("");
  const [answerContent, setAnswerContent] = useState("");

  useEffect(() => {
    const getInquiryApi = api.get(`/v1/api/inquiry/${inquiryNo}`);
    getInquiryApi.then((response) => {
      const inquiryData = response.data; // 예시: 서버 응답에 따라 수정 필요
      setInquiryContent(inquiryData.inquiry);
      setAnswerContent(inquiryData.answer);
    });
    getInquiryApi.catch((error) => {
      alert("문의사항 정보를 불러오지 못했습니다.");
      console.error(error);
    });
  }, [inquiryNo]);

  const submitModifiedAnswer = () => {
    if (answerContent.length < 20) {
      alert("내용은 20글자 이상 입력해야합니다.");
      return;
    }

    const requestBody = { answer: answerContent };

    const modifiedAnswerUrl = `/v1/api/admin/inquiry/update-answer/${inquiryNo}`;
    const modifiedAnswerApi = api.put(modifiedAnswerUrl, requestBody);

    modifiedAnswerApi.then((response) => {
      navigate("/inquiry");
    });
    modifiedAnswerApi.catch((error) => {
      alert("문의사항 답변 수정에 실패하였습니다.");
      console.error(error);
    });
  };

  return (
    <WriteLayOut>
      <FlexBox
        $jus="space-between"
        $align="center"
      >
        <TextBox
          as="p"
          $size="24px"
        >
          수정하기
        </TextBox>
        <TextBox
          as="button"
          onClick={() => navigate("/inquiry")}
          $size="16px"
        >
          이전 페이지
        </TextBox>
      </FlexBox>
      <FlexBox
        $pad="20px 0"
        $jus="flex-start"
        $gap="12px"
        $direct="column"
      >
        <TextBox $white="nowrap">문의 내용</TextBox>
        <WriterContent
          value={inquiryContent}
          readOnly={true}
        />
        <TextBox $white="nowrap">답변 내용</TextBox>
        <WriterContent
          value={answerContent}
          placeholder="내용은 20글자 이상 입력해야합니다."
          onChange={(e) => setAnswerContent(e.target.value)}
        />
      </FlexBox>
      <FlexBox $jus="flex-end">
        <WriteBtn onClick={submitModifiedAnswer}>"수정하기"</WriteBtn>
      </FlexBox>
    </WriteLayOut>
  );
};

export default InquiryModify;
